import { Injectable } from '@angular/core';
import { 
  ACTIVE_STORE_BRANCH_ENDPOINT,
  LOCK_UNLOCK_EMPLOYEE_ENDPOINT,
  DELETE_EMPLOYEE_ENDPOINT,
  STORE_BRANCH_ENDPOINT
} from 'app/core/constants/api-endpoint.const';
import { StoreBranch, UserStoreBranch } from '../models/store-branch.model';
import { Observable } from 'rxjs';
import { ApiService } from 'app/core/services/api.service';
import { CreateBranchReq } from '../models/create-branch.model';
import { UpdateBranchReq } from '../models/update-branch.model';
import { ILockUnlockEmployee } from 'app/features/employees/models/lock-unlock-employee.model';
import { IDeleteEmployeeInBranch } from 'app/features/employees/models/delete-employee.model';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(
    private readonly apiService: ApiService
  ) { }

  getUserStoreBranches$(): Observable<UserStoreBranch[]> {
    return this.apiService.get<UserStoreBranch[]>(`${STORE_BRANCH_ENDPOINT}`);
  }

  createBranch$(model: CreateBranchReq): Observable<StoreBranch> {
    return this.apiService.post(`${STORE_BRANCH_ENDPOINT}`, model);
  }

  updateBranch$(id: string, model: UpdateBranchReq): Observable<StoreBranch> {
    return this.apiService.put(`${STORE_BRANCH_ENDPOINT}/${id}`, model);
  }

  setActiveBranch$(id: string): Observable<StoreBranch> {
    return this.apiService.put(`${ACTIVE_STORE_BRANCH_ENDPOINT}/${id}`);
  }
  /**
   * lock or unlock employee in branches
   * @param employeeId 
   * @param model 
   * @returns 
   */
  lockOrUnlockEmployeeInBranches$(employeeId: string, model: ILockUnlockEmployee): Observable<void> {
    return this.apiService.put(`${LOCK_UNLOCK_EMPLOYEE_ENDPOINT}/${employeeId}`, model);
  }
  /**
   * Remove employee in branch(es)
   * @param employeeId 
   * @param model 
   * @returns 
   */
  deleteEmployeeInBranches$(employeeId: string, model: IDeleteEmployeeInBranch): Observable<void> {
    return this.apiService.post(`${DELETE_EMPLOYEE_ENDPOINT}/${employeeId}`, model);
  }
}
