import { Injectable } from "@angular/core";
import { ApiService } from "app/core/services/api.service";
import { Observable } from "rxjs";
import { CheckExistsCategory, CreateCategory, ICategoryList, ResponseCreateCategory, UpdateCategory } from "../models/category.model";
import { TDSHelperString, TDSSafeAny } from "tds-ui/shared/utility";
import { CATEGORY_LIST_ENDPOINT, CHECK_CATEGORY_NAME_ENDPOINT } from "app/core/constants/api-endpoint.const";

@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    constructor(
        private readonly apiService: ApiService
    ) {
    }

    /**
     * Get list categories
     * @param skip 
     * @param take 
     * @param filter 
     * @param storeId 
     * @returns 
     */
    getCategories$(
        skip: number = 0,
        take: number = 20,
        filter: string = '',
        storeId: string | undefined | null = ''
      ): Observable<ICategoryList> {
        const filterParams = TDSHelperString.hasValueString(filter) ? `&filter=${filter}` : '';
        const storeIdParams = TDSHelperString.hasValueString(storeId) ? `&storeId=${storeId}` : '';
        return this.apiService.get<ICategoryList>(`${CATEGORY_LIST_ENDPOINT}?skip=${skip}&take=${take}${filterParams}${storeIdParams}`);
    }

    /**
     * Add new category
     * @param input 
     * @returns 
     */
    addCategory$(input: CreateCategory): Observable<ResponseCreateCategory>{
      return this.apiService.post<ResponseCreateCategory>(`${CATEGORY_LIST_ENDPOINT}`, input);
    }

    /**
     * Check duplicate category name
     * @param input 
     * @returns 
     */
    checkExistCategory$(input: CheckExistsCategory):Observable<boolean>{
      return this.apiService.post<boolean>(`${CHECK_CATEGORY_NAME_ENDPOINT}`, input);
    }

    /**
     * Update category
     * @param id 
     * @param input 
     * @returns 
     */
    updateCategory$(id: string, input: UpdateCategory){
      return this.apiService.put<TDSSafeAny>(`${CATEGORY_LIST_ENDPOINT}/${id}`, input);
    }

    /**
     * Remove category
     * @param id 
     * @returns 
     */
    removeCategory$(id: string){
      return this.apiService.delete<TDSSafeAny>(`${CATEGORY_LIST_ENDPOINT}/${id}`);
    }
}