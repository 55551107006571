import { Pipe, PipeTransform } from "@angular/core";

// @Pipe({
//   name: 'sortDateName',
//   standalone: true
// })
// export class SortDateNamePipe implements PipeTransform {

//   transform(type: string): string {
//     switch (type) {
//       case 'asc':
//         return 'Ngày tăng dần';
//       case 'desc':
//         return 'Ngày giảm dần';
//       default:
//         return 'Sắp xếp theo ngày';
//     }
//   }
// }

// @Pipe({
//   name: 'sortTotalAmountName',
//   standalone: true
// })
// export class SortTotalAmountNamePipe implements PipeTransform {

//   transform(type: string): string {
//     switch (type) {
//       case 'asc':
//         return 'Tổng tiền tăng dần';
//       case 'desc':
//         return 'Tổng tiền giảm dần';
//       default:
//         return 'Sắp xếp theo tổng tiền';
//     }
//   }
// }


@Pipe({
  name: 'sortDateClass',
  standalone: true
})
export class SortDateClassPipe implements PipeTransform {

  transform(type: string): string {
    switch (type) {
      case 'asc':
        return 'tdsi-sort-arrow-up-fill';
      case 'desc':
        return 'tdsi-sort-arrow-down-fill';
      default:
        return 'tdsi-sort-arrow-up-down-line !text-neutral-1-500';
    }
  }
}
