import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'app/core/services/auth.service';
import { finalize, takeUntil } from 'rxjs';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSModalModule, TDSModalService } from 'tds-ui/modal';
import { TDSPageHeaderModule } from 'tds-ui/page-header';
import { TDSTableModule, TDSTableQueryParams } from 'tds-ui/table';
import { TDSTagModule } from 'tds-ui/tag';
import { TDSInputModule } from 'tds-ui/tds-input';
import { TDSToolTipModule } from 'tds-ui/tooltip';
import { IEmployee, IEmployeeList, IEmployeeQueryParams } from '../../models/employee.model';
import { EmployeeActionService } from '../../services/employee-action.service';
import { EmployeeService } from '../../services/employee.service';
import { EmployeeActionsComponent } from '../employee-actions/employee-actions.component';
import { EmployeeBranchesDetailComponent } from '../employee-branches-detail/employee-branches-detail.component';
import { EmployeeStatusComponent } from '../employee-status/employee-status.component';
import { FilterEmployeeComponent } from '../filter-employee/filter-employee.component';
import { InviteEmployeeComponent } from '../invite-employee/invite-employee.component';
import { TDSMessageService } from 'tds-ui/message';
import { EmployeeDetailComponent } from '../employee-detail/employee-detail.component';
import { InviteEmployeeToBranchComponent } from '../invite-employee-to-branch/invite-employee-to-branch.component';
import { IFilterEmployee } from '../../models/filter-employee.model';
import { BaseEmployeeClass } from '../../classes/base-employee.class';

@Component({
  selector: 'app-list-employees',
  standalone: true,
  imports: [
    CommonModule,
    TDSPageHeaderModule,
    TDSInputModule,
    TDSFormFieldModule,
    TDSButtonModule,
    TDSTableModule,
    TDSAvatarModule,
    TDSTagModule,
    TDSToolTipModule,
    TDSModalModule,
    EmployeeActionsComponent,
    EmployeeStatusComponent,
    EmployeeBranchesDetailComponent,
    FilterEmployeeComponent,
  ],
  providers: [
    EmployeeService,
    TDSDestroyService,
  ],
  templateUrl: './list-employees.component.html',
  styleUrl: './list-employees.component.scss'
})
export class ListEmployeesComponent extends BaseEmployeeClass implements OnInit {
  storeId: string = '';

  employees!: IEmployee[];
  isLoading = false;
  total: number = 0;
  pageIndex = 1;
  pageSize = 20;

  expandSet = new Set<string>();
  @ViewChild('filterCpm') employeeFilter!: FilterEmployeeComponent;

  adminWidthCfg = ['24px', '290px', '200px', '270px', '200px', '250px', '220px', '200px'];
  userWidthCfg = ['182px', '150px', '220px', '150px', '160px', '180px', '180px', '150px', '150px', '200px'];
  widthConfig: string[] = [];

  constructor(
    private readonly employeeService: EmployeeService,
    private readonly destroyService: TDSDestroyService,
    private readonly modalService: TDSModalService,
    private readonly employeeActionService: EmployeeActionService ,
    private readonly msgService: TDSMessageService,
    authService: AuthService,
  ) { 
    super(authService)
  }

  ngOnInit(): void {
    this.getWidthConfig();
    this.storeId = this.authService.getCurrentStoreId() as string;

    this.employeeActionService.reloadEmployeeList$.subscribe({
      next: (isCompleted: boolean) => {
        if (isCompleted) {
          this.resetTable();
          const filterObj = this.getFilterObj();
          this.loadEmployees(this.pageIndex - 1, this.pageSize, filterObj);
        }
      }
    })
  }

  getWidthConfig() {
    this.widthConfig =  this.isOwner() ? this.adminWidthCfg : this.userWidthCfg;
  }

  onQueryParamsChange(params: TDSTableQueryParams): void {
    let { pageIndex, pageSize } = params;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    const filterObj = this.getFilterObj();
    this.loadEmployees(this.pageIndex - 1, pageSize, filterObj);
  }

  private getFilterObj(): object | undefined {
    return this.employeeFilter.isNotEmptyObject(this.employeeFilter.currentFilter) 
      ? this.transformFilter(this.employeeFilter.currentFilter) : undefined;
  }

  loadEmployees(
    pageIndex: number = 0,
    pageSize: number = 20,
    filterObj?: any
  ): void {
    this.isLoading = true;
    let queryParams: IEmployeeQueryParams = {
      skip: pageIndex * pageSize,
      take: pageSize,
    };

    queryParams = Object.assign(queryParams, { storeBranchIds: [this.authService.getCurrentBranchId()]});

    if (filterObj) {
      queryParams = Object.assign(queryParams, filterObj);
    }

    this.employeeService.getEmployeesInStore$(this.storeId, queryParams)
      .pipe(
        takeUntil(this.destroyService),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (res: IEmployeeList) => {
          this.employees = res.items;
          this.total = res.totalCount;
        },
        error: () => {
          this.msgService.error('Đã xảy ra lỗi, vui lòng thử lại sau');
        }
      })
  }

  addNewEmployee(): void {
    this.modalService.create({
      title: 'Mời nhân viên mới',
      // size: 'lg',
      // content: InviteEmployeeComponent,
      content: InviteEmployeeToBranchComponent,
      footer: null
    });
  }

  onActionCompleted(): void {
    this.resetTable();
    this.loadEmployees(this.pageIndex - 1, this.pageSize);
  }

  onExpandChange(id: string): void {
    this.expandSet.has(id) ? this.expandSet.delete(id) : this.expandSet.add(id);
  }

  private resetTable(): void {
    this.pageIndex = 1;
    this.pageSize = 20;
  }

  onRefresh(): void {
    this.resetTable();
    this.deleteFilter();
    this.loadEmployees();
  }

  onViewDetail(employee: IEmployee): void {
    this.modalService.create<EmployeeDetailComponent, {employee: IEmployee}>({
      title: 'Chi tiết nhân viên',
      content: EmployeeDetailComponent,
      size: 'lg',
      tdsData: {
        employee: employee
      },
      footer: null
    });
  }

  private transformFilter(event: IFilterEmployee): any {
    let filterObj: any = {};

    if (event['search']) {
      filterObj.search = event['search'];
    }

    if (event['statuses']) {
      filterObj.statuses = event['statuses'].map(item => item.value);
    }

    if (event['rangeDate']) {
      filterObj.startDate = event['rangeDate'].startDate?.toISOString();
      filterObj.endDate = event['rangeDate'].endDate?.toISOString();
    }

    return filterObj;
  }

  handleFilter(event: IFilterEmployee): void {
    this.resetTable();

    const filterObj: any = this.transformFilter(event)
    this.loadEmployees(this.pageIndex - 1, this.pageSize, filterObj);
  }

  deleteFilter(): void {
    this.employeeFilter.deleteFilter();
  }
}
