<div class="w-full h-full flex flex-col">
  <div class="flex flex-col tds-page-header-color-background">
    <tds-page-header tdsTitle="Danh sách vai trò">
      <tds-page-header-extra>
        <div class="flex gap-2 items-center justify-end">
          <button tds-button
            [tdsPrefixIcon]="'tdsi-plus-circle-fill'"
            color="primary"
            (tdsBtnClick)="addNewRole()"
          >
            Thêm vai trò
          </button>
          <app-filter-role>
          </app-filter-role>

          <app-filter-column-config>
          </app-filter-column-config>
        </div>
        
      </tds-page-header-extra>
    </tds-page-header>
  </div>

  <div class="w-full flex-auto">
    <div class="w-full h-full p-2">
      <div class="flex flex-col w-full h-full bg-white rounded-md">
        <div class="flex items-start w-full">
          <app-filter-role-criteria-display
          ></app-filter-role-criteria-display>
        </div>

        <div class="flex-auto w-full tds-body-2">
          @if (isOwner) {
            <app-admin-role-list #adminRoleLst></app-admin-role-list>
          } @else {
            <app-employee-role-list #employeeRoleLst></app-employee-role-list>
          }
        </div>
      </div>
    </div>
  </div>
</div>