<div class="h-full w-full bg-white">
  <tds-header 
    tdsTheme="dark"
    urlLogoText="/assets/images/WiOnLogo.svg"
    urlLogo="/assets/images/WiOnLogo.svg"
    class="!pl-[60px] !py-2"
  ></tds-header>

  <tds-spin [spinning]="isLoading" class="w-full h-full">
    <div class="w-full h-full pt-24">
      <div class="flex m-auto h-[624px] w-[520px] rounded-xl border border-solid tds-color-border-secondary shadow-xl">
        <form
          #formDirective="ngForm"
          class="h-full w-full" 
          [formGroup]="resetPasswordForm"
          (ngSubmit)="sendOTPViaZalo(phoneNumber.value)"
        >
          <div class="px-[70px] py-16 w-full h-full flex flex-col gap-6">
            <section class="flex flex-col justify-center items-center gap-2">
              <h3 class="text-heading-3 font-semibold font-tds-font-family-heading">Quên mật khẩu</h3>
              <div class="flex justify-center flex-wrap tds-body-1 tds-color-text-tertiary text-center px-1">
                Vui lòng nhập số điện thoại đã đăng ký để lấy lại mật khẩu
              </div>
            </section>

            <section class="flex flex-col">
              <div>
                <tds-label [tdsRequired]="true">Số điện thoại</tds-label>
                <tds-form-field class="w-full mt-2">
                  <span tdsPrefix class="tds-body-2 font-semibold">+84</span>
                  <input tdsInput
                    type="text"
                    size="xl"
                    autocomplete="off"
                    placeholder="Nhập số điện thoại"
                    formControlName="phoneNumber"
                    [required]='true'
                    maxlength="10"
                    numbersOnly
                    (blur)="onPhoneBlur()"
                    (focus)="onPhoneFocus()"
                  />
                  @if(phoneNumber.errors?.['required'] && (phoneIsTouched || phoneNumber.dirty || phoneNumber.touched)) {
                    <tds-error>Vui lòng nhập số điện thoại</tds-error>
                  }

                  @if(phoneNumber.errors?.['pattern'] && phoneIsTouched) {
                    <tds-error>Số điện thoại không hợp lệ</tds-error>
                  }
                </tds-form-field>
              </div>

              @if(isShowWarning) {
                <tds-alert
                  class="mt-2"
                  type="info"
                  [message]="contentMessage"
                  showIcon
                >
                </tds-alert>
                <ng-template #contentMessage>
                  <div class="text-body-2 font-regular">Vui lòng gửi lại yêu cầu sau <span class="font-semibold">{{resendTime}}s</span></div>
                </ng-template>
              }

              <div class="flex flex-col gap-2 w-full mt-6">
                <button tds-button color="primary" type="submit" size="xl">
                  Gửi mã OTP qua Zalo
                </button>
                <button tds-button-outline color="secondary" class="pt-2" type="button" size="xl"
                  (tdsBtnClick)="sendOTPViaSMS(phoneNumber.value)">
                  Gửi mã OTP qua SMS
                </button>
              </div>
            </section>

            <a tds-link (click)="goToSignInPage()"
              class="w-fit"
            >
              <i class="tdsi-back-fill text-sm"></i>
              Về trang đăng nhập
            </a>
          </div>
        </form>
      </div>
    </div>
  </tds-spin>
</div>

<!-- Limit access exceeded otp template -->
<ng-template #limitOtp>
  <div class="w-full flex flex-col justify-center text-center tds-body-2">
    <div>
      Bạn đã hết 3 lượt gửi mã.
    </div>
    <div>
      Vui lòng thử lại sau <span class="font-semibold ml-0.5">{{sixHoursFromLatestTimeSend | date: 'HH:mm'}}</span>
    </div>
  </div>
</ng-template>