<tds-badge [count]="onlineStatus">
  <tds-avatar 
    [tdsSrc]="avatar">
  </tds-avatar>
</tds-badge>
<ng-template #onlineStatus>
  <span class="absolute -bottom-tds-spacing-1 -right-tds-spacing-2 rounded-full
    w-2.5 h-2.5 border-solid border-2 tds-color-border-white"
    [ngClass]="isOnline ? 'tds-color-background-success-subtle' : 'tds-color-background-neutral-bold'"
  >
  </span>
</ng-template>