
@if (isNotEmptyObject(currentFilter) && isDisplayFilter(currentFilter)) {
  <div class="w-full px-2 flex items-center gap-2">
    <div class="flex flex-wrap items-center gap-2 mr-2 py-2 w-full">
      @if (currentFilter['status']?.length) {
        @for(item of currentFilter['status']; track item.id) {
          <tds-tag 
            mode="closeable"
            [size]="'lg'"
            [style]="{'background': '#E2F1FF'}"
            (close)="clearFilterStatus(item)"
          >
            {{ item.name }}
          </tds-tag>
        }
      }

      @if(currentFilter['rangeDate']) {
        <tds-tag 
          mode="closeable"
          [size]="'lg'"
          [style]="{'background': '#E2F1FF'}"
          (close)="clearRangeDate()"
        >
          {{ currentFilter['rangeDate'].startDate | date: 'dd/MM/YYYY' }} - 
          {{ currentFilter['rangeDate'].endDate | date: 'dd/MM/YYYY' }}
        </tds-tag>
      }

      @if(currentFilter['store']) {
        <tds-tag 
          mode="closeable"
          [size]="'lg'"
          [style]="{'background': '#E2F1FF'}"
          (close)="clearStore()"
        >
          {{ currentFilter['store'].name }}
        </tds-tag>
      }
    </div>

    <div class="flex items-center gap-2">
      <button
        tds-button-flat
        size="sm"
        color="error"
        (tdsBtnClick)="deleteFilter()"
      >
        Xóa lọc
      </button>
      <button
        tds-button-flat
        size="sm"
        color="info"
        (tdsBtnClick)="addFilter()"
      >
        Thêm lọc
      </button>
    </div>
  </div>
}