import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Paging } from 'app/shared/models/paging.model';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSTableModule, TDSTableQueryParams } from 'tds-ui/table';
import { TDSInputModule } from 'tds-ui/tds-input';
import { AttributeValueParam, IAttributeValue, IAttributeValueList } from '../../models/attribute-value.model';
import { IAttribute } from '../../models/attribute.model';
import { AddAttributeValueComponent } from '../add-attribute-value/add-attribute-value.component';
import { AttributeValueService } from '../../service/attribute-value.service';
import { TDSHelperString, TDSSafeAny } from 'tds-ui/shared/utility';
import { TDSMessageModule, TDSMessageService } from 'tds-ui/message';
import { AuthService } from 'app/core/services/auth.service';
import { AvatarComponent } from 'app/shared/components/avatar/avatar.component';
import { TDSModalModule, TDSModalService } from 'tds-ui/modal';
import { FormsModule } from '@angular/forms';
import { TDSToolTipModule } from 'tds-ui/tooltip';
import { EditAttributeValueComponent } from '../edit-attribute-value/edit-attribute-value.component';

@Component({
  selector: 'app-list-attribute-value',
  standalone: true,
  imports: [
    CommonModule,
    AddAttributeValueComponent,
    TDSButtonModule,
    TDSInputModule,
    TDSTableModule,
    AvatarComponent,
    TDSMessageModule,
    TDSModalModule,
    FormsModule,
    TDSToolTipModule 
  ],
  templateUrl: './list-attribute-value.component.html',
  styleUrl: './list-attribute-value.component.scss'
})
export class ListAttributeValueComponent implements OnInit, OnChanges {
  @Input() attribute?: IAttribute;
  @Input() listOfAttribute: IAttribute[] = [];
  @Input() storeId!: string | null;
  @Output() loadingAttributeEvent = new EventEmitter<boolean>();

  isLoading = false;
  paging: Paging = {
    size: 20,
    index: 1,
    total: 0,
  };
  searchText: string = '';
  listOfAttributeValue: IAttributeValue[] = [];
  constructor(private attributeValueService: AttributeValueService,
    private modalService: TDSModalService,
    private messageService: TDSMessageService,) {


  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['attribute'] && !changes['attribute'].firstChange) {
      this._resetPaging();
      this.loadAttributeValue();
    }
  }

  ngOnInit(): void {

  }

  onQueryParamsChange(event: TDSTableQueryParams) {
    this.paging.size = event.pageSize;
    this.paging.index = event.pageIndex;
    this.loadAttributeValue();
  }

  loadAttributeValue(){
    const model = this.prepareParam();
    this._enableLoading();
    this.attributeValueService.getAttributesValue$(
      (model.skip - 1) * model.take,
      model.take,
      model.filter,
      model.attributeId,
      model.storeId,
    ).subscribe({
      next: (res: IAttributeValueList) => {
        this.listOfAttributeValue = res.items;
        this.paging.total = res.totalCount;
      }, error: () => this.messageService.error("Không lấy được danh sách thuộc tính")
      , complete: () => this._disableLoading()
    })
  }

  prepareParam(){
    const model: AttributeValueParam = {
      skip: this.paging.index,
      take: this.paging.size,
      filter: this.searchText,
      storeId: TDSHelperString.hasValueString(this.storeId) ? this.storeId : null,
      attributeId: this.attribute?this.attribute.id: ''
    };
    return model;
  }

  private _enableLoading() {
    this.isLoading = true;
  }

  private _disableLoading() {
    this.isLoading = false;
  }

  private _resetPaging(){
    this.paging.index = 1;
  }

  onSearch(event: TDSSafeAny) {
    this.searchText = (event) ? event.value : undefined;
    this._resetPaging();
    this.loadAttributeValue();
  }

  loadingEvent(event: boolean){
    this.loadingAttributeEvent.emit(event);
  }

  loadingDataEvent(event: boolean){
    if(event) this.loadAttributeValue();
  }

  removeAttributeValue(item: IAttributeValue){
    this.modalService.error({
      title: 'Xóa giá trị thuộc tính',
      confirmIcon: 'tdsi-trash-fill',
      content: `Bạn có chắc chắn muốn xóa  giá trị thuộc tính "<strong>${item.name}</strong>” ?`,
      onOk: () => this.callApiRemoveAttributeValue(item),
      okText: "Xác nhận",
      cancelText: "Đóng"
    })
  }

  callApiRemoveAttributeValue(item: IAttributeValue) {
    this.attributeValueService.removeAttributeValue$(item.id)
      .subscribe({
        next: () => {
          this.messageService.success("Đã xóa thành công!");
          this.loadAttributeValue();
        }, error: () => this.messageService.error("Có lỗi khi xóa, vui lòng thử lại!")
      })
  }

  editAttributeValue(item: IAttributeValue){
    const modal = this.modalService.create({
      title: 'Chỉnh sửa giá trị thuộc tính',
      size: "md",
      content: EditAttributeValueComponent,
      componentParams: {
        attributeValue: item,
        storeId: this.storeId
      }
    });

    modal.afterClose.subscribe(((res: undefined | boolean) => {
      if (typeof res == 'boolean' && res) this.loadAttributeValue();
    }))
  }
}
