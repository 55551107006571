import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from 'app/core/services/auth.service';
import { TDSAvatarModule } from "tds-ui/avatar";
import { TDSButtonModule } from "tds-ui/button";
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSDrawerModule, TDSDrawerService } from "tds-ui/drawer";
import { TDSDropDownModule } from "tds-ui/dropdown";
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSModalService } from 'tds-ui/modal';
import { TDSPageHeaderModule } from "tds-ui/page-header";
import { TDSTableModule } from "tds-ui/table";
import { TDSTagModule } from "tds-ui/tag";
import { TDSInputModule } from "tds-ui/tds-input";
import { TDSToolTipModule } from 'tds-ui/tooltip';
import { CreateRoleComponent } from '../create-role/create-role.component';
import { FilterColumnConfigComponent } from '../filter-column-config/filter-column-config.component';
import { FilterRoleCriteriaDisplayComponent } from '../filter-role-criteria-display/filter-role-criteria-display.component';
import { FilterRoleComponent } from '../filter-role/filter-role.component';
import { AdminRoleListComponent } from './admin-role-list/admin-role-list.component';
import { EmployeeRoleListComponent } from './employee-role-list/employee-role-list.component';

@Component({
  selector: 'app-list-role',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TDSPageHeaderModule,
    TDSButtonModule,
    TDSTableModule,
    TDSFormFieldModule,
    TDSInputModule,
    TDSAvatarModule,
    TDSTagModule,
    TDSDrawerModule,
    TDSDropDownModule,
    TDSToolTipModule,
    FilterRoleComponent,
    FilterColumnConfigComponent,
    FilterRoleCriteriaDisplayComponent,
    AdminRoleListComponent,
    EmployeeRoleListComponent
  ],
  providers: [
    TDSDrawerService,
    TDSModalService,
    TDSDestroyService
  ],
  templateUrl: './list-role.component.html',
  styleUrl: './list-role.component.scss'
})
export class ListRoleComponent implements OnInit {
  @ViewChild("adminRoleLst") adminRoleLstCmp!: AdminRoleListComponent;
  @ViewChild("employeeRoleLst") employeeRoleLstCmp!: EmployeeRoleListComponent;

  isOwner = false;

  constructor(
    private readonly drawerService: TDSDrawerService,
    private readonly authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.isOwner = this.authService.isOwner();
  }

  addNewRole(): void {
    const drawerRef = this.drawerService.create<CreateRoleComponent>({
      title: 'Thêm mới nhóm vai trò',
      content: CreateRoleComponent,
      width: '768px',
      height: '100vh',
      maskClosable: false,
      bodyStyle: {
        padding: '0px'
      }
    });

    drawerRef.afterClose.subscribe((res: boolean | undefined | null) => {
      if (!res) return;

      if (this.isOwner) {
        this.adminRoleLstCmp.loadRoles();
      } else {
        this.employeeRoleLstCmp.loadRoles();
      }
    });
  }
}
