import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ResetPasswordReq } from "app/core/models/reset-password.model";
import { VerifiedOtp } from "app/core/models/sign-up.model";
import { WiAccountService } from "app/core/services/wi-account.service";
import { WiECommerceDirtyErrorStateMatcher } from "app/core/utilities/error-state-matcher.util";
import { noWhitespaceOrVietnamese } from "app/core/utilities/no-white-space-or-vietnamese.util";
import { finalize, takeUntil } from "rxjs";
import { TDSButtonModule } from "tds-ui/button";
import { TDSDestroyService } from "tds-ui/core/services";
import { TDSFormFieldModule } from "tds-ui/form-field";
import { TDSMessageService } from "tds-ui/message";
import { TDSModalRef } from "tds-ui/modal";
import { ErrorStateMatcher } from "tds-ui/shared/common";
import { TDSInputModule } from "tds-ui/tds-input";

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TDSFormFieldModule,
    TDSInputModule,
    TDSButtonModule,
  ],
  providers: [
    WiAccountService,
    TDSDestroyService,
    TDSMessageService,
    {
      provide: ErrorStateMatcher,
      useClass: WiECommerceDirtyErrorStateMatcher
    },
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit {
  @Input() verifiedOtp!: VerifiedOtp;
  form!: FormGroup<any>;

  // Password visibility toggles and input types
  isPasswordVisible = false;
  isConfirmPasswordVisible = false;
  readonly passwordInputType = 'password';
  readonly textInputType = 'text';
  passwordFieldType = this.passwordInputType;
  confirmPasswordFieldType = this.passwordInputType;
  isLoading = false;

  showPasswordHint = false;

  constructor(
    private readonly modalRef: TDSModalRef,
    private readonly wiAccountService: WiAccountService,
    private readonly tdsDestroyService: TDSDestroyService,
    private readonly messageService: TDSMessageService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  // Initialize the form with validators
  private initForm(): void {
    const passwordPattern = /^[^\s]{8,16}$/;

    this.form = new FormGroup<any>({
      password: new FormControl(null, [
        Validators.required,
        Validators.pattern(passwordPattern),
        noWhitespaceOrVietnamese
      ]),
      confirmPassword: new FormControl(null)
    });

    this.form.get('confirmPassword')?.setValidators([
      Validators.required,
      this.confirmPasswordValidator.bind(this)
    ]);
  }

  private confirmPasswordValidator(control: AbstractControl): ValidationErrors | null {
    const password = this.form.get('password')?.value;
    const confirmPassword = control.value;
    return password === confirmPassword ? null : { mismatch: true };
  }

  // Getter for the password form control, providing access to the password field in the form
  get password() {
    return this.form.get('password') as FormControl;
  }

  // Getter for the confirmPassword form control, providing access to the confirmPassword field in the form
  get confirmPassword() {
    return this.form.get('confirmPassword') as FormControl;
  }

  private markFormAsDirty(): void {
    Object.keys(this.form.controls).forEach(key => {
      this.form.get(key)?.markAsDirty();
    });
  }

  // Handle form completion
  onComplete(): void {
    this.markFormAsDirty();
    if (!this.form.valid) {
      return;
    }

    this.isLoading = true;
    const resetData = this.prepareResetPassword();
    this.wiAccountService.resetPassword(resetData)
      .pipe(
        takeUntil(this.tdsDestroyService),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (res: any) => {
          this.modalRef.destroy(true);
        },
        error: (err: any) => {
          this.messageService.error(err.error.message);
        }
      });
  }

  private prepareResetPassword(): ResetPasswordReq {
    return {
      newPassword: this.form.value.password ?? '',
      phoneNumber: this.verifiedOtp.phone,
      token: this.verifiedOtp.smsOtpToken,
      otpCode: this.verifiedOtp.otpCode
    };
  }

  // Handle back navigation
  goToSignIn(): void {
    this.modalRef.close();
    this.router.navigate(['/sign-in']);
  }

  // Toggle password visibility
  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
    this.passwordFieldType = this.isPasswordVisible ? this.textInputType : this.passwordInputType;
  }

  // Toggle confirm password visibility
  toggleConfirmPasswordVisibility(): void {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
    this.confirmPasswordFieldType = this.isConfirmPasswordVisible ? this.textInputType : this.passwordInputType;
  }

  onFocus(): void {
    this.showPasswordHint = true;
    this.password.markAsDirty();
  }
}