import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { WiECommerceDirtyErrorStateMatcher } from 'app/core/utilities/error-state-matcher.util';
import { NoWhitespaceValidator } from 'app/shared/custom-validators/no-whitespace-validator';
import { Observable, map, of, switchMap, timer } from 'rxjs';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalModule, TDSModalRef } from 'tds-ui/modal';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { TDSSafeAny } from 'tds-ui/shared/utility';
import { TDSInputModule } from 'tds-ui/tds-input';
import { ATTRIBUTE_VALUE_RULE } from '../../constants/attribute-value-rule.constant';
import { IAttributeValue, UpdateAttributeValue } from '../../models/attribute-value.model';
import { AttributeValueService } from '../../service/attribute-value.service';

@Component({
  selector: 'app-edit-attribute-value',
  standalone: true,
  imports: [
    TDSButtonModule,
    TDSModalModule,
    TDSFormFieldModule,
    TDSInputModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: ErrorStateMatcher,
      useClass: WiECommerceDirtyErrorStateMatcher
    }
  ],
  templateUrl: './edit-attribute-value.component.html',
  styleUrl: './edit-attribute-value.component.scss'
})
export class EditAttributeValueComponent implements OnInit{
  @Input() attributeValue!: IAttributeValue;
  @Input() storeId: string | null | undefined;
  isLoading: boolean = false;
  form!: FormGroup;
  rule = ATTRIBUTE_VALUE_RULE;

  constructor(private modal: TDSModalRef,
    private messageService: TDSMessageService,
    private attributeValueService: AttributeValueService,
    private fb: FormBuilder,){

  }

  ngOnInit(): void {
    this.createForm();
    this.updateForm();
  }

  createForm() {
    this.form = this.fb.group({
      name: [
        null, Validators.compose([
          Validators.required,
          Validators.minLength(this.rule.name.minLength),
          Validators.maxLength(this.rule.name.maxLength),
          NoWhitespaceValidator(),
        ]),this.checkExistName(this.attributeValueService)
      ],
      attributeId: [
        null, Validators.required
      ],
    })
  }

  updateForm() {
    this.form.get('name')?.setValue(this.attributeValue.name);
    this.form.get('attributeId')?.setValue(this.attributeValue.attributeId);
  }

  checkExistName = (service: AttributeValueService) => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || this.attributeValue.name == control.value){
        this.isLoading = false;
        return of(null)
      } 

      this.isLoading = true;
      return timer(500).pipe(
        switchMap(() =>
          service.checkExistAttributeValue$({
            name: control.value,
            storeId: this.storeId ?? '',
            attributeId: this.attributeValue.attributeId
          })
            .pipe(
              map((res: TDSSafeAny) => {
                this.isLoading = false;
                if (res) {
                  return { duplicate: true };
                } else {
                  return null;
                }
              })
            )
        )
      )
    }
  }

  onSave(){
    this._markDirtyAndTouched();
    if (!this.form.valid) return;
    this.isLoading = true;
    const model = this.prepareModel();
    this.attributeValueService.updateAttributeValue$(this.attributeValue.id, model)
      .subscribe({
        next: () => {
          this.messageService.success("Cập nhật danh mục thành công!");
          this.modal.destroy(true);
        },error: () => this.messageService.error("Có lỗi khi cập nhật danh mục!")
        ,complete: () => this.isLoading = false
      })
  }

  onCancel(){
    this.modal.destroy(false);
  }

  prepareModel(){
    const valueForm = this.form.value;
    const model: UpdateAttributeValue = {
      name: valueForm.name,
      storeId: this.storeId ?? '',
      attributeId: valueForm.attributeId
    };
    return model; 
  }

  private _markDirtyAndTouched() {
    this.form.controls['name'].markAsDirty();
    this.form.controls['name'].markAsTouched();
    this.form.controls['attributeId'].markAsDirty();
    this.form.controls['attributeId'].markAsTouched();
  }
}
