import { Component, inject } from '@angular/core';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSModalRef } from 'tds-ui/modal';

@Component({
  selector: 'app-reset-password-success',
  standalone: true,
  imports: [
    TDSButtonModule
  ],
  templateUrl: './reset-password-success.component.html',
  styleUrl: './reset-password-success.component.scss'
})
export class ResetPasswordSuccessComponent {
  private readonly _modalRef = inject(TDSModalRef);
  
  onSignIn(): void {
    this._modalRef.destroy(true);
  }
}
