import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ISignalrReceiveMessage } from 'app/core/models/signalR.model';
import { IRole, IRoleList } from 'app/features/roles/models/role.model';
import { RoleService } from 'app/features/roles/services/role.service';
import { OnlineStatusComponent } from 'app/shared/components/online-status/online-status.component';
import { finalize, takeUntil } from 'rxjs';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSDrawerService } from 'tds-ui/drawer';
import { TDSDropDownModule } from 'tds-ui/dropdown';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalRef, TDSModalService } from 'tds-ui/modal';
import { TDSTableModule, TDSTableQueryParams } from 'tds-ui/table';
import { TDSTagModule } from 'tds-ui/tag';
import { TDSToolTipModule } from 'tds-ui/tooltip';
import { EditRoleComponent } from '../../edit-role/edit-role.component';
import { FilterRoleService } from 'app/features/roles/services/filter-role.service';
import { IRoleQueryParams } from 'app/features/roles/models/query-role-params.model';
import { IFilterRole } from 'app/features/roles/models/filter-role.model';
import { SignalRService } from 'app/core/services/signalr.service';
import { AuthService } from 'app/core/services/auth.service';
import { ON_CONNECTED_EVENT } from 'app/core/constants/signalR.const';

@Component({
  selector: 'app-employee-role-list',
  standalone: true,
  imports: [
    CommonModule,
    TDSButtonModule,
    TDSTableModule,
    TDSAvatarModule,
    TDSTagModule,
    TDSToolTipModule,
    TDSDropDownModule,
    OnlineStatusComponent,
  ],
  templateUrl: './employee-role-list.component.html',
  styleUrl: './employee-role-list.component.scss'
})
export class EmployeeRoleListComponent implements OnInit {
  roles: IRole[] = [];
  total: number = 0;
  isLoading = false;
  PAGE_SIZE = 20;
  pageIndex = 1;
  pageSize = this.PAGE_SIZE;
  currentFilter: IFilterRole = {};
  isFiltered = false;
  isOwner = false;

  setOfExpanded = new Set();

  constructor(
    private readonly roleService: RoleService,
    private readonly msgService: TDSMessageService,
    private readonly modalService: TDSModalService,
    private readonly destroyService: TDSDestroyService,
    private readonly drawerService: TDSDrawerService,
    private readonly signalRService: SignalRService,
    private readonly authService: AuthService,
    private readonly filterRoleService: FilterRoleService
  ) {
  }

  ngOnInit(): void {
    this.signalRService.messages$.subscribe((data: ISignalrReceiveMessage<any>) => {
      //User early return to check conditions
      if (!data || data.message !== ON_CONNECTED_EVENT) return;
      if (!this.roles?.length) return;
      
      const tenantId = this.authService.getTenantId();
      if (!tenantId || tenantId !== data.dto.tenantId) return;
      
      this.roles = this.updateOnlineStatus(this.roles, data);
    });

    this.filterRoleService.currentFilterCriteria$.subscribe((filters: IFilterRole | null) => {
      if (!filters) return;

      this.isFiltered = true;
      this.currentFilter = filters;
      this.resetTableParams();
      this.loadRoles(this.pageIndex - 1, this.pageSize, filters);
    })
  }

  onQueryParamsChange(params: TDSTableQueryParams): void {
    let { pageIndex, pageSize } = params;  
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

    if (this.isFiltered) return;

    this.loadRoles(this.pageIndex - 1, pageSize, this.currentFilter);
  }

  private buildQueryParams(queryParams: IRoleQueryParams, filters: IFilterRole): IRoleQueryParams {
    if (filters['status']) queryParams.isDefault = filters['status'][0].isDefault;
  
    if (filters['storeBranchIds']) queryParams.storeBranchIds = filters['storeBranchIds'];

    if (filters['store']) queryParams.storeId = filters['store'].id;

    if (filters['search']) queryParams.search = filters['search'];

    if (filters['rangeDate']) {
      queryParams.startDate = filters['rangeDate'].startDate.toISOString();
      queryParams.endDate = filters['rangeDate'].endDate.toISOString();
    }

    return queryParams;
  }

  loadRoles(pageIndex: number = 0, pageSize: number = this.PAGE_SIZE, filters?: IFilterRole): void {
    this.isLoading = true;
    
    let queryParams: IRoleQueryParams = {
      skip: pageIndex * pageSize,
      take: pageSize,
    };

    if (filters) {
      this.buildQueryParams(queryParams, filters);
    }

    this.roleService.getRolesV2$(queryParams)
    .pipe(
      takeUntil(this.destroyService),
      finalize(() => {
        if (this.isFiltered) this.isFiltered = false;
      })
    )
    .subscribe({
      next: (res: IRoleList) => {
        this.roles = res.roleList;
        this.total = res.totalCount;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.msgService.error('Đã xảy ra lỗi, vui lòng thử lại sau');
      }
    });
  }

  editRole(role: IRole): void {
    const drawerRef = this.drawerService.create<EditRoleComponent, { role: IRole }>({
      title: 'Chỉnh sửa nhóm vai trò',
      closable: true,
      content: EditRoleComponent,
      width: '768px',
      height: '100vh',
      maskClosable: false,
      bodyStyle: {
        padding: '0px'
      },
      tdsData: {
        role: role
      }
    });

    drawerRef.afterClose.subscribe((res: boolean | undefined | null) => {
      if (!res) return;
      this.resetTableParams();
      this.loadRoles();
    });
  }

  private resetTableParams(): void {
    this.pageIndex = 1;
    this.pageSize = this.PAGE_SIZE;
  }

  onDeleteRole(id: string, modal: TDSModalRef): void {
    this.roleService.deleteRole$(id)
      .pipe(
        takeUntil(this.destroyService)
      )
      .subscribe({
        next: () => {
          this.msgService.success('Xóa nhóm vai trò thành công');
          modal.destroy(true);
        },
        error: () => {
          this.msgService.success('Đã xảy ra lỗi, vui lòng thử lại sau');
          modal.destroy();
        }
      })
  }

  deleteRole(role: IRole): void {
    const modal = this.modalService.error({
      title: 'Xóa nhóm vai trò',
      confirmIcon: "tdsi-trash-fill",
      content: `<div class="tds-body-2">Bạn có chắc chắn muốn xóa vai trò này "<span class="font-semibold">${role.name}</span>"?</div>`,
      onOk: () => {
        this.onDeleteRole(role.id, modal);
        return false;//prevent close modal until delete success
      },
      onCancel:()=> {},
      okText: "Xác nhận",
      cancelText: "Đóng",
    });

    modal.afterClose.subscribe((isDeleted: boolean | undefined | null) => {
      if (!isDeleted) return;
      this.resetTableParams();
      this.loadRoles();
    });
  }

  /**
   * Update isOnline status for all matching users in all roles
   * @param roles 
   * @param data 
   * @returns updated roles
   */
  private updateOnlineStatus(roles: IRole[], data: ISignalrReceiveMessage<any>): IRole[] {
    return roles.map(role => ({
      ...role,
      userInRoleList: role.userInRoleList?.map(user => 
        user.id === data.dto.userId 
          ? { ...user, isOnline: data.dto.isOnline }
          : user
      )
    }));
  }

  refreshTable(): void {
    this.resetTableParams();
    this.loadRoles();
  }

  isNotEmptyObject(obj: object): boolean {
    return obj && Object.keys(obj).length > 0;
  }

  isOnlySearchFilter(currentFilter: object): boolean {
    return Object.keys(currentFilter).length === 1 && 'search' in currentFilter;
  }

  deleteFilter(): void {
    this.resetTableParams();
    this.filterRoleService.deleteFilter();
  }

  /**
   * Refresh data, keep current filters
   */
  onRefresh(): void {
    this.loadRoles(this.pageIndex - 1, this.pageSize, this.currentFilter);
  }

  toggleDescription(index: number) {
    if (this.setOfExpanded.has(index)) {
      this.setOfExpanded.delete(index);
    } else {
      this.setOfExpanded.add(index);
    }
  }

  isExpanded(index: number): boolean {
    return this.setOfExpanded.has(index);
  }

  toggleContent(index: number): void {
    if (this.setOfExpanded.has(index)) {
      this.setOfExpanded.delete(index);
    } else {
      this.setOfExpanded.add(index);
    }
  }
}
