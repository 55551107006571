import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { WiECommerceDirtyErrorStateMatcher } from 'app/core/utilities/error-state-matcher.util';
import { phoneValidatorTenDigits } from 'app/core/utilities/phone-validator.util';
import { StoreBranch } from 'app/features/branches/models/store-branch.model';
import { IRole } from 'app/features/roles/models/role.model';
import { RoleService } from 'app/features/roles/services/role.service';
import { NumbersOnlyDirective } from 'app/shared/directives/numbers-only.directive';
import { catchError, EMPTY, finalize, map, Observable, of, switchMap, takeUntil, tap, timer } from 'rxjs';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalModule, TDSModalRef, TDSModalService } from 'tds-ui/modal';
import { TDSSelectModule } from 'tds-ui/select';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { TDSTagModule } from 'tds-ui/tag';
import { TDSInputModule } from 'tds-ui/tds-input';
import { IEmployee } from '../../models/employee.model';
import { IInviteEmployeeReq, InviteInfo } from '../../models/invite-employee.model';
import { EmployeeActionService } from '../../services/employee-action.service';
import { EmployeeService } from '../../services/employee.service';
import { InvitedSuccessfullyComponent } from '../invited-successfully/invited-successfully.component';
import { AuthService } from 'app/core/services/auth.service';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';

@Component({
  selector: 'app-invite-employee-to-branch',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TDSFormFieldModule,
    TDSButtonModule,
    TDSInputModule,
    TDSSelectModule,
    NumbersOnlyDirective,
    TDSModalModule,
    TDSTagModule,
    TDSSpinnerModule
  ],
  providers: [
    {
      provide: ErrorStateMatcher, useClass: WiECommerceDirtyErrorStateMatcher
    },
    TDSMessageService,
    TDSDestroyService,
    EmployeeService,
    RoleService,
    TDSModalService
  ],
  templateUrl: './invite-employee-to-branch.component.html',
  styleUrl: './invite-employee-to-branch.component.scss'
})
export class InviteEmployeeToBranchComponent implements OnInit {
  inviteForm!: FormGroup;
  phoneIsTouched = false;
  userBranches!: StoreBranch[];
  userRoles!: IRole[];
  isLoading = false;

  isLoadingMore = false;
  currentPage = 0;
  readonly pageSize = 20;
  total = 0;
  allRolesLoaded = false; // Track if all roles are loaded

  constructor(
    private readonly employeeService: EmployeeService,
    private readonly roleService: RoleService,
    private readonly modalRef: TDSModalRef<InviteEmployeeToBranchComponent>,
    private readonly msgService: TDSMessageService,
    private readonly tdsDestroyService: TDSDestroyService,
    private readonly employeeActionService: EmployeeActionService,
    private readonly modalService: TDSModalService,
    private readonly authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getRolesOfUser();
    this.createInviteForm();
  }

  getRolesOfUser(): void {
    this.fetchRoles(this.currentPage).subscribe();
  }

  createInviteForm(): void {
    this.inviteForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      phoneNumber: new FormControl(null, Validators.compose([
          Validators.required,
          phoneValidatorTenDigits(),
        ]),
        this.checkExistPhoneNumber(this.employeeService)
      ),
      roleId: new FormControl(null, Validators.required),
      roleName: new FormControl(null, Validators.required),
    });
  }

  get name(): FormControl {
    return this.inviteForm.get('name') as FormControl;
  }

  get phoneNumber(): FormControl {
    return this.inviteForm.get('phoneNumber') as FormControl;
  }

  get roleId(): FormControl {
    return this.inviteForm.get('roleId') as FormControl;
  }

  get roleName(): FormControl {
    return this.inviteForm.get('roleName') as FormControl;
  }

  onPhoneBlur(): void {
    this.phoneIsTouched = true;
  }

  onPhoneFocus(): void {
    this.phoneIsTouched = false;
  }

  onClose(): void {
    this.modalRef.close();
  }

  private preparePayload(): IInviteEmployeeReq {
    const formValue = this.inviteForm.value;

    const model: IInviteEmployeeReq = {
      name: formValue.name,
      phoneNumber: formValue.phoneNumber,
      storeBranches: [
        {
          roleId: formValue.roleId,
          storeBranchId: this.authService.getCurrentBranchId() as string
        }
      ]
    }

    return model;
  }

  private markFormAsDirty(): void {
    Object.keys(this.inviteForm.controls).forEach(key => {
      this.inviteForm.get(key)?.markAsDirty();
    })
  }

  openSuccessModal(inviteInfo?: InviteInfo, employee?: IEmployee): void {
    const modal = this.modalService.create<InvitedSuccessfullyComponent, {employee?: IEmployee, inviteInfo?: InviteInfo}>({
      content: InvitedSuccessfullyComponent,
      closable: false,
      tdsData: {
        employee: employee,
        inviteInfo: inviteInfo
      },
      bodyStyle: {
        "padding": "0px"
      },
      footer: null
    });

    modal.afterClose.subscribe(() => {
      this.employeeActionService.onActionCompleted();
    })
  }

  onSendInvite(): void {
    this.markFormAsDirty();

    if (!this.inviteForm.valid) return;

    this.isLoading = true;
    const payload = this.preparePayload();

    this.employeeService.inviteEmployee$(payload).pipe(
      takeUntil(this.tdsDestroyService),
      finalize(() => this.isLoading = false)
    )
    .subscribe({
      next: () => {
        this.modalRef.destroy(true);
        const data: InviteInfo = {
          phoneNumber: this.inviteForm.value.phoneNumber,
          employeeName: this.inviteForm.value.name,
        };

        this.openSuccessModal(data);
      },
      error: () => {
        this.msgService.error('Đã xảy ra lỗi, vui lòng thử lại sau');
      }
    })
  }

  checkExistPhoneNumber = (service: EmployeeService) => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }

      return timer(750).pipe(
        switchMap(() =>
          service.checkExistPhoneNumber$(control.value)
            .pipe(
                map((res: boolean) => {
                  if (res) {
                    return { existPhoneNumber: true };
                  } else {
                    return null;
                  }
                })
            )
        )
      )
    }
  }

  onChangeRole(event: IRole): void {
    if (!event) {
      this.roleId.setValue(null);
      this.roleName.setValue(null);
    } else {
      this.roleId.setValue(event.id);
      this.roleName.setValue(event.name);
    }
  }

  private processRoles(roles: IRole[]): void {
    const filteredRoles = roles.filter(r => !r.isDefault);
    this.userRoles = this.currentPage === 0 
      ? filteredRoles 
      : [...this.userRoles, ...filteredRoles];

    if (this.userRoles.length === (this.total - 1)) this.allRolesLoaded = true;
  }

  private fetchRoles(page: number): Observable<IRole[]> {
    return this.roleService.getRoles$(page * this.pageSize, this.pageSize).pipe(
      tap(res => this.total = res.totalCount),
      map(res => res.roleList),
      tap(roles => {
        this.processRoles(roles);
      }),
      catchError(error => {
        if (error) {
          this.msgService.error('Đã xảy ra lỗi, vui lòng thử lại sau');
        }
        return EMPTY;
      })
    );
  }

  loadMoreRoles(): void {
    if (this.allRolesLoaded || this.isLoadingMore) return;

    this.isLoadingMore = true;
    this.currentPage++;

    this.fetchRoles(this.currentPage).pipe(
      finalize(() => this.isLoadingMore = false),
      catchError(error => {
        this.currentPage--; // Revert page increment on error
        return EMPTY;
      })
    ).subscribe();
  }
}
