/**
 * @fileoverview Sign-in component for WiOn E-commerce.
 * @author WiOn E-commerce Development Team
 * @version 1.0.0
 * @description This component handles the user sign-in process, including
 * phone number input, password verification, and error handling for failed attempts.
 */

import { CommonModule } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { WiECommerceDirtyErrorStateMatcher } from '../../../core/utilities/error-state-matcher.util';
import { phoneValidator } from '../../../core/utilities/phone-validator.util';
import { TDSButtonModule } from "tds-ui/button";
import { TDSButtonMenuModule } from 'tds-ui/button-menu';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSHeaderModule } from 'tds-ui/header';
import { TDSInputNumberModule } from 'tds-ui/input-number';
import {
  TDSModalModule,
  TDSModalService
} from 'tds-ui/modal';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { TDSInputModule } from "tds-ui/tds-input";
import { SignInErrorResponse, SignInReq, SignInResponse } from '../../../core/models/sign-in.model';
import { AuthService } from '../../../core/services/auth.service';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';
import { finalize, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { TDSDestroyService } from 'tds-ui/core/services';
import { EMPTY, interval } from 'rxjs';
import { UserService } from '../../../features/users/services/user.service';
import { UserInfo } from '../../../core/models/user.model';
import { NumbersOnlyDirective } from '../../../shared/directives/numbers-only.directive';
import { StoreService } from 'app/features/stores/services/store.service';
import { Store } from 'app/features/stores/models/store.model';
import { StoreBranch } from 'app/features/branches/models/store-branch.model';
import { TDSNotificationModule, TDSNotificationService } from "tds-ui/notification";
import { ConfirmForgotPasswordComponent } from '../forgot-password/confirm-forgot-password/confirm-forgot-password.component';

interface SignInForm {
  phoneNumber: FormControl<string | null>;
  password: FormControl<string | null>;
}

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [
    CommonModule,
    TDSHeaderModule,
    TDSFormFieldModule,
    TDSInputModule,
    ReactiveFormsModule,
    TDSButtonModule,
    TDSButtonMenuModule,
    RouterLink,
    TDSInputNumberModule,
    TDSModalModule,
    TDSSpinnerModule,
    NumbersOnlyDirective,
    TDSNotificationModule
  ],
  providers: [
    TDSModalService,
    TDSDestroyService,
    {
      provide: ErrorStateMatcher,
      useClass: WiECommerceDirtyErrorStateMatcher
    }
  ],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.scss'
})
export class SignInComponent implements OnInit {
  signInForm!: FormGroup;
  toggle = false;
  passType = "password";
  textType = "text";
  type = this.passType;
  isLoading = false;

  @ViewChild('signInLocked') signInLockedContent!: TemplateRef<void>;
  @ViewChild('iconTmp') customIcon!: TemplateRef<void>;

  expiryTime = '';
  version = '';
  phoneIsTouched = false;

  constructor(
    private readonly _modalService: TDSModalService,
    private readonly _router: Router,
    private readonly _authService: AuthService,
    private readonly _tdsDestroyService: TDSDestroyService,
    private readonly _userService: UserService,
    private readonly _storeService: StoreService,
    private readonly _notificationService: TDSNotificationService,
  ) {

  }

  ngOnInit(): void {
    const state = history.state;
    if (state) {
      const phone = state['phone'];
      this.createSignInForm(phone);
    } else {
      this.createSignInForm();
    }

    this.version = this.getVersion();
  }

  createSignInForm(phoneNum?: string): void {
    this.signInForm = new FormGroup<SignInForm>({
      phoneNumber: new FormControl(phoneNum ?? null, Validators.compose([Validators.required, phoneValidator()])),
      password: new FormControl(null, Validators.required)
    });
  }

  // Getter for phone number form control
  get phoneNumber(): FormControl {
    return this.signInForm.get("phoneNumber") as FormControl;
  }

  // Getter for password form control
  get password(): FormControl {
    return this.signInForm.get("password") as FormControl;
  }

  /**
   * Toggle password visibility
   */
  togglePassword(): void {
    this.toggle = !this.toggle;
    this.type = this.toggle ? this.textType : this.passType;
  }

  // Handle sign-in process
  onSignIn(): void {
    if (!this.signInForm.valid) return;

    this.isLoading = true;
    const signInReq: SignInReq = {
      phoneNumber: this.phoneNumber.value,
      password: this.password.value
    };
    
    this._authService.signIn(signInReq.phoneNumber, signInReq.password)
      .pipe(
        switchMap((res: SignInResponse) => {
          if (res.accessToken) {
            return this._userService.getUserInfo$();
          } else {
            throw new Error('Unauthorized');
          }
        }),
        switchMap((userInfo: UserInfo) => {
          if (!userInfo.roleList.length) {
            this._router.navigateByUrl("");
            return EMPTY;
          } else {
            return this._storeService.getStoreList$();
          }
        }),
        takeUntil(this._tdsDestroyService),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (res: Store[]) => {
          if (res.length === 1 && res[0].storeBranchList.length === 1) {
            this.onAccessBranch(res[0].storeBranchList[0]);
          } else {
            this._router.navigateByUrl("/pick-store");
          }
        },
        error: (err: SignInErrorResponse) => this.handleSignInError(err)
      });
  }

  // Handle sign-in failure
  private _signInFailed(): void {
    this._notificationService.error(
      'Đăng nhập không thành công',
      'Tài khoản không đúng hoặc chưa đăng ký',
    );
  }

  /**
   * Handle locked sign-in notification
   */
  private _signInLocked(expiredTime: Date): void {
    this.startCountdown(expiredTime); // Start countdown for lockout
    const modal = this._modalService.error({
      title: 'Thông báo',
      confirmIcon: this.customIcon,
      content: this.signInLockedContent,
      onCancel: () => { modal.close(); },
      cancelText: "Đóng",
      okText: null
    });
  }

  /**
   * Start countdown timer for lockout
   * @param inputExpiredTime - The time when the lockout expires
   */
  private startCountdown(inputExpiredTime: Date): void {
    const expiredTime = new Date(inputExpiredTime).getTime();
    const now = new Date().getTime();
    let countDown = Math.floor((expiredTime - now) / 1000);

    this.expiryTime = this.formatCountdown(countDown); // Initialize expiry time display

    const subscription = interval(1000)
      .pipe(
        takeWhile(() => countDown > 0),
        takeUntil(this._tdsDestroyService)
      )
      .subscribe(() => {
        countDown--;
        this.expiryTime = this.formatCountdown(countDown);
        if (countDown === 0) {
          subscription.unsubscribe();
        }
      });
  }

  /**
   * Format countdown time into MM:SS format
   * @param countDown - The remaining time in seconds
   * @returns - The formatted time string
   */
  private formatCountdown(countDown: number): string {
    const minutes = Math.floor((countDown % 3600) / 60);
    const seconds = countDown % 60;
    return [minutes, seconds].map(unit => unit.toString().padStart(2, '0')).join(':');
  }

  /**
   * Handle sign-in error responses
   * @param err - The error response from the sign-in API
   */
  private handleSignInError(err: SignInErrorResponse): void {
    if (err.error.code === 'Unauthorized') {
      this._signInFailed();
    } else if (err.error.code === 'Block') {
      const lockTime = err.error.data as { LockTo: Date }; // Extract lock time
      this._signInLocked(lockTime.LockTo); // Show locked sign-in modal
    }
  }
  /**
   * Get version of application
   * @returns - The version string
   */
  getVersion(): string {
    return localStorage.getItem('app_version') ?? '';
  }

  onPhoneBlur(): void {
    this.phoneIsTouched = true;
  }

  onPhoneFocus(): void {
    this.phoneIsTouched = false;
  }

  onAccessBranch(branch: StoreBranch): void {
    this._authService.setCurrentStoreId(branch.storeId);
    this._authService.setCurrentBranchId(branch.id);
    this._authService.setBranchTenantId(branch.tenantId);
    this._authService.connectSignalR();
    this._router.navigate(['/dashboard']);
  }

  confirmForgotPassword(): void {
    this._modalService.create({
      closable: false,
      bodyStyle: { "padding": "0px" },
      content: ConfirmForgotPasswordComponent,
      footer: null
    })
  }
}
