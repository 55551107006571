import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { catchError, Observable, throwError } from 'rxjs';
import { CHECK_EXIST_EMAIL, RESET_PASSWORD_ENDPOINT, SEND_OTP_SMS, SENT_OTP_RESET_PASSWORD_ENDPOINT, SIGN_UP, VERIFY_OTP_RESET_PASSWORD_ENDPOINT, VERIFY_OTP_SMS } from '../constants/wi-account-endpoint.const';
import { SendOtpReq, SendOtpResponse, VerifyOtpReq, VerifyOtpResetPassword, VerifyOtpResponse } from '../models/otp.model';
import { SignUpReq, SignUpResponse } from '../models/sign-up.model';
import { BaseApiService } from './base-api.service';
import { ResetPasswordReq } from '../models/reset-password.model';

// Function to check environment configuration
function checkEnvironmentConfig() {
  console.log('Current environment configuration:');
  console.log('Production:', environment.production);
  console.log('API URL:', environment.apiUrl);
  console.log('WiAccount URL:', environment.wiAccountUrl);
}

// Call the function to check configuration
checkEnvironmentConfig();

@Injectable({
  providedIn: 'root'
})
export class WiAccountService extends BaseApiService {
  protected override readonly apiUrl = environment.wiAccountUrl;

  constructor(_http: HttpClient) {
    super(_http);
  }

  /**
   * Sends a sign-up request to the server.
   * @param data - The sign-up request data.
   * @returns An observable of the sign-up response.
   */
  signUp(data: SignUpReq): Observable<SignUpResponse> {
    return this.post<SignUpResponse>(SIGN_UP, data).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error);
      })
    );
  }

  /**
   * Verifies the OTP sent to the user.
   * @param data - The OTP verification request data.
   * @returns An observable of the OTP verification response.
   */
  verifyOtp(data: VerifyOtpReq): Observable<VerifyOtpResponse> {
    return this.post<VerifyOtpResponse>(VERIFY_OTP_SMS, data).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  /**
   * Sends an OTP to the user's phone number.
   * @param data - The request data containing the phone number and method.
   * @returns An observable of the OTP sending response.
   */
  sendOtp(data: SendOtpReq): Observable<SendOtpResponse> {
    return this.post<SendOtpResponse>(SEND_OTP_SMS, data).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  checkExistEmail(email: string): Observable<boolean> {
    return this.get<boolean>(CHECK_EXIST_EMAIL + `?email=${email}`).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  sendOtpForgotPassword(data: SendOtpReq): Observable<SendOtpResponse> {
    return this.post<SendOtpResponse>(SENT_OTP_RESET_PASSWORD_ENDPOINT, data).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  verifyOtpForgotPassword(data: VerifyOtpReq): Observable<VerifyOtpResetPassword> {
    return this.post<VerifyOtpResetPassword>(VERIFY_OTP_RESET_PASSWORD_ENDPOINT, data).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  resetPassword(data: ResetPasswordReq): Observable<any> {
    return this.post<any>(RESET_PASSWORD_ENDPOINT, data).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error.error);
      })
    );
  }
}