export const SIGN_IN_ENDPOINT = '/api/app/user/log-in';
export const SIGN_OUT_ENDPOINT = '/api/app/user/log-out';
export const REFRESH_TOKEN_ENDPOINT = '/api/app/user/refresh-token';
export const USER_INFO_ENDPOINT = '/api/app/user';

export const STORE_ENDPOINT = '/api/app/store';
export const STORE_CATEGORY_ENDPOINT = '/api/app/store-category';
export const STORE_BRANCH_ENDPOINT = '/api/app/store-branch';
export const ACTIVE_STORE_BRANCH_ENDPOINT = '/api/app/store-branch/active';

export const UPLOAD_IMAGE_ENDPOINT = '/api/app/file';

export const ROLE_ENDPOINT = '/api/app/role';
export const ROLE_V2_ENDPOINT = '/api/app/role/list';
export const USER_PERMISSION_ENDPOINT = '/api/app/role/user-permissions';
export const DEFAULT_PERMISSION_ENDPOINT = '/api/app/role/default-permissions';
export const UPDATE_USER_ROLE_IN_BRANCHES_ENDPOINT = '/api/app/role/user-roles-in-store-branches'
export const MENU_IN_ROLE_ENDPOINT = '/api/app/role/menu';

export const EMPLOYEE_ENDPOINT = '/api/app/employee';
export const EMPLOYEE_LIST_ENDPOINT = '/api/app/employee/list';
export const INVITE_EMPLOYEE_ENDPOINT = '/api/app/employee/invite';
export const LOCK_UNLOCK_EMPLOYEE_ENDPOINT = '/api/app/store-branch/employee';
export const DELETE_EMPLOYEE_ENDPOINT = '/api/app/store-branch/employee/delete';
export const CHECK_PHONE_NUMBER_ENDPOINT = '/api/app/employee/check-phone-number'

export const CATEGORY_LIST_ENDPOINT = '/api/app/omni-category';
export const CHECK_CATEGORY_NAME_ENDPOINT = '/api/app/omni-category/is-exist-by-name';

export const ATTRIBUTE_LIST_ENDPOINT = '/api/app/omni-attribute';
export const CHECK_ATTRIBUTE_NAME_ENDPOINT = '/api/app/omni-attribute/is-exist-by-name';

export const ATTRIBUTE_VALUE_LIST_ENDPOINT = '/api/app/omni-attribute-value';
export const CHECK_ATTRIBUTE_VALUE_NAME_ENDPOINT = '/api/app/omni-attribute-value/is-exist-by-name';