import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSDatePickerModule } from 'tds-ui/date-picker';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSPopoverModule } from 'tds-ui/popover';
import { TDSSelectModule } from 'tds-ui/select';
import { TDSTagModule } from 'tds-ui/tag';
import { TDSInputModule } from 'tds-ui/tds-input';
import { EEmployeeStatus } from '../../enums/employee-status.enum';
import { differenceInCalendarDays } from 'date-fns';
import { IEmployeeStatus, IFilterEmployee } from '../../models/filter-employee.model';
import { FormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { TDSBadgeModule } from "tds-ui/badges";

@Component({
  selector: 'app-filter-employee',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TDSFormFieldModule,
    TDSInputModule,
    TDSButtonModule,
    TDSTagModule,
    TDSPopoverModule,
    TDSSelectModule,
    TDSDatePickerModule,
    TDSBadgeModule
  ],
  templateUrl: './filter-employee.component.html',
  styleUrl: './filter-employee.component.scss'
})
export class FilterEmployeeComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInput!: ElementRef;

  // Core filter states
  searchValue: string = '';
  isVisible: boolean = false;
  currentFilter: IFilterEmployee = {};
  isApplyingFilter: boolean = false;

  // Filter criteria
  lstStatus: IEmployeeStatus[] = [
    { id: 1, name: "Chờ xác nhận", value: EEmployeeStatus.Pending, checked: false },
    { id: 2, name: "Đang làm việc", value: EEmployeeStatus.Active, checked: false },
    { id: 3, name: "Ngưng làm việc", value: EEmployeeStatus.Inactive, checked: false }
  ];

  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) > 0;
  rangeDate: any = null;
  branches: any[] = [];
  stores: any[] = [];
  isDefaultWorkSpace = false;

  @Output() onChangeFilter = new EventEmitter<IFilterEmployee>();

  constructor() { }

  ngAfterViewInit(): void {
    // Search input handling with debounce
    fromEvent(this.searchInput.nativeElement, 'input').pipe(
      debounceTime(500),
      distinctUntilChanged(),
      map((event: any) => event.target.value)
    ).subscribe(this.handleSearch.bind(this));
  }

  ngOnInit(): void {}

  isNotEmptyObject(obj: object): boolean {
    return obj && Object.keys(obj).length > 0;
  }

  isOnlySearchFilter(): boolean {
    return Object.keys(this.currentFilter).length === 1 && 'search' in this.currentFilter;
  }

  // Filter Operations
  onApply(): void {
    this.isApplyingFilter = true;
    this.currentFilter = this.buildFilterObject();
    this.isVisible = false;
    this.onChangeFilter.emit(this.currentFilter);
  }

  onCancel(): void {
    // Restore previous state
    this.restoreFilterState();
    this.isVisible = false;
  }

  addFilter(): void {
    // Load current filter state into temp
    this.loadCurrentFilterToUI();
    this.isVisible = true;
  }

  deleteFilter(): void {
    this.resetAllFilters();
    this.onChangeFilter.emit({});
  }

  // Filter Criteria Handlers
  clearFilterStatus(status: IEmployeeStatus): void {
    if (this.currentFilter.statuses) {
      this.currentFilter.statuses = this.currentFilter.statuses.filter(s => s.id !== status.id);
      this.updateStatusChecked();

      if (!this.currentFilter.statuses.length) delete this.currentFilter.statuses;
      this.onChangeFilter.emit(this.currentFilter);
    }
  }

  clearRangeDate(): void {
    this.rangeDate = null;
    delete this.currentFilter.rangeDate;
    this.onChangeFilter.emit(this.currentFilter);
  }

  // Private Helper Methods
  private handleSearch(value: string): void {
    this.currentFilter.search = value || undefined;
    this.onChangeFilter.emit(this.currentFilter);
  }

  private buildFilterObject(): IFilterEmployee {
    const filter: IFilterEmployee = {};
    
    const selectedStatuses = this.lstStatus.filter(s => s.checked);
    if (selectedStatuses.length) filter.statuses = selectedStatuses;
    
    if (this.rangeDate) {
      filter.rangeDate = {
        startDate: this.rangeDate[0],
        endDate: this.rangeDate[1]
      };
    }

    if (this.searchValue) filter.search = this.searchValue;
    
    return filter;
  }

  private resetAllFilters(): void {
    this.isApplyingFilter = false;
    this.currentFilter = {};
    this.searchValue = '';
    this.lstStatus.forEach(item => item.checked = false);
    this.rangeDate = null;
    this.isVisible = false;
  }

  private restoreFilterState(): void {
    this.loadFilterToUI(this.currentFilter);
  }

  private loadCurrentFilterToUI(): void {
    this.loadFilterToUI(this.currentFilter);
  }

  private loadFilterToUI(filter: IFilterEmployee): void {
    // Reset all first
    this.lstStatus.forEach(item => item.checked = false);
    this.rangeDate = null;

    // Apply filter state
    if (filter.statuses) {
      filter.statuses.forEach(status => {
        const found = this.lstStatus.find(s => s.id === status.id);
        if (found) found.checked = true;
      });
    }

    if (filter.rangeDate) {
      this.rangeDate = [filter.rangeDate.startDate, filter.rangeDate.endDate];
    }
  }

  private updateStatusChecked(): void {
    this.lstStatus.forEach(status => {
      status.checked = this.currentFilter.statuses?.some(s => s.id === status.id) || false;
    });
  }

  onChangeShop(event: any): void {
    console.log("onChangeShop", event);
  }

  onChangeEnterprise(event: any): void {
    console.log("onChangeEnterprise", event);
  }

  getFilterCount(): number {
    let count = 0;
    if (!this.currentFilter) return count;
    
    if (this.currentFilter['statuses']?.length) {
      count += this.currentFilter['statuses'].length;
    }
    
    if (this.currentFilter['rangeDate']) {
      count += 1;
    }
    
    if (this.currentFilter['branches']?.length) {
      count += 1;
    }
    
    if (this.currentFilter['stores']?.length) {
      count += 1;
    }
    
    return count;
  }

  onCheckedItem(item: IEmployeeStatus): void {
    this.lstStatus.forEach(s => {
      s.checked = false;
    });
    
    item.checked = true;
  }
}
