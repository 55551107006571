import { Injectable } from '@angular/core';
import { CHECK_PHONE_NUMBER_ENDPOINT, EMPLOYEE_ENDPOINT, EMPLOYEE_LIST_ENDPOINT, INVITE_EMPLOYEE_ENDPOINT } from 'app/core/constants/api-endpoint.const';
import { ApiService } from 'app/core/services/api.service';
import { Observable } from 'rxjs';
import { IEmployeeList, IEmployeeQueryParams } from '../models/employee.model';
import { IInviteEmployeeReq } from '../models/invite-employee.model';
import { IUpdateUserInfoReq } from 'app/features/users/models/update-user-info-req.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(
    private readonly apiService: ApiService
  ) { }

  getEmployeesInStore$(
    storeId: string,
    queryParams: IEmployeeQueryParams
  ): Observable<IEmployeeList> {
    return this.apiService.post<IEmployeeList>(`${EMPLOYEE_LIST_ENDPOINT}/${storeId}`, queryParams);
  }

  inviteEmployee$(data: IInviteEmployeeReq): Observable<void> {
    return this.apiService.post(INVITE_EMPLOYEE_ENDPOINT, data);
  }

  updateEmployeeInfo$(employeeId: string, payload: IUpdateUserInfoReq): Observable<void> {
    return this.apiService.put(`${EMPLOYEE_ENDPOINT}/${employeeId}`, payload);
  }

  checkExistPhoneNumber$(phoneNumber: string): Observable<boolean> {
    return this.apiService.post(CHECK_PHONE_NUMBER_ENDPOINT, {phoneNumber: phoneNumber});
  }
}
