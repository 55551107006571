import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSTagModule } from 'tds-ui/tag';
import { FilterRoleService } from '../../services/filter-role.service';
import { IFilterRole, IFilterRoleStatus } from '../../models/filter-role.model';

@Component({
  selector: 'app-filter-role-criteria-display',
  standalone: true,
  imports: [
    CommonModule,
    TDSTagModule,
    TDSButtonModule
  ],
  templateUrl: './filter-role-criteria-display.component.html',
  styleUrl: './filter-role-criteria-display.component.scss'
})
export class FilterRoleCriteriaDisplayComponent implements OnInit {
  currentFilter: IFilterRole = {};

  constructor(
    private filterRoleService: FilterRoleService
  ) {}

  ngOnInit(): void {
    this.filterRoleService.currentFilterCriteria$.subscribe((filters: IFilterRole | null) => {
      if (filters) this.currentFilter = filters;
    })
  }

  addFilter(): void {
    this.filterRoleService.addFilter();
  }

  clearFilterStatus(item: IFilterRoleStatus): void {
    if (this.currentFilter.status) {
      this.currentFilter.status = this.currentFilter.status.filter((s: IFilterRoleStatus) => s.id !== item.id);

      if (!this.currentFilter.status.length) delete this.currentFilter.status;

      this.filterRoleService.changeFilterCriteria(this.currentFilter);
    }
  }

  clearRangeDate(): void {
    delete this.currentFilter['rangeDate'];
    this.filterRoleService.changeFilterCriteria(this.currentFilter);
  }

  deleteFilter(): void {
    this.filterRoleService.deleteFilter();
  }

  clearStore(): void {
    delete this.currentFilter['store'];
    this.filterRoleService.changeFilterCriteria(this.currentFilter);
  }

  isNotEmptyObject(obj: object): boolean {
    return obj && Object.keys(obj).length > 0;
  }

  isOnlySearchFilter(currentFilter: IFilterRole): boolean {
    return Object.keys(currentFilter).length === 1 && 'search' in currentFilter;
  }

  isFilterBranch(currentFilter: IFilterRole): boolean {
    return Object.keys(currentFilter).length === 1 && 'storeBranchIds' in currentFilter;
  }

  isSearchAndFilterBranch(currentFilter: IFilterRole): boolean {
    return Object.keys(currentFilter).length === 2 && 'storeBranchIds' in currentFilter && 'search' in currentFilter;
  }

  isDisplayFilter(currentFilter: IFilterRole): boolean {
    return !(this.isOnlySearchFilter(currentFilter) || this.isFilterBranch(currentFilter)
      || this.isSearchAndFilterBranch(currentFilter));
  }
}
