import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthService } from 'app/core/services/auth.service';
import { StoreBranch, UserStoreBranch } from 'app/features/branches/models/store-branch.model';
import { BranchService } from 'app/features/branches/services/branch.service';
import { differenceInCalendarDays } from 'date-fns';
import { debounceTime, distinctUntilChanged, fromEvent, map, takeUntil } from 'rxjs';
import { TDSBadgeModule } from 'tds-ui/badges';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSDatePickerModule } from 'tds-ui/date-picker';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSMessageService } from 'tds-ui/message';
import { TDSPopoverModule } from 'tds-ui/popover';
import { TDSSelectModule } from 'tds-ui/select';
import { TDSTagModule } from 'tds-ui/tag';
import { TDSInputModule } from 'tds-ui/tds-input';
import { FilterRoleService } from '../../services/filter-role.service';
import { IFilterRole, IFilterRoleStatus } from '../../models/filter-role.model';

@Component({
  selector: 'app-filter-role',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TDSFormFieldModule,
    TDSInputModule,
    TDSButtonModule,
    TDSTagModule,
    TDSPopoverModule,
    TDSSelectModule,
    TDSDatePickerModule,
    TDSBadgeModule
  ],
  templateUrl: './filter-role.component.html',
  styleUrl: './filter-role.component.scss'
})
export class FilterRoleComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInput!: ElementRef;

  // Core filter states
  searchValue: string = '';
  isVisible: boolean = false;
  currentFilter: IFilterRole = {};
  isApplyingFilter: boolean = false;
  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) > 0;
  rangeDate: any = null;

  lstStatus: IFilterRoleStatus[] = [
    { id: 0, name: "Mặc định", isDefault: true, checked: false },
    { id: 1, name: "Tùy chỉnh", isDefault: false, checked: false },
  ];
  
  branches: StoreBranch[] = [];
  currentBranchId!: string | null | undefined;
  stores: UserStoreBranch[] = [];

  currentStore!: UserStoreBranch | null;
  isOwner = false;

  constructor(
    private readonly authService: AuthService,
    private readonly branchService: BranchService,
    private readonly destroyService: TDSDestroyService,
    private readonly msgService: TDSMessageService,
    private readonly filterRoleService: FilterRoleService
  ) { }

  ngAfterViewInit(): void {
    // Search input handling with debounce
    fromEvent(this.searchInput.nativeElement, 'input').pipe(
      debounceTime(500),
      distinctUntilChanged(),
      map((event: any) => event.target.value)
    ).subscribe(this.handleSearch.bind(this));
  }

  ngOnInit(): void {
    this.isOwner = this.authService.isOwner();

    if (this.isOwner) {
      this.getStoresAndBranches();
    }

    this.filterRoleService.currentFilterCriteria$.subscribe((filters: IFilterRole | null) => {
      if (!filters) return; 

      if (typeof filters === 'object' && (!this.isNotEmptyObject(filters) || this.isSearchAndFilterBranch(filters))) {
        this.resetAllFilters();
      } else {
        this.loadFilterToUI(filters);
      }
    });

    this.filterRoleService.isAddFilter$.subscribe(isAddFilter => {
      if (isAddFilter) {
        this.isVisible = true;
      }
    })
  }

  // Filter Operations
  onApply(): void {
    this.isApplyingFilter = true;
    this.currentFilter = this.buildFilterObject();
    this.filterRoleService.changeFilterCriteria(this.currentFilter);
    this.isVisible = false;
  }

  onCancel(): void {
    // Restore previous state
    this.restoreFilterState();
    this.isVisible = false;
  }

  addFilter(): void {
    // Load current filter state into temp
    this.loadCurrentFilterToUI();
    this.isVisible = true;
  }

  deleteFilter(): void {
    this.resetAllFilters();
    this.filterRoleService.changeFilterCriteria(this.currentFilter);
  }

  clearRangeDate(): void {
    this.rangeDate = null;
    delete this.currentFilter.rangeDate;
    this.filterRoleService.changeFilterCriteria(this.currentFilter);
  }

  // Private Helper Methods
  private handleSearch(value: string): void {
    this.currentFilter.search = value || undefined;
    this.filterRoleService.changeFilterCriteria(this.currentFilter);
  }

  private buildFilterObject(): IFilterRole {
    const filter: IFilterRole = {};
    
    const selectedStatus = this.lstStatus.filter(s => s.checked);
    if (selectedStatus.length) filter.status = selectedStatus;
    
    if (this.rangeDate) {
      filter.rangeDate = {
        startDate: this.rangeDate[0],
        endDate: this.rangeDate[1]
      };
    }

    if (this.searchValue) filter.search = this.searchValue;

    if (this.currentBranchId) filter.storeBranchIds = [this.currentBranchId];

    if (this.currentStore) {
      filter.store = {
        id: this.currentStore.id,
        name: this.currentStore.name
      }
    }

    return filter;
  }

  private resetAllFilters(): void {
    this.isApplyingFilter = false;
    this.currentFilter = {};
    this.searchValue = '';
    this.lstStatus.forEach(item => item.checked = false);
    this.rangeDate = null;
    this.currentBranchId = null;
    this.currentStore = null;
    this.isVisible = false;
  }

  private restoreFilterState(): void {
    this.loadFilterToUI(this.currentFilter);
  }

  private loadCurrentFilterToUI(): void {
    this.loadFilterToUI(this.currentFilter);
  }

  private loadFilterToUI(filter: IFilterRole): void {
    // Reset all first
    this.lstStatus.forEach(item => item.checked = false);
    this.rangeDate = null;
    this.currentBranchId = null;
    this.currentStore = null;

    // Apply filter state
    if (filter.status) {
      filter.status.forEach((status: IFilterRoleStatus) => {
        const found = this.lstStatus.find(s => s.id === status.id);
        if (found) found.checked = true;
      });
    }

    if (filter.rangeDate) {
      this.rangeDate = [filter.rangeDate.startDate, filter.rangeDate.endDate];
    }

    if (filter.storeBranchIds) {
      this.currentBranchId = filter.storeBranchIds[0];
    }

    if (filter.store !== undefined && filter.store.id) {
      this.currentStore = this.stores.find(store => store.id === filter.store!.id) as UserStoreBranch;
    }
  }

  onChangeStore(event: UserStoreBranch): void {
    this.currentStore = event;
  }

  getFilterCount(): number {
    let count = 0;
    if (!this.currentFilter) return count;
    
    if (this.currentFilter['status']?.length) {
      count += this.currentFilter['status'].length;
    }
    
    if (this.currentFilter['rangeDate']) {
      count += 1;
    }
    
    if (this.currentFilter['store']) {
      count += 1;
    }
    
    return count;
  }

  onCheckedItem(item: IFilterRoleStatus): void {
    this.lstStatus.forEach(s => {
      s.checked = false;
    });
    
    item.checked = true;
  }

  getStoresAndBranches(): void {
    this.branchService.getUserStoreBranches$()
      .pipe(
        takeUntil(this.destroyService),
      )
      .subscribe({
        next: (res: UserStoreBranch[]) => {
          this.stores = res;
          res.forEach(item => {
            this.branches = [...this.branches, ...item.storeBranchList];
          });
        },
        error: (err) => {
          this.msgService.error("Lỗi khi lấy danh sách cửa hàng, vui lòng thử lại sau");
        }
      });
  }

  isNotEmptyObject(obj: object): boolean {
    return obj && Object.keys(obj).length > 0;
  }

  isOnlySearchFilter(currentFilter: IFilterRole): boolean {
    return Object.keys(currentFilter).length === 1 && 'search' in currentFilter;
  }

  isSearchAndFilterBranch(currentFilter: IFilterRole): boolean {
    return Object.keys(currentFilter).length === 2 && 'storeBranchIds' in currentFilter && 'search' in currentFilter;
  }

  onSelectBranch(event: StoreBranch): void {
    this.currentBranchId = !event ? null : event.id;

    this.currentFilter = this.buildFilterObject();
    this.filterRoleService.changeFilterCriteria(this.currentFilter);
  }
}
