import { Component, Input, OnInit } from '@angular/core';
import { TDSTagModule } from 'tds-ui/tag';
import { EEmployeeStatus } from '../../enums/employee-status.enum';
import { TDSMapperPipeModule } from 'tds-ui/cdk/pipes/mapper'
import { ButtonSize } from 'tds-ui/core/config';

@Component({
  selector: 'app-employee-status',
  standalone: true,
  imports: [
    TDSTagModule,
    TDSMapperPipeModule
  ],
  templateUrl: './employee-status.component.html',
  styleUrl: './employee-status.component.scss'
})
export class EmployeeStatusComponent implements OnInit {
  @Input() status!: EEmployeeStatus;
  @Input() statusName!: string;
  @Input() size: ButtonSize = 'md';
  
  ngOnInit(): void {
  }

  readonly employeeStatusColor = (status: EEmployeeStatus): string => {
    let color: string = '';
    switch (status) {
      case EEmployeeStatus.Active:
        color = 'success';
        break;
      case EEmployeeStatus.Inactive:
        color = 'secondary';
        break;
      case EEmployeeStatus.Pending:
        color = 'info';
        break;
      default:
        color = 'neutral';
        break;
    }
    return color;
  }

}
