<div class="flex flex-col p-6">
  <div class="flex flex-col gap-3">
    <div class="w-[72px] h-[72px]">
      <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M42.6329 31.5827H38.2156V24.9566C38.2156 22.5226 36.2321 20.5392 33.7982 20.5392C31.3642 20.5392 29.3808 22.5226 29.3808 24.9566V31.5827H24.9634V24.9566C24.9634 20.0842 28.9258 16.1218 33.7982 16.1218C38.6705 16.1218 42.6329 20.0842 42.6329 24.9566V31.5827Z" fill="#CCD2D4"/>
        <path d="M67.6158 22.5758L65.7712 27.4436C61.9811 13.3123 49.1044 2.86963 33.7982 2.86963C15.5278 2.86963 0.667725 17.7297 0.667725 36.0001C0.667725 54.2704 15.5278 69.1305 33.7982 69.1305C42.6462 69.1305 50.9686 65.6849 57.228 59.4299L54.1049 56.3068C48.6759 61.727 41.4668 64.7131 33.7982 64.7131C17.9662 64.7131 5.08512 51.832 5.08512 36.0001C5.08512 20.1681 17.9662 7.28702 33.7982 7.28702C47.5848 7.28702 59.1275 17.0583 61.8795 30.0366L56.4735 25.1469L53.3971 28.4123L65.7712 39.4108L71.8969 24.2567L67.6158 22.5758Z" fill="#CCD2D4"/>
        <path d="M45.001 29.2498H22.5005C21.2585 29.2498 20.2505 30.2578 20.2505 31.4998V49.5002C20.2505 50.7422 21.2585 51.7502 22.5005 51.7502H45.001C46.243 51.7502 47.251 50.7422 47.251 49.5002V31.4998C47.251 30.2578 46.243 29.2498 45.001 29.2498Z" fill="#27A745"/>
      </svg>
    </div>
    <div>
      <p class="tds-heading-5 font-semibold">Quên mật khẩu</p>
      <ul class="list-disc tds-body-2 px-6 pt-1.5">
        <li>
          Mật khẩu của tài khoản được thiết lập dùng chung cho tất cả các ứng dụng trên WiAccount như WiOn POS, WiPoint, WiCo Check,..
        </li>
        <li>
          Khi đặt lại mật khẩu đồng nghĩa với việc bạn sẽ 
          <span class="font-semibold">đổi mật khẩu cho tất cả các ứng dụng trên hệ thống của WiAccount.</span>
        </li>
      </ul>
    </div>
    <div class="tds-body-2">
      Vui lòng bấm vào nút Xác nhận nếu bạn vẫn muốn Đổi mật khẩu mới.
    </div>
  </div>

  <div class="w-full flex gap-2 justify-end px-6 pb-6" 
    *tdsModalFooter
  >
    <button tds-button-outline class="min-w-[100px]" color="secondary"
      (tdsBtnClick)="onClose()"
    >
        Đóng
    </button>
    <button tds-button class="min-w-[100px]" color="success"
      (tdsBtnClick)="onConfirm()"
    >
        Xác nhận
    </button>
  </div>
</div>
