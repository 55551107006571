<div class="flex flex-col gap-2 h-full w-full">
    <div class="rounded-md bg-white px-4 py-3">
        <app-add-attribute-value
            [storeId]="storeId"
            [listOfAttribute]="listOfAttribute"
            [attribute]="attribute"
            (loadDataEvent)="loadingDataEvent($event)"
            (loadingEvent)="loadingEvent($event)">
        </app-add-attribute-value>
    </div>
    <div class="rounded-md bg-white h-full w-full flex flex-col">
        <div class="flex justify-between p-1.5 items-center">
            <div class="tds-heading-6 font-semibold">Giá trị thuộc tính</div>
            <div class="flex gap-2">
                <tds-form-field class="w-[250px] pl-1">
                    <input tdsInput 
                        class="text-neutral-1-900" 
                        [tdsInputDebounce]="750"
                        placeholder="Tìm theo tên giá trị thuộc tính" 
                        [(ngModel)]="searchText"
                        (inputKeyup)="onSearch($event)"
                        autocomplete="off" />
                    <span tdsPrefix><em class="text-neutral-1-500 tdsi-search-fill"></em></span>
                </tds-form-field>
                <button 
                    tds-button-outline-icon 
                    tds-popover 
                    popoverTrigger="click" 
                    color="secondary" 
                    tds-tooltip
                    tooltipTitle="Ẩn/hiện cột" 
                    tooltipPlacement="topRight" 
                    popoverPlacement="bottomRight">
                    <i class="tdsi-column-setting-fill"></i>
                </button>
            </div>
        </div>
        <div class="w-full h-full flex tds-body-2">
            <tds-table #attributeValueTable 
                showPagination
                showSizeChanger 
                [loading]="isLoading" 
                [total]="paging.total"
                [pageSize]="paging.size" 
                [pageIndex]="paging.index" 
                [frontPagination]="false"
                [scroll]="{'x':'auto','y': 'auto'}" 
                (queryParams)="onQueryParamsChange($event)" 
                [listData]="listOfAttributeValue">
                <thead>
                    <tr>
                        <th [width]="'220px'">Thuộc tính</th>
                        <th [width]="'220px'">Giá trị thuộc tính</th>
                        <th [width]="'200px'">Tạo bởi</th>
                        <th [width]="'140px'">Ngày tạo</th>
                        <th [width]="'200px'">Cập nhật bởi</th>
                        <th [width]="'140px'">Ngày cập nhật</th>
                        <th [width]="'118px'" [tdsRight]="true">Thao tác</th>
                    </tr>
                </thead>
                <tbody>
                    @for(attributeValue of listOfAttributeValue; track attributeValue.id) {
                    <tr>
                        <td>
                            <div class="tds-body-2 break-words">
                                {{ attributeValue.attributeName }}
                            </div>
                        </td>
                        <td>
                            <div class="tds-body-2 break-words">
                                {{ attributeValue.name }}
                            </div>
                        </td>
                        <td>
                            @if (attributeValue.creator) {
                            <app-avatar 
                                [avatar]="attributeValue.creator.avatar"
                                [isSystem]="attributeValue.creator.isSystem"
                                [textName]="attributeValue.creator.name">
                            </app-avatar>
                            } @else {
                                <span>---</span>
                            }
                        </td>
                        <td>
                            <span>{{attributeValue.creationTime | date: 'dd/MM/yyyy'}}</span>
                        </td>
                        <td>
                            @if (attributeValue.updator) {
                            <app-avatar 
                                [avatar]="attributeValue.updator.avatar"
                                [isSystem]="attributeValue.creator.isSystem"
                                [textName]="attributeValue.updator.name">
                            </app-avatar>
                            } @else {
                                <span>---</span>
                            }
                        </td>
                        <td>
                            @if (attributeValue.lastModificationTime) {
                            <span>{{attributeValue.lastModificationTime | date: 'dd/MM/yyyy'}}</span>
                            } @else {
                                <span>--</span>
                            }
                        </td>
                        <td [tdsRight]="true">
                            <div class="flex gap-2">
                                <button tds-button-flat-icon 
                                    color="info" 
                                    size="sm" 
                                    (click)="editAttributeValue(attributeValue)"
                                    tds-tooltip
                                    tooltipTitle="Chỉnh sửa">
                                    <i class="tdsi-edit-line"></i>
                                </button>
                                <button tds-button-flat-icon 
                                    color="error" 
                                    size="sm" 
                                    (click)="removeAttributeValue(attributeValue)"
                                    tds-tooltip 
                                    tooltipTitle="Xóa">
                                    <i class="tdsi-trash-line"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    }
                </tbody>
            </tds-table>
        </div>
    </div>
</div>
