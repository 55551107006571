import { Component, inject, OnInit } from '@angular/core';
import { TDS_MODAL_DATA, TDSModalModule, TDSModalRef } from "tds-ui/modal";
import { IEmployee } from '../../models/employee.model';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSMessageService } from 'tds-ui/message';
import { InviteInfo } from '../../models/invite-employee.model';

@Component({
  selector: 'app-invited-successfully',
  standalone: true,
  imports: [
    TDSModalModule,
    TDSButtonModule
  ],
  templateUrl: './invited-successfully.component.html',
  styleUrl: './invited-successfully.component.scss'
})
export class InvitedSuccessfullyComponent implements OnInit {
  tdsData: {employee?: IEmployee; inviteInfo?: InviteInfo} = inject(TDS_MODAL_DATA);
  modalRef = inject(TDSModalRef<InvitedSuccessfullyComponent>);
  msgService = inject(TDSMessageService);
  phoneNumber!: string;
  employeeName!: string;
  invitationUrl!: string;
  copyUrl!: string;

  ngOnInit(): void {
    this.phoneNumber = this.tdsData.employee === undefined ? this.tdsData.inviteInfo!.phoneNumber : this.tdsData.employee.phoneNumber;
    this.employeeName = this.tdsData.employee === undefined ? this.tdsData.inviteInfo!.employeeName : this.tdsData.employee.name;
    this.handleInvitationUrl();
  }

  handleInvitationUrl(): void {
    this.invitationUrl = "https://wion-ecommerce.public.rke.app.dev.tmtco.org/sign-in";
    this.copyUrl = `${this.invitationUrl}?phoneNumber=${this.phoneNumber}`;
  }

  onClose(): void {
    this.modalRef.close();
  }

  onCopyToClipboard(): void {
    navigator.clipboard.writeText(this.copyUrl).then(() => {
      this.msgService.success('Đã sao chép liên kết');
    }).catch((error) => {
      console.error('Không thể sao chép liên kết: ', error);
    });
  }
}
