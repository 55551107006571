import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { AuthService } from 'app/core/services/auth.service';
import { WiECommerceDirtyErrorStateMatcher } from 'app/core/utilities/error-state-matcher.util';
import { NoWhitespaceValidator } from 'app/shared/custom-validators/no-whitespace-validator';
import { Observable, map, of, switchMap, timer } from 'rxjs';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalModule, TDSModalRef } from 'tds-ui/modal';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { TDSHelperString, TDSSafeAny } from 'tds-ui/shared/utility';
import { TDSInputModule } from "tds-ui/tds-input";
import { CATEGORY_RULE } from '../../constants/category-rule.constant';
import { CreateCategory } from '../../models/category.model';
import { CategoryService } from '../../service/category.service';
import { TDSSelectModule } from 'tds-ui/select';
import { Store } from 'app/features/stores/models/store.model';
import { StoreService } from 'app/features/stores/services/store.service';

@Component({
  selector: 'app-add-category',
  standalone: true,
  imports: [
    CommonModule,
    TDSFormFieldModule,
    TDSInputModule,
    TDSModalModule,
    TDSButtonModule,
    ReactiveFormsModule,
    TDSSelectModule
  ],
  providers: [
    {
      provide: ErrorStateMatcher,
      useClass: WiECommerceDirtyErrorStateMatcher
    }
  ],
  templateUrl: './add-category.component.html',
  styleUrl: './add-category.component.scss'
})
export class AddCategoryComponent implements OnInit {
  form!: FormGroup;
  rule = CATEGORY_RULE;
  isLoading: boolean = false;
  storeId: string | null = null;
  isOwner: boolean = false;
  listOfStore: Store[] = [];
  isLoadingStore: boolean = false;
  constructor(private fb: FormBuilder,
    private categoryService: CategoryService,
    private authService: AuthService,
    private messageService: TDSMessageService,
    private storeService: StoreService,
    private modal: TDSModalRef) {

  }

  ngOnInit(): void {
    this.getCurrentStore();
    this.getIsOwner();
    this.getListStore();
    this.createForm();
  }

  getIsOwner() {
    this.isOwner = this.authService.isOwner();
  }

  getListStore() {
    this.isLoadingStore = true;
    this.storeService.getStoreList$()
      .subscribe({
        next: (res: Store[]) => {
          this.listOfStore = res;
        }
        , error: () => this.messageService.error("Có lỗi khi lấy danh sách doanh nghiệp!")
        , complete: () => this.isLoadingStore = false
      })
  }

  getCurrentStore() {
    this.storeId = this.authService.getCurrentStoreId();
  }

  createForm() {
    this.form = this.fb.group({
      name: [
        null, Validators.compose([
          Validators.required,
          Validators.minLength(this.rule.name.minLength),
          Validators.maxLength(this.rule.name.maxLength),
          NoWhitespaceValidator(),
        ]), this.checkExistName(this.categoryService)
      ],
      storeId: [
        null
      ]
    })
    // if (this.isOwner) {
    //   this.form.controls['storeId'].addValidators(Validators.required);
    //   this.form.updateValueAndValidity();
    // }
  }

  onCancel() {
    this.modal.destroy(false);
  }

  onSave() {
    this._markDirtyAndTouched(); 
    if (!this.form.valid) return;
    this.isLoading = true;
    const model = this.prepareModel();
    this.categoryService.addCategory$(model)
      .subscribe({
        next: () => {
          this.messageService.success("Thêm danh mục thành công!");
          this.modal.destroy(true);
        }
        , error: () => this.messageService.error("Có lỗi khi thêm danh mục!")
        , complete: () => this.isLoading = false,
      })
  }

  prepareModel(): CreateCategory {
    const valueForm = this.form.value;
    // const model: CreateCategory = {
    //   name: valueForm.name,
    //   storeId: this.isOwner ? valueForm.storeId : this.storeId ?? ''
    // };
    const model : CreateCategory = {
      name: valueForm.name,
      storeId: TDSHelperString.hasValueString(this.storeId) ? this.storeId : ''
    }
    return model;
  }

  private _markDirtyAndTouched() {
    this.form.controls['name'].markAsDirty();
    this.form.controls['name'].markAsTouched();
    this.form.controls['storeId'].markAsDirty();
    this.form.controls['storeId'].markAsTouched();
  }

  checkExistName = (service: CategoryService) => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }

      return timer(500).pipe(
        switchMap(() =>
          service.checkExistCategory$({
            name: control.value,
            storeId: this.storeId ?? ''
          })
            .pipe(
              map((res: TDSSafeAny) => {
                if (res) {
                  return { duplicate: true };
                } else {
                  return null;
                }
              })
            )
        )
      )
    }
  }
}
