<tds-spin [spinning]="isLoading" >
    <div class="flex w-full h-full gap-2">
        <div class="rounded-md bg-white w-1/4 flex flex-col h-full">
            <div class="">
                <app-attribute-panel 
                    (sortDateCreatedEvent)="sortDateCreatedEvent()"
                    (refreshEvent)="loadAttributes()"
                ></app-attribute-panel>
            </div>
            <div
                (click)="changeAttribute(true)"  
                [ngClass]="{
                    'tds-color-background-info-minimal  border-l-2 border-l-info-400 tds-color-text-info !pl-1.5':isChoosenAllAttibute
                }"
                class="flex gap-2 px-2 py-2 items-center text-info-400 cursor-pointer border-b border-[#DBE0E6]">
                <i class="tdsi-bullets-fill text-2xl"></i>
                <span class="text-body-2 font-semibold">Tất cả</span>
            </div>
            <div class="h-full no-scrollbar overflow-y-auto">       
                <ng-template ngFor let-item [ngForOf]="listOfAttribute" let-i="index">
                    <div tds-scroll-into-view [scrollID]="item" 
                        [ngClass]="{
                            'border-l-2 border-l-info-400 tds-color-background-info-minimal !pl-1.5':item.isChoosen
                        }"
                        (click)="changeAttribute(false, item)" 
                        class="px-2 py-3 text-start border-b border-[#DBE0E6] cursor-pointer flex justify-between items-center">
                            <a 
                                tds-popover
                                popoverTrigger="hover" 
                                [popoverContent]="attributeDetail"
                                [popoverVisible]="attributeIndex == i"
                                popoverPlacement="right"
                                (popoverVisibleChange)="enableAttributeDetail(item, i)"
                                class="font-tds-font-family-body tds-body-2 hover:text-info-400 hover:underline">
                                {{item.name}}
                            </a>
                            <ng-template #attributeDetail>
                                <div class="w-80 flex flex-col gap-2">
                                    <div class="grid grid-cols-3 gap-2 items-center">
                                        <span class="font-semibold">Tạo bởi:</span>
                                        @if (selectedAttributeDetail.creator) {
                                            <app-avatar class="col-span-2"
                                                [avatar]="selectedAttributeDetail.creator.avatar"
                                                [isSystem]="selectedAttributeDetail.creator.isSystem"
                                                [textName]="selectedAttributeDetail.creator.name">
                                            </app-avatar>
                                        } @else {
                                            <span>---</span>
                                        }
                                    </div>
                                    <div class="grid grid-cols-3 gap-2 items-center">
                                        <span class="font-semibold">Ngày tạo:</span>
                                        <span>{{selectedAttributeDetail.creationTime | date: 'dd/MM/yyyy'}}</span>
                                    </div>
                                    <div class="grid grid-cols-3 gap-2 items-center">
                                        <span class="font-semibold">Cập nhật bởi:</span>
                                        @if (selectedAttributeDetail.updator) {
                                            <app-avatar class="col-span-2"
                                                [avatar]="selectedAttributeDetail.updator.avatar"
                                                [isSystem]="selectedAttributeDetail.updator.isSystem"
                                                [textName]="selectedAttributeDetail.updator.name">
                                            </app-avatar>
                                        } @else {
                                            <span>---</span>
                                        }
                                    </div>
                                    <div class="grid grid-cols-3 gap-2 items-center">
                                        <span class="font-semibold">Ngày cập nhật:</span>
                                        @if (selectedAttributeDetail.lastModificationTime) {
                                            <span>{{selectedAttributeDetail.lastModificationTime | date: 'dd/MM/yyyy'}}</span>
                                        } @else {
                                            <span>--</span>
                                        }
                                    </div>
                                </div>
                            </ng-template>
                            <div class="flex gap-2">
                                <button 
                                    tds-button-flat-icon
                                    color="info"
                                    size="sm"
                                    tds-tooltip
                                    tooltipTitle="Chỉnh sửa"
                                    tds-popover
                                    popoverTrigger="click"
                                    [popoverContent]="editAttributeTemplate"
                                    [popoverFooter]="footerEditTemplate"
                                    [popoverVisible]="editAttributeIndex == i"
                                    (click)="editAttribute(item, i,$event)"
                                    popoverPlacement="bottomRight"
                                >
                                    <i class="tdsi-edit-line"></i>
                                </button>
                                <button 
                                    tds-button-flat-icon
                                    color="error"
                                    size="sm"
                                    tds-tooltip
                                    tooltipTitle="Xóa"
                                    (tdsBtnClick)="removeAttribute(item,$event)"
                                >
                                    <i class="tdsi-trash-line"></i>
                                </button>
                            </div>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="h-full w-3/4">
            <app-list-attribute-value 
                [listOfAttribute]="listOfAttribute"
                [attribute]="selectedAttribute"
                [storeId]="storeId"
                (loadingAttributeEvent)="loadingAttributeEvent($event)"
                ></app-list-attribute-value>
        </div>
    </div>
 
</tds-spin>

<ng-template #editAttributeTemplate>
    <div class="w-72">
        <form [formGroup]="form" id="form">
            <tds-form-field class="w-full">
                <tds-label tdsRequired="true">Thuộc tính</tds-label>
                <input 
                    tdsInput 
                    autocomplete="off"  
                    placeholder="Tối đa 50 ký tự" 
                    formControlName="name"
                    [maxlength]="rule.name.maxLength"
                    [required]='true' />
                @if (this.form.get('name')?.errors?.['required'] || this.form.get('name')?.errors?.['whitespace']) {
                    <tds-error>Vui lòng nhập tên thuộc tính</tds-error>
                }
                @if (this.form.get('name')?.errors?.['duplicate']) {
                    <tds-error>Thuộc tính đã tồn tại</tds-error>
                }
            </tds-form-field>
        </form>
    </div>
</ng-template>

<ng-template #footerEditTemplate>
    <div class="flex justify-end">
        <button tds-button color="secondary" class="mr-2" size="sm" (click)="closeEdit($event)">
            Đóng
        </button>
        <button tds-button size="sm" (click)="saveEditAttribute()">
            Lưu
        </button>
    </div>
</ng-template>

