import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { BranchService } from 'app/features/branches/services/branch.service';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDS_MODAL_DATA, TDSModalModule } from 'tds-ui/modal';
import { IEmployee } from '../../models/employee.model';

@Component({
  selector: 'app-employee-in-all-branches',
  standalone: true,
  imports: [
    CommonModule,
    TDSModalModule,
    TDSAvatarModule
  ],
  providers: [
    BranchService,
    TDSDestroyService
  ],
  templateUrl: './employee-in-all-branches.component.html',
  styleUrl: './employee-in-all-branches.component.scss'
})
export class EmployeeInAllBranchesComponent {
  readonly tdsModalData: { employee: IEmployee, type: string } = inject(TDS_MODAL_DATA);
  employee!: IEmployee;
  type!: string;

  constructor() {}
  
  ngOnInit(): void {
    this.employee = this.tdsModalData.employee;
    this.type = this.tdsModalData.type;
  }
}
