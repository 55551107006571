<div class="h-full w-full bg-white">
  <tds-header tdsTheme="dark" urlLogoText="/assets/images/WiOnLogo.svg" urlLogo="/assets/images/WiOnLogo.svg"
    class="!pl-[60px] !py-2"></tds-header>

  <div class="w-full h-full pt-24">
    <div
      class="flex m-auto h-[600px] w-[520px] bg- rounded-xl border border-solid tds-color-border-secondary shadow-xl">
      <tds-spin [spinning]="isLoading" class="w-full h-full">
        <form class="h-full w-full" [formGroup]="signInForm" (ngSubmit)="onSignIn()">
          <div class="px-[70px] py-16 w-full h-full flex flex-col gap-16">
            <section class="flex flex-col justify-center items-center gap-4">
              <h3 class="text-heading-3 font-semibold font-tds-font-family-heading">Đăng nhập</h3>
              <div class="text-body-1 font-tds-font-family-body items-center">
                <span class="tds-color-text-tertiary mr-1">Chào mừng bạn đến với</span>
                <span class="tds-color-text-brand-primary font-semibold">WiOn E-commerce</span>
              </div>
            </section>

            <section class="flex flex-col gap-6">
              <div>
                <tds-label tdsFor="phoneNumber" [tdsRequired]="true">Số điện thoại</tds-label>
                <tds-form-field class="w-full mt-2">
                  <span tdsPrefix class="tds-body-2 font-semibold">+84</span>
                  <input tdsInput
                    size="xl"
                    id="phoneNumber"
                    autocomplete="off"
                    placeholder="Nhập số điện thoại"
                    formControlName="phoneNumber"
                    maxlength="10"
                    [required]='true'
                    numbersOnly
                    (blur)="onPhoneBlur()"
                    (focus)="onPhoneFocus()"
                  />
                  @if(phoneNumber.errors?.['required'] && phoneIsTouched) {
                    <tds-error>Vui lòng nhập số điện thoại</tds-error>
                  }

                  @if(phoneNumber.errors?.['pattern'] && phoneIsTouched) {
                    <tds-error>Số điện thoại không hợp lệ</tds-error>
                  }
                </tds-form-field>

                <div class="flex flex-row items-center justify-between pt-4">
                  <tds-label tdsFor="password" [tdsRequired]="true">Mật khẩu</tds-label>
                  <div class="tds-body-2 tds-color-text-secondary cursor-pointer"
                    (click)="confirmForgotPassword()"
                  >
                    Quên mật khẩu?
                  </div>
                </div>
                <tds-form-field class="w-full mt-2">
                  <input tdsInput
                    size="xl"
                    id="password"
                    placeholder="Nhập mật khẩu"
                    [type]="type"
                    [autocomplete]="'off'"
                    formControlName="password"
                    [required]='true'
                  />
                  <span onkeyup tdsSuffix (click)="togglePassword()">
                    <i [ngClass]="!toggle ? 'tdsi-eye-off-fill' : 'tdsi-eye-fill'" class="cursor-pointer"></i>
                  </span>
                  <tds-error>Vui lòng nhập mật khẩu</tds-error>
                </tds-form-field>
              </div>
              <button tds-button color="primary" size="xl">Đăng nhập</button>
              <div class="flex justify-center tds-body-2 gap-2">
                <span class="tds-color-text-tertiary mr-1">Bạn chưa có tài khoản?</span>
                <a tds-link class="font-semibold cursor-pointer" routerLink="/sign-up">
                  Đăng ký
                </a>
              </div>
              @if(version) {
                <div class="flex justify-center tds-body-2 tds-color-text-inactive">
                  Version_{{version}}
                </div>
              }
            </section>
          </div>
        </form>
      </tds-spin>
    </div>
  </div>
</div>

<ng-template #iconTmp>
  <i class='tdsi-lock-fill tds-color-text-error'></i>
</ng-template>

<ng-template #signInLocked>
  <div class="flex flex-col justify-center tds-body-2">
    <div>Đăng nhập bị tạm khóa trong 30 phút.</div>
    <div>
      <span class="mr-0.5">Vui lòng thử lại sau</span>
      <span class="font-semibold tds-color-text-info">{{expiryTime}}</span>
    </div>
  </div>
</ng-template>