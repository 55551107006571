import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ErrorResponse } from 'app/core/models/error-response.model';
import { BranchService } from 'app/features/branches/services/branch.service';
import { takeUntil } from 'rxjs';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalModule, TDSModalRef, TDSModalService } from 'tds-ui/modal';
import { TDSToolTipModule } from 'tds-ui/tooltip';
import { EEmployeeStatus } from '../../enums/employee-status.enum';
import { IDeleteEmployeeInBranch } from '../../models/delete-employee.model';
import { IEmployee, IUserInStoreBranch } from '../../models/employee.model';
import { IInviteEmployeeReq, InviteInfo, IStoreAndRole } from '../../models/invite-employee.model';
import { ILockUnlockEmployee } from '../../models/lock-unlock-employee.model';
import { EmployeeActionService } from '../../services/employee-action.service';
import { EmployeeService } from '../../services/employee.service';
import { EditEmployeeComponent } from '../edit-employee/edit-employee.component';
import { EmployeeInAllBranchesComponent } from '../employee-in-all-branches/employee-in-all-branches.component';
import { InvitedSuccessfullyComponent } from '../invited-successfully/invited-successfully.component';
import { EditEmployeeInBranchComponent } from '../edit-employee-in-branch/edit-employee-in-branch.component';
import { BaseEmployeeClass } from '../../classes/base-employee.class';
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'app-employee-actions',
  standalone: true,
  imports: [
    CommonModule,
    TDSButtonModule,
    TDSToolTipModule,
    TDSModalModule,
  ],
  providers: [
    BranchService,
    TDSDestroyService,
    TDSMessageService,
    EmployeeService,
  ],
  templateUrl: './employee-actions.component.html',
  styleUrl: './employee-actions.component.scss'
})
export class EmployeeActionsComponent extends BaseEmployeeClass implements OnInit {
  @Input() status!: EEmployeeStatus;
  @Input() employee!: IEmployee;
  @Input() isDetail = false;
  @Input() employeeBranch!: IUserInStoreBranch;
  @Input() isModalAction = false;
  @Input() isEditOnly = false;
  @Output() onCompletedModalAction = new EventEmitter<void>;

  @ViewChild('sendInvitationSvg') tplIconSvg!: TemplateRef<void>;

  employeeStatus = EEmployeeStatus;

  constructor(
    private readonly modalService: TDSModalService,
    private readonly branchService: BranchService,
    private readonly tdsDestroyService: TDSDestroyService,
    private readonly msgService: TDSMessageService,
    private readonly employeeActionService: EmployeeActionService,//don't provide this service to providers
    private readonly employeeService: EmployeeService,
    authService: AuthService
  ) {
    super(authService)
  }
  ngOnInit(): void {
  }

  checkLockStatus(): boolean {
    return this.isDetail ? this.employeeBranch.userStatus === EEmployeeStatus.Inactive
      : this.employee.userStatus === EEmployeeStatus.Inactive;
  }

  private openSuccessModal(inviteInfo?: InviteInfo, employee?: IEmployee): void {
    const modal = this.modalService.create<InvitedSuccessfullyComponent, {employee?: IEmployee, inviteInfo?: InviteInfo}>({
      content: InvitedSuccessfullyComponent,
      closable: false,
      tdsData: {
        employee: employee,
        inviteInfo: inviteInfo
      },
      bodyStyle: {
        "padding": "0px"
      },
      footer: null
    });

    modal.afterClose.subscribe(() => {
      this.employeeActionService.onActionCompleted();
    })
  }

  private executeSendInvitation(employee: IEmployee, payload: IInviteEmployeeReq, modalRef: TDSModalRef<any>): void {
    this.employeeService.inviteEmployee$(payload).pipe(
      takeUntil(this.tdsDestroyService)
    )
    .subscribe({
      next: () => {
        modalRef.destroy(true);
        this.openSuccessModal(undefined, employee);
      },
      error: (error: ErrorResponse) => {
        if (error && error.error.code === 'Validation') {
          this.msgService.error(error.error.message);
        } else {
          this.msgService.error('Đã xảy ra lỗi, vui lòng thử lại sau');
        }
      }
    })
  }

  private sendInvitationToBranch(employee:IEmployee, currentBranch: IUserInStoreBranch, modalRef: TDSModalRef<any>): void {
    const payload: IInviteEmployeeReq = {
      name: employee.name,
      phoneNumber: employee.phoneNumber,
      storeBranches: [{
        roleId: currentBranch.roleId,
        storeBranchId: currentBranch.id
      }]
    };

    this.executeSendInvitation(employee, payload, modalRef);
  }

  private onSendInvitationInToBranch(employee: IEmployee, currentBranch: IUserInStoreBranch): void {
    const modal = this.modalService.info({
      title: 'Xác nhận gửi lời mời',
      confirmIcon: this.tplIconSvg,
      content: `
        <div class="w-full tds-body-2">
          Bạn có muốn mời lại nhân viên vào cửa hàng <span class="font-semibold">${currentBranch.name}</span> ?
        </div>
      `,
      okText: "Xác nhận",
      onOk: () => {
        this.sendInvitationToBranch(employee, currentBranch, modal);
      },
      cancelText: "Đóng",
      onCancel: () => {}
    });

    modal.afterClose.subscribe((res) => {
      if (res) {
        this.emitCompletedModalAction();
      }
    })
  }

  private sendInvitationToAllBranches(employee: IEmployee, modalRef: TDSModalRef<any>): void {
    const payload: IInviteEmployeeReq = {
      name: employee.name,
      phoneNumber: employee.phoneNumber,
      storeBranches: employee.userInStoreBranches!.map(branch => {
        const item: IStoreAndRole = {
          roleId: branch.roleId,
          storeBranchId: branch.id
        };

        return item;
      })
    };

    this.executeSendInvitation(employee, payload,modalRef);
  }

  private onSendInvitationInToAllBranches(employee: IEmployee): void {
    const modal = this.modalService.create<EmployeeInAllBranchesComponent>({
      title: 'Gửi lời mời đồng loạt',
      content: EmployeeInAllBranchesComponent,
      tdsData: {
        employee: employee,
        type: 'invite'
      },
      okText: 'Xác nhận',
      cancelText: 'Đóng',
      onOk: () => {
        this.sendInvitationToAllBranches(employee, modal);
        return false;
      },
      onCancel: () => {}
    });
  }

  sendInvitation(employee: IEmployee): void {
    if (this.isOwner()) {
      if (!this.isModalAction) {
        if (this.isDetail && this.employeeBranch) {
          this.onSendInvitationInToBranch(employee, this.employeeBranch);
        } else {
          this.onSendInvitationInToAllBranches(employee);
        }
      } else if (this.isActionBranch(employee)) {
        this.onSendInvitationInToBranch(employee, this.employee.userInStoreBranches![0]);
      } else {
        this.onSendInvitationInToAllBranches(employee);
      }
    } else {
      this.onSendInvitationInToBranch(employee, this.employee.userInStoreBranches![0]);
    }
  }

  editEmployee(employee: IEmployee): void {
    const modal = this.modalService.create({
      title: 'Chỉnh sửa nhân viên',
      size: 'lg',
      content: EditEmployeeInBranchComponent,
      // content: EditEmployeeComponent,
      componentParams: {
        employee: employee
      },
      footer: null,
    });

    modal.afterClose.subscribe((res: any) => {
      if (res) {
        this.emitCompletedModalAction();
      }
    })
  }

  /**
   * Prepare payload to lock or unlock employee
   * @param isLock -true if you want to lock employee
   * @returns 
   */
  private preparePayload(isLock: boolean): ILockUnlockEmployee {
    let payload: ILockUnlockEmployee = {} as ILockUnlockEmployee;
    if (this.isDetail && this.employeeBranch !== undefined) {
      payload.storeBranchIds = [this.employeeBranch.id],
      payload.status = isLock ? EEmployeeStatus.Inactive : EEmployeeStatus.Active
    } else {
      payload.storeBranchIds = this.employee.userInStoreBranches?.map(item => item.id) as string[];
      payload.status = isLock ? EEmployeeStatus.Inactive : EEmployeeStatus.Active;
    };

    return payload;
  }

  private executeLockOrUnlockEmployee(employeeId: string, payload: ILockUnlockEmployee, isLock: boolean, modal: TDSModalRef<any>): void {
    this.branchService.lockOrUnlockEmployeeInBranches$(employeeId, payload)
      .pipe(
        takeUntil(this.tdsDestroyService)
      ).subscribe({
        next: () => {
          this.msgService.success(isLock ? `Khóa tài khoản thành công` : `Mở khóa tài khoản thành công`);
          modal.destroy(true);
          this.emitCompletedModalAction();
          this.employeeActionService.onActionCompleted();
        },
        error: () => {
          this.msgService.error(`Đã xảy ra lỗi, vui lòng thử lại sau`);
        },
      })
  }

  private lockOrUnlockEmployeeInBranch(employeeBranch: IUserInStoreBranch, isLock: boolean, modal: TDSModalRef<any>): void {
    const payload = this.preparePayload(isLock);

    this.executeLockOrUnlockEmployee(employeeBranch.employeeId, payload, isLock, modal);
  }

  private onLockEmployeeInBranch(employeeBranch: IUserInStoreBranch): void {
    const modal = this.modalService.error({
      title: 'Khóa tài khoản',
      confirmIcon: 'tdsi-lock-fill',
      content: `Trong thời gian khóa tài khoản, nhân viên “<span class="font-semibold">${employeeBranch.employeeName}</span>” 
        sẽ không thể truy cập vào cửa hàng. Dữ liệu và thông tin giao dịch không bị mất.`,
      onOk: () => {
        let isLock = true;
        this.lockOrUnlockEmployeeInBranch(employeeBranch, isLock, modal);
        return false;
      },
      onCancel:()=> {},
      okText:"Xác nhận",
      cancelText:"Đóng",
    });
  }

  private onLockEmployeeInAllBranches(employee: IEmployee): void {
    const modal = this.modalService.create<EmployeeInAllBranchesComponent>({
      title: 'Khóa đồng loạt cửa hàng',
      content: EmployeeInAllBranchesComponent,
      tdsData: {
        employee: employee,
        type: 'lock'
      },
      okText: 'Xác nhận',
      cancelText: 'Đóng',
      onOk: () => {
        const isLock = true;
        const payload = this.preparePayload(isLock);
        this.executeLockOrUnlockEmployee(employee.id, payload, isLock, modal);
        return false;
      },
      onCancel: () => {}
    });
  }

  onLockEmployee(): void {
    if (this.isOwner()) {
      if (!this.isModalAction) {
        if (this.isDetail && this.employeeBranch) {
          this.onLockEmployeeInBranch(this.employeeBranch);
        } else {
          this.onLockEmployeeInAllBranches(this.employee);
        }
      } else if (this.isActionBranch(this.employee)) {
        this.onLockEmployeeInBranch(this.employee.userInStoreBranches![0]);
      } else {
        this.onLockEmployeeInAllBranches(this.employee);
      }
    } else {
      this.onLockEmployeeInBranch(this.employee.userInStoreBranches![0]);
    }
  }

  private onUnLockEmployeeInBranch(employeeBranch: IUserInStoreBranch): void {
    const modal = this.modalService.info({
      title: 'Mở khóa tài khoản',
      confirmIcon: 'tdsi-unlock-fill',
      content: `Mở khoản tài khoản cho nhân viên “<span class="font-semibold">${employeeBranch.employeeName}</span>” ?`,
      onOk: () => {
        let isLock = false;
        this.lockOrUnlockEmployeeInBranch(employeeBranch, isLock, modal);
        return false;
      },
      onCancel:()=>{},
      okText:"Xác nhận",
      cancelText:"Đóng",
    });
  }

  private onUnLockEmployeeInAllBranches(employee: IEmployee): void {
    const modal = this.modalService.create<EmployeeInAllBranchesComponent>({
      title: 'Mở khóa đồng loạt cửa hàng',
      content: EmployeeInAllBranchesComponent,
      tdsData: {
        employee: employee,
        type: 'unlock'
      },
      okText: 'Xác nhận',
      cancelText: 'Đóng',
      onOk: () => {
        const isLock = false;
        const payload = this.preparePayload(isLock);
        this.executeLockOrUnlockEmployee(employee.id, payload, isLock, modal);
        return false;
      },
      onCancel: () => {}
    });
  }

  onUnlockEmployee(): void {
    if (this.isOwner()) {
      if (!this.isModalAction) {
        if (this.isDetail && this.employeeBranch) {
          this.onUnLockEmployeeInBranch(this.employeeBranch);
        } else {
          this.onUnLockEmployeeInAllBranches(this.employee);
        }
      } else if (this.isActionBranch(this.employee)) {
        this.onUnLockEmployeeInBranch(this.employee.userInStoreBranches![0]);
      } else {
        this.onUnLockEmployeeInAllBranches(this.employee);
      }
    } else {
      this.onUnLockEmployeeInBranch(this.employee.userInStoreBranches![0]);
    }
  }
  /**
   * Lock or unlock employee
   * @param isLocked 
   */
  lockOrUnlockEmployee(): void {
    const isLocked = this.checkLockStatus();
    if (isLocked) {
      this.onUnlockEmployee();
    } else {
      this.onLockEmployee();
    }
  }

  private executeDeleteEmployee(employeeId: string, payload:IDeleteEmployeeInBranch, modal: TDSModalRef<any>): void {
    this.branchService.deleteEmployeeInBranches$(employeeId, payload)
      .pipe(
        takeUntil(this.tdsDestroyService)
      )
      .subscribe({
        next: () => {
          this.msgService.success(`Xóa nhân viên thành công`);
          modal.destroy(true);
          this.emitCompletedModalAction();
          this.employeeActionService.onActionCompleted();
        },
        error: () => {
          this.msgService.error('Đã xảy ra lỗi, vui lòng thử lại sau');
        }
      });
  }

  private deleteEmployeeInBranch(branch: IUserInStoreBranch, modal: TDSModalRef<any>): void {
    const payload: IDeleteEmployeeInBranch = {
      storeBranchIds: [branch.id]
    };

    this.executeDeleteEmployee(branch.employeeId, payload, modal);
  }

  private onDeleteEmployeeInBranch(employeeBranch: IUserInStoreBranch): void {
    const modal = this.modalService.error({
      title: 'Xóa nhân viên',
      confirmIcon: 'tdsi-trash-fill',
      content: `Sau khi xóa, dữ liệu và thông tin giao dịch của nhân viên “<span class="font-semibold">${employeeBranch.employeeName}</span>” trên cửa hàng vẫn còn được lưu trữ.?`,
      onOk: () => {
        this.deleteEmployeeInBranch(employeeBranch, modal);
        return false;
      },
      onCancel:()=>{},
      okText:"Xác nhận",
      cancelText:"Đóng",
    });
  }

  private onDeleteEmployeeInAllBranches(employee: IEmployee): void {
    const modal = this.modalService.create<EmployeeInAllBranchesComponent>({
      title: 'Xóa đồng loạt cửa hàng',
      content: EmployeeInAllBranchesComponent,
      tdsData: {
        employee: employee,
        type: 'delete'
      },
      okText: 'Xác nhận',
      cancelText: 'Đóng',
      onOk: () => {
        const payload: IDeleteEmployeeInBranch = {
          storeBranchIds: employee.userInStoreBranches!.map(branch => branch.id)
        };

        this.executeDeleteEmployee(employee.id, payload, modal);
        return false;
      },
      onCancel: () => {}
    });
  }

  deleteEmployee(): void {
    if (this.isOwner()) {
      if (!this.isModalAction) {
        if (this.isDetail && this.employeeBranch) {
          this.onDeleteEmployeeInBranch(this.employeeBranch);
        } else {
          this.onDeleteEmployeeInAllBranches(this.employee);
        }
      } else if (this.isActionBranch(this.employee)){
        this.onDeleteEmployeeInBranch(this.employee.userInStoreBranches![0]);
      } else {
        this.onDeleteEmployeeInAllBranches(this.employee);
      }
    } else {
      this.onDeleteEmployeeInBranch(this.employee.userInStoreBranches![0]);
    }
  }

  private isActionBranch(employee: IEmployee): boolean {
    return !(employee.userInStoreBranches && employee.userInStoreBranches.length !== 1);
  }

  private emitCompletedModalAction() {
    if (this.isModalAction) {
      this.onCompletedModalAction.emit();
    }
  }
}
