import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DistrictResponse, ProvinceResponse, WardResponse } from 'app/core/models/address.model';
import { AddressService } from 'app/core/services/address.service';
import { AuthService } from 'app/core/services/auth.service';
import { emailValidator } from 'app/core/utilities/email-validator.util';
import { WiECommerceDirtyErrorStateMatcher } from 'app/core/utilities/error-state-matcher.util';
import { StoreBranch } from 'app/features/branches/models/store-branch.model';
import { BranchService } from 'app/features/branches/services/branch.service';
import { IRole } from 'app/features/roles/models/role.model';
import { RoleService } from 'app/features/roles/services/role.service';
import { IUpdateUserInfoReq } from 'app/features/users/models/update-user-info-req.model';
import { differenceInCalendarDays } from 'date-fns';
import { catchError, EMPTY, expand, finalize, map, mergeMap, takeUntil, tap } from 'rxjs';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSDatePickerModule } from 'tds-ui/date-picker';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalModule, TDSModalRef } from 'tds-ui/modal';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';
import { TDSSelectModule } from 'tds-ui/select';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { TDSTagModule } from 'tds-ui/tag';
import { TDSInputModule } from 'tds-ui/tds-input';
import { IEmployee } from '../../models/employee.model';
import { EmployeeActionService } from '../../services/employee-action.service';
import { EmployeeService } from '../../services/employee.service';
import { IUpdateUserRoleInBranches } from 'app/features/roles/models/update-user-role-in-branches.model';

@Component({
  selector: 'app-edit-employee-in-branch',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TDSFormFieldModule,
    TDSButtonModule,
    TDSInputModule,
    TDSSelectModule,
    TDSModalModule,
    TDSDatePickerModule,
    TDSTagModule,
    TDSSpinnerModule
  ],
  providers: [
    {
      provide: ErrorStateMatcher, useClass: WiECommerceDirtyErrorStateMatcher
    },
    BranchService,
    TDSDestroyService,
    TDSMessageService,
    EmployeeService,
    AddressService
  ],
  templateUrl: './edit-employee-in-branch.component.html',
  styleUrl: './edit-employee-in-branch.component.scss'
})
export class EditEmployeeInBranchComponent implements OnInit {
  @Input() employee!: IEmployee;
  editEmployeeForm!: FormGroup;

  provinces: ProvinceResponse[] = [];
  districts: DistrictResponse[] = [];
  wards: WardResponse[] = [];
  curProvinceCode!: string;
  curDistCode!: string;

  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) > 0;
  userBranches!: StoreBranch[];
  roles!: IRole[];
  isLoading = false;
  currentPage = 0;
  readonly pageSize = 20;
  total = 0;
  allRolesLoaded = false;
  isLoadingMore = false;

  constructor(
    private readonly modalRef: TDSModalRef<EditEmployeeInBranchComponent>,
    private readonly tdsDestroyService: TDSDestroyService,
    private readonly msgService: TDSMessageService,
    private readonly roleService: RoleService,
    private readonly employeeService: EmployeeService,
    private readonly authService: AuthService,
    private readonly addressService: AddressService,
    private readonly employeeActionService: EmployeeActionService,//don't provide this service to providers
  ) {}

  ngOnInit(): void {
    this.loadProvinces();
    this.createEditForm();
    this.getRolesOfUser();
  }

  getRolesOfUser(): void {
    this.isLoading = true;
    this.roles = [];

    this.roleService.getRoles$(this.currentPage * this.pageSize, this.pageSize).pipe(
      expand(res => {
        const { roleId } = this.employee.userInStoreBranches![0];
        const foundRole = res.roleList.filter(r => !r.isDefault)?.find(r => r.id === roleId);

        if (!foundRole) {
          this.currentPage++;
          return this.roleService.getRoles$(this.currentPage * this.pageSize, this.pageSize);
        } else {
          return EMPTY;
        }
      }),
      tap(res => {
        this.total = res.totalCount;
        this.roles = [...this.roles, ...res.roleList.filter(r => !r.isDefault)];
        this.allRolesLoaded = this.roles.length === (this.total - 1);
      }),
      finalize(() => {
        this.isLoading = false;
        this.updateRole(); // Update role after found current role
      }),
      catchError(() => {
        this.msgService.error('Đã xảy ra lỗi, vui lòng thử lại sau');
        return EMPTY;
      }),
      takeUntil(this.tdsDestroyService)
    ).subscribe();
  }
  
  createEditForm(): void {
    this.editEmployeeForm = new FormGroup({
      name: new FormControl(this.employee.name, Validators.required),
      phoneNumber: new FormControl(this.employee.phoneNumber),
      email: new FormControl(this.employee.email, emailValidator),
      birthday: new FormControl(this.employee.birthday),
      street: new FormControl(this.employee.street),
      ward: new FormControl(this.employee.ward),
      district: new FormControl(this.employee.district),
      city: new FormControl(this.employee.city),
      roleId: new FormControl(null, Validators.required),
      roleName: new FormControl(null, Validators.required)
    });
  }

  get roleId(): FormControl {
    return this.editEmployeeForm.get('roleId') as FormControl;
  }

  get roleName(): FormControl {
    return this.editEmployeeForm.get('roleName') as FormControl;
  }

  updateRole(): void {
    const { roleId, roleName } = this.employee.userInStoreBranches![0];
    this.roleId.setValue(roleId);
    this.roleName.setValue(roleName);
  }


  private handleDeleteProvince(): void {
    this.editEmployeeForm.get('district')?.reset();
    this.districts = [];

    this.onChangeDistrict(null);
  }

  onChangeProvince(event: ProvinceResponse | null): void {
    if (!event) {
      this.handleDeleteProvince();
    } else {
      this.editEmployeeForm.get('district')?.reset();
      this.editEmployeeForm.get('ward')?.reset();

      this.editEmployeeForm.get('city')?.setValue(event.Name);
      this.loadDistricts(event.Code);
    }
  }

  private handleDeleteDistrict(): void {
    this.editEmployeeForm.get('ward')?.reset();
    this.wards = [];
  }

  onChangeDistrict(event: DistrictResponse | null): void {
    if (!event) {
      this.handleDeleteDistrict();
    } else {
      this.editEmployeeForm.get('ward')?.reset();

      this.editEmployeeForm.get('district')?.setValue(event.Name);
      this.loadWards(event.Code);
    }
  }

  onChangeWard(event: WardResponse): void {
    if (!event) return;

    this.editEmployeeForm.get('ward')?.setValue(event.Name);
  }

  onClose(): void {
    this.modalRef.close()
  }

  private markFormAsDirty(): void {
    Object.keys(this.editEmployeeForm.controls).forEach(key => {
      this.editEmployeeForm.get(key)?.markAsDirty();
    })
  }

  private prepareUpdateInfo(): IUpdateUserInfoReq {
    const formValue = this.editEmployeeForm.value;

    const model: IUpdateUserInfoReq = {
      Name: formValue.name,
      Avatar: null,
      Email: formValue.email,
      Birthday: formValue.birthday,
      City: formValue.city,
      District: formValue.district,
      Ward: formValue.ward,
      Street: formValue.street
    };

    return model;
  }

  private prepareUpdateUserRoleInBranches(): IUpdateUserRoleInBranches {
    const model: IUpdateUserRoleInBranches = {
      storeId: this.authService.getCurrentStoreId() as string,
      userId: this.employee.id,
      requests: [{
        storeBranchId: this.authService.getCurrentBranchId() as string,
        roleId: this.editEmployeeForm.value.roleId
      }]
    };

    return model;
  }

  onUpdate(): void {
    this.markFormAsDirty();

    if (!this.editEmployeeForm.valid) return;

    this.isLoading = true;

    const updateInfoPayload: IUpdateUserInfoReq = this.prepareUpdateInfo();
    const updateUserRoleInBranches: IUpdateUserRoleInBranches = this.prepareUpdateUserRoleInBranches();

    this.employeeService.updateEmployeeInfo$(this.employee.id, updateInfoPayload)
      .pipe(
        mergeMap(() => this.roleService.updateUserRoleInBranches$(updateUserRoleInBranches)),
        takeUntil(this.tdsDestroyService),
        finalize(() => this.isLoading = false)
      ).subscribe({
        next: () => {
          this.msgService.success('Cập nhật thông tin thành công');
          this.modalRef.destroy(true);
          this.employeeActionService.onActionCompleted();
        },
        error: (error) => {
          this.msgService.error('Cập nhật thông tin thất bại');
        }
      });
  }

  onChangeRole(event: IRole): void {
    if (!event) {
      this.roleId.setValue(null);
      this.roleName.setValue(null);
    } else {
      this.roleId.setValue(event.id);
      this.roleName.setValue(event.name);
    }
  }

  loadProvinces(): void {
    this.addressService.getProvinces$()
      .pipe(
        tap(res => {
          this.curProvinceCode = res.Data.find(city => city.Name === this.employee.city)?.Code as string;
          if (this.curProvinceCode) {
            this.loadDistricts(this.curProvinceCode);
          }
        }),
        takeUntil(this.tdsDestroyService)
      )
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.provinces = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách tỉnh/thành phố thất bại');
        }
      })
  }

  loadDistricts(provinceCode: string): void {
    this.addressService.getDistricts$(provinceCode)
      .pipe(
        tap(res => {
          this.curDistCode = res.Data.find(district => district.Name === this.employee.district)?.Code as string;
          if (this.curDistCode) {
            this.loadWards(this.curDistCode);
          }        
        }),
        takeUntil(this.tdsDestroyService)
      )
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.districts = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách quận/huyện thất bại');
        }
      })
  }

  loadWards(districtCode: string): void {
    this.addressService.getWards$(districtCode)
      .pipe(takeUntil(this.tdsDestroyService))
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.wards = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách phường/xã thất bại');
        }
      })
  }

  loadMoreRoles(): void {
    if (this.allRolesLoaded) return;
    
    this.currentPage++;
    this.isLoadingMore = true;
    this.roleService.getRoles$(this.currentPage * this.pageSize, this.pageSize).pipe(
      tap(res => {
        this.total = res.totalCount;
        this.roles = [...this.roles, ...res.roleList.filter(r => !r.isDefault)];
        this.allRolesLoaded = this.roles.length === (this.total - 1);
      }),
      finalize(() => {
        this.isLoadingMore = false;
      }),
      catchError(() => {
        this.msgService.error('Đã xảy ra lỗi, vui lòng thử lại sau');
        return EMPTY;
      }),
      takeUntil(this.tdsDestroyService)
    ).subscribe();
  }
}
