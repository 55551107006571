import { Component, Input, OnInit } from '@angular/core';
import { IEmployee, IUserInStoreBranch } from '../../models/employee.model';
import { CommonModule } from '@angular/common';
import { TDSTableModule } from 'tds-ui/table';
import { TDSTagModule } from 'tds-ui/tag';
import { EmployeeActionsComponent } from '../employee-actions/employee-actions.component';
import { EmployeeStatusComponent } from '../employee-status/employee-status.component';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { AuthService } from 'app/core/services/auth.service';
import { BaseEmployeeClass } from '../../classes/base-employee.class';

@Component({
  selector: 'app-employee-branches-detail',
  standalone: true,
  imports: [
    CommonModule,
    TDSTableModule,
    TDSTagModule,
    EmployeeStatusComponent,
    EmployeeActionsComponent,
    TDSAvatarModule
  ],
  templateUrl: './employee-branches-detail.component.html',
  styleUrl: './employee-branches-detail.component.scss'
})
export class EmployeeBranchesDetailComponent extends BaseEmployeeClass implements OnInit {
  @Input() employee!: IEmployee;
  @Input() employeeBranches: IUserInStoreBranch[] = [];

  constructor(
    authService: AuthService
  ) {
    super(authService)
  }

  ngOnInit(): void {
  }
}
