<ng-container>
  <tds-spin [spinning]="isLoading">
    <form [formGroup]="inviteForm"
      class="h-full w-full flex flex-col gap-3"
    >
      <div class="w-full">
        <tds-label [tdsRequired]="true">Tên nhân viên</tds-label>
        <tds-form-field class="w-2/full mt-1">
          <input 
            tdsInput
            autocomplete="off"
            placeholder="Tối đa 100 ký tự"
            [maxlength]="100"
            formControlName="name"
          />
          <tds-error>Vui lòng nhập tên nhân viên</tds-error>
        </tds-form-field>
      </div>

      <div class="w-full">
        <tds-label [tdsRequired]="true">Số điện thoại</tds-label>
        <tds-form-field class="w-full mt-1">
          <input tdsInput
            id="phoneNumber"
            autocomplete="off"
            placeholder="Nhập số điện thoại"
            formControlName="phoneNumber"
            maxlength="10"
            [required]='true'
            numbersOnly
            (blur)="onPhoneBlur()"
            (focus)="onPhoneFocus()"
          />
          @if(phoneNumber.errors?.['required'] && (phoneIsTouched || phoneNumber.dirty || phoneNumber.touched)) {
            <tds-error>Vui lòng nhập số điện thoại</tds-error>
          }

          @if(phoneNumber.errors?.['existPhoneNumber']) {
            <tds-error>Số điện thoại đã tồn tại</tds-error>
          }

          @if(phoneNumber.errors?.['pattern'] && phoneIsTouched) {
            <tds-error>Số điện thoại không hợp lệ</tds-error>
          }
        </tds-form-field>
      </div>

      <div class="w-full">
        <tds-label [tdsRequired]="true">Vai trò</tds-label>
        <tds-form-field class="w-full mt-1">
          <tds-select
            placeholder="Chọn vai trò"
            [valuePrimitive]="false"
            [allowClear]="true"
            [data]="userRoles"
            formControlName="roleId"
            valueField="id"
            textField="name"
            [loading]="isLoadingMore"
            (selectChange)="onChangeRole($event)"
            (scrollToBottom)="loadMoreRoles()"
          ></tds-select>
          <tds-error>Vui lòng chọn vai trò</tds-error>
        </tds-form-field>
      </div>


      <div *tdsModalFooter
        class="flex gap-2 p-4 justify-end">
        <button tds-button-outline
          color="secondary"
          class="min-w-[100px]"
          type="button"
          (tdsBtnClick)="onClose()"
        >
          Đóng
        </button>

        <button tds-button
          class="min-w-[100px]"
          color="primary"
          type="submit"
          (tdsBtnClick)="onSendInvite()"
        >
          Gửi lời mời
        </button>
      </div>
    </form>
  </tds-spin>
</ng-container>
