import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSModalModule, TDSModalRef } from 'tds-ui/modal';

@Component({
  selector: 'app-confirm-forgot-password',
  standalone: true,
  imports: [
    TDSButtonModule,
    TDSModalModule
  ],
  templateUrl: './confirm-forgot-password.component.html',
  styleUrl: './confirm-forgot-password.component.scss'
})
export class ConfirmForgotPasswordComponent {
  constructor(
    private readonly router: Router,
    private readonly modalRef: TDSModalRef
  ) {

  }

  onClose(): void {
    this.modalRef.destroy();
  }

  onConfirm(): void {
    this.modalRef.destroy();
    this.router.navigate(['/forgot-password']);
  }
}
