import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSPopoverModule } from 'tds-ui/popover';
import { TDSCheckBoxModule } from 'tds-ui/tds-checkbox';

@Component({
  selector: 'app-filter-column-config',
  standalone: true,
  imports: [
    CommonModule,
    TDSButtonModule,
    TDSPopoverModule,
    TDSCheckBoxModule
  ],
  templateUrl: './filter-column-config.component.html',
  styleUrl: './filter-column-config.component.scss'
})
export class FilterColumnConfigComponent {
  isVisible = false;

  colsConfig = [
    {id: 0, name: 'Mô tả', checked: true},
    {id: 0, name: 'Nhân viên', checked: true},
    {id: 0, name: 'Loại', checked: true},
    {id: 0, name: 'Tạo bởi', checked: true},
    {id: 0, name: 'Ngày tạo', checked: true},
    {id: 0, name: 'Cập nhật bởi', checked: true},
    {id: 0, name: 'Ngày cập nhật', checked: true},
  ]

  constructor() {

  }

  onChangeCol(col: any): void {
    
  }
}
