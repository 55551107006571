import { CommonModule } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OtpType, SendOtpErrorResponse, SendOtpReq, SendOtpResponse } from 'app/core/models/otp.model';
import { VerifiedOtp, VerifyOtp } from 'app/core/models/sign-up.model';
import { WiAccountService } from 'app/core/services/wi-account.service';
import { WiECommerceDirtyErrorStateMatcher } from 'app/core/utilities/error-state-matcher.util';
import { phoneValidator } from 'app/core/utilities/phone-validator.util';
import { NumbersOnlyDirective } from 'app/shared/directives/numbers-only.directive';
import { finalize, interval, Subscription, takeUntil, takeWhile } from 'rxjs';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSDestroyService } from 'tds-ui/core/services';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSHeaderModule } from 'tds-ui/header';
import { TDSMessageService } from 'tds-ui/message';
import { TDSModalModule, TDSModalService } from 'tds-ui/modal';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { TDSInputModule } from 'tds-ui/tds-input';
import { OtpForgotPasswordComponent } from './otp-forgot-password/otp-forgot-password.component';
import { ResetPasswordSuccessComponent } from './reset-password-success/reset-password-success.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { OTP_LIMIT_EXCEEDED, RETRY_REQUEST_DELAY } from 'app/core/constants/wi-account-error-code.const';
import { TDSAlertModule } from 'tds-ui/alert';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TDSHeaderModule,
    TDSFormFieldModule,
    TDSInputModule,
    TDSButtonModule,
    TDSModalModule,
    TDSSpinnerModule,
    NumbersOnlyDirective,
    TDSAlertModule
  ],
  providers: [
    {
      provide: ErrorStateMatcher,
      useClass: WiECommerceDirtyErrorStateMatcher
    },
    TDSModalService,
    WiAccountService,
    TDSDestroyService,
    TDSMessageService,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('formDirective') formDirective!: NgForm;
  isLoading = false;
  isSubmitted = false;

  resetPasswordForm!: FormGroup<any>;
  phoneIsTouched = false;
  isShowWarning = false;
  private resendCountdownSubscription?: Subscription;
  resendTime = 0;

  sixHoursFromLatestTimeSend!: Date;

   @ViewChild('limitOtp') limitOtpTmp: TemplateRef<unknown> | undefined;
  constructor(
    private readonly modalService: TDSModalService,
    private readonly router: Router,
    private readonly wiAccountService: WiAccountService,
    private readonly destroyService: TDSDestroyService,
  ) {

  }

  ngOnInit(): void {
    this.initializeSignUpForm();
  }

  private initializeSignUpForm(): void {
    this.resetPasswordForm = new FormGroup<any>({
      phoneNumber: new FormControl(null, [Validators.required, phoneValidator()]),
    });
  }

  get phoneNumber(): FormControl {
    return this.resetPasswordForm.get("phoneNumber") as FormControl;
  }


  sendOTPViaZalo(phone: string): void {
    this.phoneNumber.markAsDirty();
    this.phoneNumber.markAsTouched();
    this.isSubmitted = true;
    if (this.resetPasswordForm.invalid || this.isShowWarning) return;

    const data: SendOtpReq = {
      phoneNumber: phone,
      method: "ZaLo",
    };

    this.sendOtpWithType(data);
  }

  onPhoneBlur(): void {
    this.phoneIsTouched = true;
  }

  onPhoneFocus(): void {
    this.phoneIsTouched = false;
  }

  sendOTPViaSMS(phone: string): void {
    this.phoneNumber.markAsDirty();
    this.phoneNumber.markAsTouched();
    this.isSubmitted = true;
    if (this.resetPasswordForm.invalid || this.isShowWarning) return;

    const data: SendOtpReq = {
      phoneNumber: phone,
      method: "SMS",
    };

    this.sendOtpWithType(data);
  }

  goToSignInPage() {
    this.router.navigate(['/sign-in']);
  }

  private sendOtpWithType(data: SendOtpReq): void {
    this.isLoading = true;
    this.wiAccountService.sendOtpForgotPassword(data)
      .pipe(
        takeUntil(this.destroyService),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: (res: SendOtpResponse) => this.handleOtpSuccess(res, data.phoneNumber, data.method),
        error: (err: SendOtpErrorResponse) => this.handleOtpError(err, data.phoneNumber)
      });
  }

  private handleOtpError(err: SendOtpErrorResponse, phone: string): void {
    if (err.error.code === OTP_LIMIT_EXCEEDED) {
      this.handleOtpLimitExceeded(err.error.data as { latestTimeSend: Date });
    } else if (err.error.code === RETRY_REQUEST_DELAY) {
      this.handleRetryDelay(err.error.data as { latestTimeSend: Date });
    } else {
      // this._msgService.error(err.error.message);
    }
  }

  private handleOtpSuccess(res: SendOtpResponse, phone: string, type: OtpType): void {
    const data: VerifyOtp = {
      type: type,
      phone: phone,
      expiredTime: new Date(res.codeVerifyExpiryDate)
    };
    this.inputOtp(data);
  }

  private inputOtp(input: VerifyOtp): void {
    const modal = this.modalService.create({
      closable: false,
      content: OtpForgotPasswordComponent,
      bodyStyle: { "padding": "0px" },
      style: { "width": "520px", "height": "688px" },
      componentParams: { inputData: input },
      footer: null
    });

    modal.afterClose.subscribe((res: VerifiedOtp | undefined) => {
      if (res) {
        this.resetPassword(res);
      }
    });
  }


  private resetPassword(verifiedOtp: VerifiedOtp): void {
    const modal = this.modalService.create({
      closable: false,
      content: ResetPasswordComponent,
      size: "xl",
      bodyStyle: { "padding": "0px" },
      style: {
        "width": "520px",
        "height": "708px",
      },
      componentParams: { verifiedOtp: verifiedOtp },
      centered: true,
      footer: null
    });

    modal.afterClose.subscribe((isCompleted: boolean) => {
      if (isCompleted) {
        this.openSuccess();
      }
    });
  }

  /**
   * Opens success modal when signup is successful
   */
  private openSuccess(): void {
    const modal = this.modalService.create({
      closable: false,
      content: ResetPasswordSuccessComponent,
      bodyStyle: { "padding": "0px" },
      style: { "width": "520px", "height": "708px" },
      footer: null
    });

    modal.afterClose.subscribe((res: boolean | undefined) => {
      if (res) {
        this.router.navigate(['/sign-in']);
      }
    });
  }

  private handleOtpLimitExceeded(data: { latestTimeSend: Date }): void {
    const adjustedTimeSend = new Date(data.latestTimeSend);
    adjustedTimeSend.setHours(adjustedTimeSend.getHours() + 6);
    this.sixHoursFromLatestTimeSend = adjustedTimeSend;
    this.showLimitAccessOtpModal();
  }

  private showLimitAccessOtpModal(): void {
    const modal = this.modalService.error({
      title: "Thông báo",
      content: this.limitOtpTmp,
      okText: null,
      cancelText: 'Đóng',
      onCancel: () => {
        modal.destroy();

        // Reset form directive to clear the submit state
        if (this.formDirective) {
          this.formDirective.resetForm();
        }

        // Reset the form completely
        this.resetPasswordForm.reset({
          phoneNumber: null,
        });

        // Reset the states
        this.isSubmitted = false;
        this.phoneIsTouched = false;
        this.isShowWarning = false;
      },
    });
  }

  private handleRetryDelay(data: { latestTimeSend: Date }): void {
    this.isShowWarning = true;

    // Clear any existing subscription
    if (this.resendCountdownSubscription) {
      this.resendCountdownSubscription.unsubscribe();
    }

    // Convert latestTimeSend string to Date object
    const latestTimeSend = new Date(data.latestTimeSend);

    // Calculate countdown
    const calculateRemainingTime = (): number => {
      const now = new Date().getTime();
      const expireTime = latestTimeSend.getTime() + 30000; // 30 seconds in milliseconds
      return Math.max(0, Math.floor((expireTime - now) / 1000));
    };

    // Set initial countdown value
    this.resendTime = calculateRemainingTime();

    // Start countdown
    this.resendCountdownSubscription = interval(1000)
      .pipe(
        takeWhile(() => this.resendTime > 0),
        takeUntil(this.destroyService)
      )
      .subscribe({
        next: () => {
          this.resendTime = calculateRemainingTime();
          if (this.resendTime <= 0) {
            this.isShowWarning = false;
            this.resendCountdownSubscription?.unsubscribe();
          }
        },
        complete: () => {
          this.isShowWarning = false;
        }
      });
  }

}
