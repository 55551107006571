<div class="w-full h-full flex flex-col">
  <div class="flex flex-col tds-page-header-color-background">
    <tds-page-header tdsTitle="Danh sách nhân viên">
      <tds-page-header-extra>
        <button tds-button
          [tdsPrefixIcon]="'tdsi-plus-circle-fill'"
          color="primary"
          (tdsBtnClick)="addNewEmployee()"
        >
          Thêm nhân viên
        </button>
      </tds-page-header-extra>
    </tds-page-header>
  </div>

  <div class="w-full flex-auto">
    <div class="w-full h-full p-2">
      <!-- @if (employees && employees.length > 0) { -->
      <div class="flex flex-col w-full h-full bg-white rounded-md">
        <div class="w-full px-2.5">
          <app-filter-employee
            #filterCpm
            (onChangeFilter)="handleFilter($event)"
          ></app-filter-employee>
        </div>
        <div class="flex-auto w-full tds-body-2">
          <tds-table
            #employeesTable
            [widthConfig]="widthConfig"
            showPagination
            showSizeChanger
            [scroll]="{'x':'auto','y': 'auto'}"
            [listData]="employees"
            [(pageIndex)]="pageIndex"
            [(pageSize)]="pageSize"
            [total]="total"
            [loading]="isLoading"
            [frontPagination]="false"
            [pageSizeOptions]="[10, 20, 50, 100, 200]"
            [noResult]="noResult"
            (clickRefresh)="onRefresh()"
            (queryParams)="onQueryParamsChange($event)"
          >
            <thead>
              <tr>
                @if (isOwner()) {
                  <th scope="col" [tdsLeft]="true"></th>
                }
                <th scope="col">Nhân viên</th>
                <th scope="col">Số điện thoại</th>
                <th scope="col">Email </th>
                <th scope="col">Trạng thái</th>
                @if (!isOwner()) {
                  <th scope="col">Vai trò</th>
                }
                <th scope="col">Tạo bởi</th>
                @if (!isOwner()) {
                  <th scope="col">Cập nhật bởi</th>
                }
                <th scope="col">Ngày tạo</th>
                @if (!isOwner()) {
                  <th scope="col">Ngày cập nhật</th>
                }
                <th scope="col" [tdsRight]="true">Thao tác</th>
              </tr>
            </thead>
            <tbody>
              @for (employee of employees; track employee.id) {
                <tr>
                  @if (isOwner()) {
                    <td
                      [expand]="expandSet.has(employee.id)"
                      (expandChange)="onExpandChange(employee.id)"
                    ></td>
                  }
                  <td>
                    <div class="font-semibold tds-color-text-info cursor-pointer"
                      (click)="onViewDetail(employee)"
                    >
                      {{ employee.name }}
                    </div>
                  </td>
                  <td>{{ employee.phoneNumber }}</td>
                  <td>
                    <div class="truncate"
                      tds-tooltip
                      [tooltipTitle]="employee.email"
                    >
                      {{ employee.email || '---' }}
                    </div>
                  </td>
                  <td>
                    <app-employee-status
                      [status]="employee.userStatus"
                      [statusName]="employee.userStatusName!"
                    ></app-employee-status>
                  </td>
                  @if (!isOwner()) {
                    <td>
                      <tds-tag 
                        type="outline"
                        size="md"
                        status="default"
                        class="tds-color-text-tertiary"
                      >
                        {{ employee.userInStoreBranches?.[0]!.roleName }}
                      </tds-tag>
                    </td>
                  }
                  <td>
                    @if (employee.creator) {
                      <div class="flex items-center gap-2"> 
                        <tds-avatar class="bg-base-orange-500 dark:bg-d-base-orange-500 text-white" size="md"
                          [tdsSrc]="employee.creator.avatar">
                        </tds-avatar>
                        <div class="flex-1">{{employee.creator!.name}}</div> 
                      </div>
                    } @else {
                      <div>---</div>
                    }
                  </td>

                  @if (!isOwner()) {
                    <td>
                      @if (employee.updator) {
                        <div class="flex items-center gap-2"> 
                          <tds-avatar class="bg-base-orange-500 dark:bg-d-base-orange-500 text-white" size="md"
                            [tdsSrc]="employee.updator.avatar">
                          </tds-avatar>
                          <div class="flex-1">{{employee.updator!.name}}</div> 
                        </div>
                      } @else {
                        <div>---</div>
                      }
                    </td>
                  }
                  <td>{{ employee.creationTime | date: 'dd/MM/yyyy HH:mm' }}</td>
                  @if (!isOwner()) {
                    <td>
                      @if (employee.lastModificationTime) {
                        <span>{{ employee.lastModificationTime | date: 'dd/MM/yyyy HH:mm' }}</span>
                      } @else {
                        <span>---</span>
                      }
                    </td>
                  }
                  <td [tdsRight]="true">
                    @if (!shouldHiddenActions(employee)) {
                      <app-employee-actions
                        [status]="employee.userStatus"
                        [employee]="employee"
                        (onActionCompleted)="onActionCompleted()"
                      ></app-employee-actions>
                    }
                  </td>
                </tr>

                @if (isOwner()) {
                  <tr [expand]="expandSet.has(employee.id)" class="w-full">
                    <app-employee-branches-detail
                      [employee]="employee"
                      [employeeBranches]="employee.userInStoreBranches ?? []"
                    ></app-employee-branches-detail>
                  </tr>
                }
              }
            </tbody>
          </tds-table>
        </div>
      </div>
      <!-- }  -->
      <!-- @else if (employees && employees.length === 0) {
        <ng-container *ngTemplateOutlet="emptyEmployee"></ng-container>
      } -->
    </div>
  </div>
</div>

<ng-template #emptyEmployee>
  <div class="flex justify-center items-center w-full h-full bg-white rounded-md">
    <div class="flex flex-col gap-4 w-[400px] h-[430px]">
      <div class="w-full h-full px-[48px]">
        <img src="/assets/images/empty-employee.svg"
          alt="empty-employee" class="w-full h-full">
      </div>
      <div class="flex flex-col gap-2 items-center">
        <div class="tds-heading-5 font-semibold tds-color-text-primary">Chưa có nhân viên nào</div>
        <div class="text-body-2 tds-color-text-secondary">Hãy thêm mới nhân viên vào doanh nghiệp của bạn</div>
      </div>
      <div class="flex justify-center">
        <button tds-button
          [tdsPrefixIcon]="'tdsi-plus-circle-fill'"
          color="primary"
          size="lg"
          (tdsBtnClick)="addNewEmployee()"
        >
          Thêm nhân viên
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noResult>
  <div class="flex justify-center items-center w-full h-full bg-white rounded-md">
    <div class="flex flex-col gap-4 w-[400px] h-[430px]">
      <div class="w-full h-full px-[48px]">
        <img src="/assets/images/employee-no-result.svg"
          alt="no-result" class="w-full h-full">
      </div>
      <div class="flex flex-col gap-2 items-center justify-center">
        <div class="tds-heading-5 tds-color-text-primary font-semibold">Không tìm thấy kết quả</div>
        <div class="text-body-2 tds-color-text-secondary">Hãy thử tìm kiếm với các thông tin khác</div>
      </div>
      <div class="flex justify-center">
        <button tds-button
          [tdsPrefixIcon]="'tdsi-trash-fill'"
          color="primary"
          size="lg"
          (tdsBtnClick)="deleteFilter()"
        >
          Xóa bộ lọc
        </button>
      </div>
    </div>
  </div>
</ng-template>
