import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { TDSBadgeModule } from 'tds-ui/badges';

@Component({
  selector: 'app-online-status',
  standalone: true,
  imports: [
    CommonModule,
    TDSAvatarModule,
    TDSBadgeModule
  ],
  templateUrl: './online-status.component.html',
  styleUrl: './online-status.component.scss'
})
export class OnlineStatusComponent implements OnInit {
  @Input() avatar!: string;
  @Input() isOnline!: boolean;
  
  constructor() {}

  ngOnInit(): void {
  }
}
