import { Component, Input } from '@angular/core';
import { TDSAvatarModule } from "tds-ui/avatar";
import { TDSMapperPipeModule } from 'tds-ui/cdk/pipes/mapper';


@Component({
  selector: 'app-avatar',
  standalone: true,
  imports: [
    TDSAvatarModule,
    TDSMapperPipeModule
  ],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss'
})
export class AvatarComponent {
  @Input() textName : string = '';
  @Input() avatar : string = '';
  @Input() isSystem: boolean = false;

  abbreviateTheCustomerName = (name: string): string => {
    const hasAsterisk = /\*/.test(name); 
    if (hasAsterisk) {
        return `${name.charAt(0).toUpperCase()}${name.charAt(name.length - 1)}`;
    } else {
        const words = name.split(' ');
        if (words.length > 1) {
            return `${words[0].charAt(0).toUpperCase()}${words[1].charAt(0).toUpperCase()}`;
        } else {
            //If only one word, return first and second character of that word
            return `${words[0].charAt(0).toUpperCase()}${words[0].charAt(1)}`;
        }
    }
}
}
