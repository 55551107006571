<ng-container>
  <div class="flex flex-col gap-3">
    @switch (type) {
      @case ('lock') {
        <div class="tds-body-2 tds-color-text-primary">Danh sách cửa hàng nhân viên được phân quyền khóa:</div>
      }
      @case ('unlock') {
        <div class="tds-body-2 tds-color-text-primary">Danh sách cửa hàng nhân viên được phân quyền mở khóa:</div>
      }
      @case ('delete') {
        <div class="tds-body-2 tds-color-text-primary">Danh sách cửa hàng nhân viên được phân quyền xóa:</div>
      }
      @case ('invite') {
        <div class="tds-body-2 tds-color-text-primary">Danh sách cửa hàng nhân viên được phân quyền gửi lời mời:</div>
      } @default {
        <div>Sai loại thao tác</div>
      }
    }
    
    <div class="flex flex-col gap-3 p-2 w-full h-[344px] border tds-color-border-primary rounded-md overflow-y-auto custom-scroll">
      @for(branch of employee.userInStoreBranches; track branch.id) {
        <div class="tds-color-background-neutral-minimalist p-3 rounded-md">
          <div class="tds-body-2 font-semibold">{{branch.name}}</div>
        </div>
      }
    </div>

    @switch (type) {
      @case ('delete') {
        <div class="w-full">
          <p class="tds-body-2">
            <span class="font-semibold tds-color-text-error">Lưu ý:</span>
            Sau khi xóa, dữ liệu và thông tin giao dịch của nhân viên <span class="tds-color-text-primary font-semibold">“{{employee.name}}”</span> trên các cửa hàng vẫn còn được lưu trữ.
          </p>
        </div>
      }
      @case ('lock') {
        <div class="w-full">
          <p class="tds-body-2">
            <span class="font-semibold tds-color-text-error">Lưu ý:</span>
            Trong thời gian khóa, nhân viên <span class="tds-color-text-primary font-semibold">“{{employee.name}}”</span> sẽ không thể truy cập vào các cửa hàng. Dữ liệu và thông tin giao dịch không bị mất.
          </p>
        </div>
      }
    }
  </div>
</ng-container>
