import { AuthService } from 'app/core/services/auth.service';
import { IEmployee } from '../models/employee.model';

export class BaseEmployeeClass {
  constructor(protected readonly authService: AuthService) {}

  /**
   * Determines if the actions should be hidden for a specific employee.
   * @param employee - The employee info to check.
   * @returns True if the actions should be hidden, false otherwise.
   */
  shouldHiddenActions(employee: IEmployee): boolean {
    return this.authService.getUserInfo()!.id === employee.id || employee.isOwner;
  }

  isOwner(): boolean {
    return this.authService.isOwner();
  }
}