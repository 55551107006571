import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserInfo } from 'app/core/models/user.model';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { TDSBadgeModule } from 'tds-ui/badges';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSMessageModule, TDSMessageService } from 'tds-ui/message';
import { TDSModalModule, TDSModalRef } from 'tds-ui/modal';
import { TDSSelectModule } from 'tds-ui/select';
import { TDSTagModule } from 'tds-ui/tag';
import { TDSInputModule } from 'tds-ui/tds-input';
import { UserService } from '../../services/user.service';
import { IUpdateUserInfoReq } from '../../models/update-user-info-req.model';
import { finalize, takeUntil, tap } from 'rxjs';
import { TDSDestroyService } from 'tds-ui/core/services';
import { ErrorDetails } from 'app/core/models/error-response.model';
import { TDSDatePickerModule } from "tds-ui/date-picker";
import { differenceInCalendarDays } from 'date-fns';
import { DistrictResponse, ProvinceResponse, WardResponse } from 'app/core/models/address.model';
import { AddressService } from 'app/core/services/address.service';
import { TDSSpinnerModule } from 'tds-ui/progress-spinner';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { WiECommerceDirtyErrorStateMatcher } from 'app/core/utilities/error-state-matcher.util';
import { emailValidator } from 'app/core/utilities/email-validator.util';
import { CustomUploadImageComponent } from 'app/shared/components/custom-upload-image/custom-upload-image.component';
import { UploadImageResponse } from 'app/shared/models/upload-image.model';

@Component({
  selector: 'app-edit-profile',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TDSButtonModule,
    TDSInputModule,
    TDSFormFieldModule,
    TDSMessageModule,
    TDSModalModule,
    TDSTagModule,
    TDSAvatarModule,
    TDSBadgeModule,
    TDSSelectModule,
    TDSDatePickerModule,
    TDSSpinnerModule,
    CustomUploadImageComponent
  ],
  providers: [
    UserService,
    TDSDestroyService,
    TDSMessageService,
    AddressService,
    {
      provide: ErrorStateMatcher, useClass: WiECommerceDirtyErrorStateMatcher
    },
  ],
  templateUrl: './edit-profile.component.html',
  styleUrl: './edit-profile.component.scss'
})
export class EditProfileComponent implements OnInit {
  @Input() userInfo!: UserInfo;
  editForm!: FormGroup;

  isLoading = false;
  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) > 0;

  provinces: ProvinceResponse[] = [];
  districts: DistrictResponse[] = [];
  wards: WardResponse[] = [];
  curProvinceCode!: string;
  curDistCode!: string;

  isUploading = false;

  constructor(
    private readonly userService: UserService,
    private readonly tdsDestroyService: TDSDestroyService,
    private readonly msgService: TDSMessageService,
    private readonly modalRef: TDSModalRef,
    private readonly addressService: AddressService,
    private readonly cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {
    this.loadProvinces();
    this.initForm();
    if (this.userInfo) {
      this.updateUserInfo();
    }
  }

  initForm(): void {
    this.editForm = new FormGroup({
      avatar: new FormControl(null),
      name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, emailValidator),
      birthday: new FormControl(null),
      street: new FormControl(null),
      ward: new FormControl(null),
      district: new FormControl(null),
      city: new FormControl(null),
    });
  }

  updateUserInfo(): void {
    this.editForm.patchValue({
      avatar: this.userInfo.avatar,
      name: this.userInfo.name,
      email: this.userInfo.email,
      birthday: this.userInfo.birthday,
      street: this.userInfo.street,
      ward: this.userInfo.ward,
      district: this.userInfo.district,
      city: this.userInfo.city,
    })
  }

  onClose(): void {
    this.modalRef.close();
  }

  onCancel(): void {
    this.modalRef.close();
  }

  private prepareUpdateInfo(): IUpdateUserInfoReq {
    return {
      Name: this.editForm.value.name,
      Avatar: this.editForm.value.avatar ?? null,
      Email: this.editForm.value.email,
      Birthday: this.editForm.value.birthday ? new Date(this.editForm.value.birthday) : null,
      Street: this.editForm.value.street,
      Ward: this.editForm.value.ward,
      District: this.editForm.value.district,
      City: this.editForm.value.city,
    }
  }

  private markFormAsDirty(): void {
    Object.keys(this.editForm.controls).forEach(key => {
      this.editForm.get(key)?.markAsDirty();
    });
  }

  onSubmit(): void {
    this.markFormAsDirty();

    if (!this.editForm.valid) return;

    this.isLoading = true;
    const updateInfo: IUpdateUserInfoReq = this.prepareUpdateInfo();

    this.userService.updateUserInfo$(updateInfo)
      .pipe(
        takeUntil(this.tdsDestroyService),
        finalize(() => this.isLoading = false)
      )
      .subscribe({
        next: () => {
          this.msgService.success('Cập nhật thông tin thành công');
          this.modalRef.destroy(true);
        },
        error: (err: ErrorDetails) => {
          this.msgService.error(err.message ?? 'Cập nhật thông tin thất bại');
        }
      })
  }

  loadProvinces(): void {
    this.addressService.getProvinces$()
      .pipe(
        tap(res => {
          this.curProvinceCode = res.Data.find(city => city.Name === this.userInfo.city)?.Code as string;
          if (this.curProvinceCode) {
            this.loadDistricts(this.curProvinceCode);
          }
        }),
        takeUntil(this.tdsDestroyService)
      )
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.provinces = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách tỉnh/thành phố thất bại');
        }
      })
  }

  loadDistricts(provinceCode: string): void {
    this.addressService.getDistricts$(provinceCode)
      .pipe(
        tap(res => {
          this.curDistCode = res.Data.find(district => district.Name === this.userInfo.district)?.Code as string;
          if (this.curDistCode) {
            this.loadWards(this.curDistCode);
          }        
        }),
        takeUntil(this.tdsDestroyService)
      )
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.districts = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách quận/huyện thất bại');
        }
      })
  }

  loadWards(districtCode: string): void {
    this.addressService.getWards$(districtCode)
      .pipe(takeUntil(this.tdsDestroyService))
      .subscribe({
        next: (res) => {
          if (res.Error) return;
          this.wards = res.Data;
        },
        error: () => {
          this.msgService.error('Lấy danh sách phường/xã thất bại');
        }
      })
  }

  private handleDeleteProvince(): void {
    this.editForm.get('district')?.reset();
    this.districts = [];

    this.onChangeDistrict(null);
  }

  onChangeProvince(event: ProvinceResponse | null): void {
    if (!event) {
      this.handleDeleteProvince();
    } else {
      this.editForm.get('district')?.reset();
      this.editForm.get('ward')?.reset();

      this.editForm.get('city')?.setValue(event.Name);
      this.loadDistricts(event.Code);
    }
  }

  private handleDeleteDistrict(): void {
    this.editForm.get('ward')?.reset();
    this.wards = [];
  }

  onChangeDistrict(event: DistrictResponse | null): void {
    if (!event) {
      this.handleDeleteDistrict();
    } else {
      this.editForm.get('ward')?.reset();

      this.editForm.get('district')?.setValue(event.Name);
      this.loadWards(event.Code);
    }
  }

  onChangeWard(event: WardResponse): void {
    if (!event) return;

    this.editForm.get('ward')?.setValue(event.Name);
  }

  handleUploadSucceeded(event: UploadImageResponse): void {
    this.editForm.get('avatar')?.setValue(event.url);
  }

  onLoadingChange(isLoading: boolean): void {
    this.isUploading = isLoading;
    this.cdr.detectChanges();
  }
}
