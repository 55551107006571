<section class="w-full border tds-color-border-primary rounded-md"
  [ngClass]="{'pb-2.5': permissionDetailList.length > 0 && !isCollapsed}"
>
  <form [formGroup]="form"
    class="flex flex-col gap-2 w-full"
  >
    <div class="flex items-center tds-color-background-neutral-minimalist p-1 gap-1 rounded-md w-full cursor-pointer"
      (click)="collapsePermission($event)"
    >
      <div class="flex items-center">
        <i class="tds-color-icon-secondary text-xl leading-none p-1"
          [ngClass]="{'tdsi-chevron-down-fill': !isCollapsed, 'tdsi-chevron-right-fill': isCollapsed}"
        ></i>
        <tds-checkbox 
          [checked]="form.get('isActive')?.value"
          [indeterminate]="form.get('isIndeterminate')?.value"
          (click)="$event.stopPropagation()"
          (tdsChange)="onChangeActive($event)"
        ></tds-checkbox>
      </div>
      <div class="tds-body-2 font-semibold tds-color-text-tertiary p-1">{{ displayName.value }}</div>
    </div>

    @if (permissionDetailList.length > 0 && !isCollapsed) {
      <div class="flex w-full h-auto gap-2 px-8"
        [ngClass]="{
          'flex-col': permission.permissionName !== 'Setting'
        }"
      >
        <ng-container *ngFor="let permissionDetail of permissionDetailList.controls; let i = index;"
          formArrayName="permissionDetailList"
        >
          <div class="flex gap-2"
            [ngClass]="{
                'flex-col': permissionDetailListAtIndex(i).value.length > 0,
                'items-center': permissionDetailListAtIndex(i).value.length === 0,
                'w-1/3': permission.permissionName === 'Setting',
              }"
            [formGroupName]="i"
          >
            <div class="w-full flex items-center gap-2">
              <tds-checkbox 
                [checked]="permissionDetail.get('isActive')?.value"
                [indeterminate]="permissionDetail.get('isIndeterminate')?.value"
                (tdsChange)="onChangeActiveItem($event, permissionDetail)"
              ></tds-checkbox> 
              <span class="tds-body-2">{{ permissionDetail.get('displayName')?.value }}</span>
            </div>

            @if (permissionDetailListAtIndex(i).value.length > 0) {
              <div class="w-full h-auto flex flex-col gap-2 px-4">
                <ng-container *ngFor="let permissionDetail of permissionDetailListAtIndex(i).controls; let i1 = index;"
                  formArrayName="permissionDetailList"
                >
                  <div [formGroupName]="i1"
                  >
                    <div class="flex items-center gap-2">
                      <tds-checkbox 
                        [checked]="permissionDetail.get('isActive')?.value"
                        [indeterminate]="permissionDetail.get('isIndeterminate')?.value"
                        (tdsChange)="onChangeActiveItem($event, permissionDetail, i)"
                      ></tds-checkbox>
                      <span class="tds-body-2">{{ permissionDetail.get('displayName')?.value }}</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            }
          </div>
        </ng-container>
      </div>
    }
  </form>
</section>