import { Injectable } from '@angular/core';
import { DEFAULT_PERMISSION_ENDPOINT, MENU_IN_ROLE_ENDPOINT, ROLE_ENDPOINT, ROLE_V2_ENDPOINT, UPDATE_USER_ROLE_IN_BRANCHES_ENDPOINT, USER_PERMISSION_ENDPOINT } from 'app/core/constants/api-endpoint.const';
import { ApiService } from 'app/core/services/api.service';
import { Observable } from 'rxjs';
import { ICreateRoleReq } from '../models/create-role.model';
import { IPermission, IUserPermission } from '../models/permission.model';
import { IRole, IRoleList } from '../models/role.model';
import { IUpdateRoleReq } from '../models/update-role.model';
import { TDSHelperString } from 'tds-ui/shared/utility';
import { IUpdateUserRoleInBranches } from '../models/update-user-role-in-branches.model';
import { IRoleQueryParams } from '../models/query-role-params.model';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
    private readonly apiService: ApiService
  ) {
  }

  getRoles$(
    skip: number = 0,
    take: number = 20,
    search: string = ''
  ): Observable<IRoleList> {
    const searchParams = TDSHelperString.hasValueString(search) ? `&search=${search}` : '';
    return this.apiService.get<IRoleList>(`${ROLE_ENDPOINT}?skip=${skip}&take=${take}${searchParams}`);
  }

  getRoleById$(id: string): Observable<IRole> {
    return this.apiService.get<IRole>(`${ROLE_ENDPOINT}/${id}`);
  }

  createRole$(model: ICreateRoleReq): Observable<IRole> {
    return this.apiService.post(`${ROLE_ENDPOINT}`, model);
  }

  updateRole$(id: string, model: IUpdateRoleReq): Observable<void> {
    return this.apiService.put(`${ROLE_ENDPOINT}/${id}`, model);
  }

  getDefaultPermissions$(): Observable<IPermission[]> {
    return this.apiService.get<IPermission[]>(`${DEFAULT_PERMISSION_ENDPOINT}`);
  }

  getUserPermissions$(): Observable<IUserPermission> {
    return this.apiService.get<IUserPermission>(`${USER_PERMISSION_ENDPOINT}`);
  }

  deleteRole$(id: string): Observable<void> {
    return this.apiService.delete(`${ROLE_ENDPOINT}/${id}`);
  }

  updateUserRoleInBranches$(payload: IUpdateUserRoleInBranches): Observable<void> {
    return this.apiService.put(`${UPDATE_USER_ROLE_IN_BRANCHES_ENDPOINT}`, payload);
  }

  getRolesV2$(queryParams: IRoleQueryParams): Observable<IRoleList> {
    return this.apiService.post<IRoleList>(`${ROLE_V2_ENDPOINT}`, queryParams);
  }

  getMenu(): Observable<any> {
    return this.apiService.get<any>(`${MENU_IN_ROLE_ENDPOINT}`);
  }
}
