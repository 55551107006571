<ng-container>
  <div class="flex-auto w-full tds-body-2 p-2">
    <tds-table templateMode>
      <thead>
        <tr>
          <th scope="col">Cửa hàng làm việc</th>
          <th scope="col">Vai trò</th>
          <th scope="col">Trạng thái</th>
          <th scope="col">Cập nhật bởi</th>
          <th scope="col">Ngày cập nhật</th>
          <th scope="col" [tdsRight]="true">Thao tác</th>
        </tr>
      </thead>
      <tbody>
        @for (employeeBranch of employeeBranches; track employeeBranch.id) {
        <tr>
          <td>
            <span>{{ employeeBranch.name }}</span>
          </td>
          <td>
            <tds-tag 
              type="outline"
              size="md"
              status="default"
              class="tds-color-text-tertiary"
            >
              {{ employeeBranch.roleName }}
            </tds-tag>
          </td>
          <td>
            <app-employee-status 
              [status]="employeeBranch.userStatus"
              [statusName]="employeeBranch.userStatusName!"
            ></app-employee-status>
          </td>
          <td>
            @if (employeeBranch.updator && employeeBranch.updator.avatar) {
              <div class="flex items-center gap-2">
                <tds-avatar class="bg-base-orange-500 dark:bg-d-base-orange-500 text-white" size="md"
                  [tdsSrc]="employeeBranch.updator!.avatar">
                </tds-avatar>
                <div class="flex-1">{{employeeBranch.updator!.name}}</div>
              </div>
            } @else {
              <div>---</div>
            }
          </td>
          <td>{{ employeeBranch.lastModificationTime | date: 'dd/MM/yyyy HH:mm' }}</td>
          <td [tdsRight]="true">
            @if (!shouldHiddenActions(employee)) {
              <app-employee-actions 
                [status]="employeeBranch.userStatus" 
                [isDetail]="true"
                [employee]="employee"
                [employeeBranch]="employeeBranch"
              ></app-employee-actions>
            }
          </td>
        </tr>
        }
      </tbody>
    </tds-table>
  </div>
</ng-container>