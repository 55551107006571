import { Component, inject, OnInit } from '@angular/core';
import { IEmployee } from '../../models/employee.model';
import { TDS_MODAL_DATA, TDSModalModule, TDSModalRef } from 'tds-ui/modal';
import { CommonModule } from '@angular/common';
import { TDSButtonModule } from 'tds-ui/button';
import { TDSAvatarModule } from 'tds-ui/avatar';
import { TDSTagModule } from 'tds-ui/tag';
import { EmployeeStatusComponent } from '../employee-status/employee-status.component';
import { AddressPipe } from 'app/shared/pipes/address.pipe';
import { EmployeeActionsComponent } from '../employee-actions/employee-actions.component';
import { AuthService } from 'app/core/services/auth.service';
import { BaseEmployeeClass } from '../../classes/base-employee.class';
import { EEmployeeStatus } from '../../enums/employee-status.enum';

@Component({
  selector: 'app-employee-detail',
  standalone: true,
  imports: [
    CommonModule,
    TDSModalModule,
    TDSButtonModule,
    TDSAvatarModule,
    TDSTagModule,
    EmployeeStatusComponent,
    AddressPipe,
    EmployeeActionsComponent
  ],
  templateUrl: './employee-detail.component.html',
  styleUrl: './employee-detail.component.scss'
})
export class EmployeeDetailComponent extends BaseEmployeeClass implements OnInit {
  tdsData: {employee: IEmployee} = inject(TDS_MODAL_DATA);
  employee!: IEmployee;
  status = EEmployeeStatus;

  constructor(
    private readonly modalRef: TDSModalRef<any>,
    authService: AuthService
  ) {
    super(authService)
  }

  ngOnInit(): void {
    if (this.tdsData.employee) {
      this.employee = this.tdsData.employee;
    };
  }

  close() {
    this.modalRef.close();
  }
}
