<div class="w-full h-full flex flex-col">
    <div class="flex flex-col tds-page-header-color-background">
        <tds-page-header tdsTitle="Cài đặt chung">
            <tds-page-header-content>
                <div class="w-full flex items-center">
                    <div class="flex items-center">
                        <tds-tabs-filter 
                            [tdsData]="lstSetting" 
                            [(tdsValue)]="selectedSetting" 
                            (tdsOptionChange)="onSettingSelected($event)"
                            tdsType="pill">
                        </tds-tabs-filter>
                    </div>
                </div>
            </tds-page-header-content>
        </tds-page-header>
    </div>
    <div class="h-full p-2 overflow-auto">
        <router-outlet></router-outlet>
    </div>
</div>