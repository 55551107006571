<tds-table
  #roleTable
  [widthConfig]="['180px', '270px', '200px', '200px', '200px', '120px', '200px', '120px', '200px', '120px', '108px']"
  showPagination
  showSizeChanger
  [scroll]="{'x':'auto','y': 'auto'}"
  [listData]="roles"
  [(pageIndex)]="pageIndex"
  [(pageSize)]="pageSize"
  [total]="total"
  [loading]="isLoading"
  [frontPagination]="false"
  [pageSizeOptions]="[10, 20, 50, 100, 200]"
  [noResult]="noResult"
  (queryParams)="onQueryParamsChange($event)"
  (clickRefresh)="onRefresh()"
>
  <thead>
    <tr>
      <th scope="col">Tên vai trò</th>
      <th scope="col">Mô tả</th>
      <th scope="col">Cửa hàng</th>
      <th scope="col">Doanh nghiệp</th>
      <th scope="col">Nhân viên</th>
      <th scope="col">Loại</th>
      <th scope="col">Tạo bởi</th>
      <th scope="col">Ngày tạo</th>
      <th scope="col">Cập nhật bởi</th> 
      <th scope="col">Ngày cập nhật</th>
      <th scope="col" [tdsRight]="true">Thao tác</th>
    </tr>
  </thead>
  <tbody>
    @for(role of roles; track role.id; let index = $index) {
      <tr>
        <td>
          <tds-tag
            type="outline"
            size="md"
            status="default"
            class="tds-color-text-tertiary"
          >
            {{role.name}}
          </tds-tag>
        </td>
        <td>
          @if (role.description && role.description!.length) {
            <div class="tds-body-2">
              <p
                [class.line-clamp-2]="role.description!.length > 100 && !isExpanded(index)"
              >
                {{ role.description }}
              </p>
              <!-- Only show button when content length longer than 100 -->
              @if (role.description!.length > 100) {
                <button
                  class="tds-color-text-interactive font-semibold hover:underline mt-1"
                  (click)="toggleContent(index)"
                >
                  {{ isExpanded(index) ? 'Thu gọn' :'Xem thêm' }}
                </button>
              }
            </div>
          } @else {
            <div>---</div>
          }
        </td>
        <td>
          {{ role.storeBranchName }}
        </td>
        <td>
          {{ role.storeName }}
        </td>
        <td>
          @if (role.userInRoleList && role.userInRoleList.length) {
            @if (role.userInRoleList.length === 1) {
              <div class="flex items-center gap-2">
                <app-online-status
                  [avatar]="role.userInRoleList[0].avatar"
                  [isOnline]="role.userInRoleList[0].isOnline"
                ></app-online-status>
                <span class="tds-body-2 tds-color-text-primary">{{role.userInRoleList[0].name}}</span>
              </div>
            } @else {
              <tds-avatar-group>
                @for(user of role.userInRoleList; let idx = $index; track user.id) {
                  @if(idx < 3) {
                    <tds-avatar class="tds-color-background-info-minimal tds-color-text-info tds-body-1 font-semibold cursor-pointer"
                      tds-tooltip
                      [tooltipTitle]="role.userInRoleList[idx].name"
                      [tdsSrc]="role.userInRoleList[idx].avatar">
                    </tds-avatar>
                  }
                }
                @if(role.userInRoleList.length > 3){
                  <tds-avatar 
                    class="tds-color-background-info-minimal tds-color-text-info font-semibold tds-body-1 cursor-pointer" 
                    tds-dropdown
                    [text]="'+' + (role.userInRoleList.length - 3)"
                    trigger="click"
                    [tdsDropdownMenu]="usersInRole"
                  >
                  </tds-avatar>
                }

                <tds-dropdown-menu #usersInRole="tdsDropdownMenu">
                  @for(user of role.userInRoleList; track user.id; let idx = $index) {
                    @if(idx >= 3) {
                      <div class="min-w-64 max-w-64 flex items-center gap-2 px-3 py-2">
                        <app-online-status
                          [avatar]="user.avatar"
                          [isOnline]="user.isOnline"
                        ></app-online-status>
                        <span class="tds-body-2 tds-color-text-primary flex-1">{{user.name}}</span>
                      </div>
                    }
                  }
                </tds-dropdown-menu>
              </tds-avatar-group>
            }
          } @else {
            <div>---</div>
          }
        </td>
        <td> 
          <tds-tag [status]="role.isDefault ? 'info' : 'success'" type="outline">{{role.isDefault ? 'Mặc định' : 'Tùy chỉnh'}}</tds-tag>
        </td>
        <td>
          <div class="flex items-center gap-2">
            @if(role.creator !== null) {
              <tds-avatar
                [tdsSrc]="role.creator.avatar"
              ></tds-avatar>
              <div>{{role.creator.name}}</div>
            } @else {
              <div>---</div>
            }
          </div>
        </td>
        <td>
          <div>{{role.creationTime | date: 'dd/MM/yyyy'}}</div>
          <div>{{role.creationTime | date: 'HH:mm:ss'}}</div>
        </td>
        <td>
          <div class="flex items-center gap-2">
            @if(role.updator !== null) {
              <tds-avatar
                [tdsSrc]="role.updator!.avatar"
              ></tds-avatar>
              <div>{{role.updator!.name}}</div>  
            } @else {
              <div>---</div>
            }
          </div>
        </td>
        <td>
          @if(role.lastModificationTime) {
            <div>{{role.lastModificationTime | date: 'dd/MM/yyyy'}}</div>
            <div>{{role.lastModificationTime | date: 'HH:mm:ss'}}</div>
          } @else {
            <div>---</div>
          }
        </td>
        <td [tdsRight]="true">
          @if(!role.isDefault) {
            <div class="flex items-center gap-2">
            <button tds-button-flat-icon
              size="sm"
              color="info"
              (tdsBtnClick)="editRole(role)"
            >
              <i class="tdsi-edit-line"></i>
            </button>
            <button tds-button-flat-icon
              size="sm"
              color="error"
              (tdsBtnClick)="deleteRole(role)"
            >
              <i class="tdsi-trash-line"></i>
              </button>
            </div>
          }
        </td>
      </tr>
    }
  </tbody>
</tds-table>

<ng-template #noResult>
  <div class="flex justify-center items-center w-full h-full bg-white rounded-md">
    <div class="flex flex-col gap-4 w-[400px] h-[430px]">
      <div class="w-full h-full px-[48px]">
        <img src="/assets/images/employee-no-result.svg"
          alt="no-result" class="w-full h-full">
      </div>
      <div class="flex flex-col gap-2 items-center justify-center">
        <div class="tds-heading-5 tds-color-text-primary font-semibold">Không tìm thấy kết quả</div>
        <div class="text-body-2 tds-color-text-secondary">Hãy thử tìm kiếm với các thông tin khác</div>
      </div>
      <div class="flex justify-center">
        <button tds-button
          [tdsPrefixIcon]="'tdsi-trash-fill'"
          color="primary"
          size="lg"
          (tdsBtnClick)="deleteFilter()"
        >
          Xóa bộ lọc
        </button>
      </div>
    </div>
  </div>
</ng-template>