import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TDSButtonModule } from 'tds-ui/button';
import { SortDateClassPipe } from '../../pipes/sort-date.pipe';
import { CommonModule } from '@angular/common';
import { TDSPopoverModule } from 'tds-ui/popover';
import { TDSFormFieldModule } from 'tds-ui/form-field';
import { TDSInputModule } from 'tds-ui/tds-input';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { ATTRIBUTE_RULE } from '../../constants/attribute-rule.constant';
import { NoWhitespaceValidator } from 'app/shared/custom-validators/no-whitespace-validator';
import { WiECommerceDirtyErrorStateMatcher } from 'app/core/utilities/error-state-matcher.util';
import { ErrorStateMatcher } from 'tds-ui/shared/common';
import { CreateAttribute, ResponseCreateAttribute } from '../../models/attribute.model';
import { TDSHelperString, TDSSafeAny } from 'tds-ui/shared/utility';
import { AuthService } from 'app/core/services/auth.service';
import { AttributeService } from '../../service/attribute.service';
import { TDSMessageService } from 'tds-ui/message';
import { Observable, map, of, switchMap, timer } from 'rxjs';
import { TDSToolTipModule } from 'tds-ui/tooltip';

@Component({
  selector: 'app-attribute-panel',
  standalone: true,
  imports: [
    CommonModule,
    TDSButtonModule,
    SortDateClassPipe,
    TDSPopoverModule,
    TDSFormFieldModule,
    TDSInputModule,
    ReactiveFormsModule,
    TDSToolTipModule
  ],
  providers: [
    {
      provide: ErrorStateMatcher,
      useClass: WiECommerceDirtyErrorStateMatcher
    }
  ],
  templateUrl: './attribute-panel.component.html',
  styleUrl: './attribute-panel.component.scss'
})
export class AttributePanelComponent implements OnInit {
  @Output() refreshEvent = new EventEmitter<boolean>();
  @Output() sortDateCreatedEvent = new EventEmitter<boolean>();
  sortByName: string = '';
  form!: FormGroup;
  rule = ATTRIBUTE_RULE;
  isVisibleAddAttribute: boolean = false;
  storeId: string | null = null;

  constructor(private fb: FormBuilder,
    private attributeService: AttributeService,
    private messageService: TDSMessageService,
    private authService: AuthService,) {

  }
  ngOnInit(): void {
    this.getCurrentStore();
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      name: [
        null, Validators.compose([
          Validators.required,
          Validators.minLength(this.rule.name.minLength),
          Validators.maxLength(this.rule.name.maxLength),
          NoWhitespaceValidator(),
        ]), this.checkExistName(this.attributeService)
      ]
    })
  }

  getCurrentStore() {
    this.storeId = this.authService.getCurrentStoreId();
  }

  addAttribute() {
    this._markDirtyAndTouched();
    if (!this.form.valid) return;
    const model = this.prepareModel();
    this.attributeService.addAttributes$(model)
      .subscribe({
        next: (res: ResponseCreateAttribute) => {
          this.clearForm();
          this.isVisibleAddAttribute = false;
          this.refreshEvent.emit(true);
        }, error: () => this.messageService.error("Có lỗi khi thêm thuộc tính!"),
      })
  }

  private _markDirtyAndTouched() {
    this.form.controls['name'].markAsDirty();
    this.form.controls['name'].markAsTouched();
  }

  prepareModel(): CreateAttribute {
    const valueForm = this.form.value;
    const model: CreateAttribute = {
      name: valueForm.name,
      storeId: TDSHelperString.hasValueString(this.storeId) ? this.storeId : ''
    }
    return model;
  }

  private clearForm() {
    this.form.get('name')?.setValue(null);
  }

  close() {
    this.clearForm();
    this.isVisibleAddAttribute = false;
  }

  checkExistName = (service: AttributeService) => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }

      return timer(500).pipe(
        switchMap(() =>
          service.checkExistAttribute$({
            name: control.value,
            storeId: this.storeId ?? ''
          })
            .pipe(
              map((res: TDSSafeAny) => {
                if (res) {
                  return { duplicate: true };
                } else {
                  return null;
                }
              })
            )
        )
      )
    }
  }

  changeSort() {
    this.sortDateCreatedEvent.emit();
  }
}
