<button tds-button-outline-icon
  color="secondary"
  tds-popover
  popoverTrigger="click"
  popoverPlacement="bottomRight"
  [popoverContent]="colConfig"
  [popoverOverlayClassName]="'custom-padding-popover'"
  [(popoverVisible)]="isVisible"
  size="md" class="relative"
>
  <span class="flex items-center">
    <em class="tdsi-column-setting-line text-lg text-neutral-1-500 leading-none"></em>
  </span>
</button>

<ng-template #colConfig>
  <div class="w-44 flex flex-col py-1">
    @for(col of colsConfig; track col.id) {
      <div class="px-3 flex items-center gap-2 tds-body-2 leading-8">
        <tds-checkbox
          [checked]="col.checked"
          (tdsChange)="onChangeCol(col)"
        ></tds-checkbox>
        <div>{{col.name}}</div>
      </div>
    }
  </div>
</ng-template>