<ng-container>
  <div class="p-6 w-full flex flex-col">
    <div class="w-full flex flex-col">
      <div class="pb-3 flex items-center justify-center">
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M62.25 11.61V41.04C62.25 41.5269 62.0921 42.0006 61.8 42.39C61.5079 42.7795 61.0974 43.0637 60.63 43.2L53.3797 50.1309C53.1707 50.16 36.209 50.1892 36 50.16H11.82C10.5043 50.1842 9.23243 49.6866 8.28243 48.7759C7.33243 47.8653 6.78149 46.6156 6.75 45.3V11.61C6.74927 10.9527 6.88793 10.3026 7.15683 9.70276C7.42573 9.10291 7.81877 8.56688 8.31 8.13005C8.5842 7.88513 8.91356 7.71016 9.27 7.62005C9.61885 7.53108 9.9841 7.52829 10.3343 7.61191C10.6844 7.69553 11.009 7.86306 11.28 8.10005L34.02 27.69C34.152 27.8081 34.3229 27.8734 34.5 27.8734C34.6771 27.8734 34.848 27.8081 34.98 27.69L57.72 8.10005C58.1325 7.73108 58.6666 7.5271 59.22 7.5271C59.7735 7.5271 60.3075 7.73108 60.72 8.10005C61.2096 8.54296 61.5992 9.08506 61.8631 9.69026C62.1269 10.2955 62.2588 10.9499 62.25 11.61Z" fill="#E5EAEB"/>
          <path d="M61.4398 9.9C61.4281 10.2091 61.3529 10.5126 61.2187 10.7913C61.0844 11.07 60.8942 11.3181 60.6598 11.52L37.9198 31.05C36.9828 31.8987 35.764 32.3691 34.4998 32.37C33.2273 32.3652 32.0005 31.8958 31.0498 31.05L8.3398 11.52C8.1054 11.3181 7.91515 11.07 7.78095 10.7913C7.64674 10.5126 7.57145 10.2091 7.5598 9.9C7.54602 9.58937 7.59765 9.27931 7.71135 8.9899C7.82504 8.70049 7.99827 8.4382 8.2198 8.22C9.18039 7.27656 10.4734 6.74859 11.8198 6.75H57.1798C58.5262 6.74859 59.8192 7.27656 60.7798 8.22C61.0013 8.4382 61.1745 8.70049 61.2882 8.9899C61.4019 9.27931 61.4536 9.58937 61.4398 9.9Z" fill="#CCD2D4"/>
          <path d="M55.5577 58.9651C63.8739 58.9651 70.6155 52.2235 70.6155 43.9073C70.6155 35.5912 63.8739 28.8496 55.5577 28.8496C47.2416 28.8496 40.5 35.5912 40.5 43.9073C40.5 52.2235 47.2416 58.9651 55.5577 58.9651Z" fill="#27A745"/>
          <path d="M47.5054 44.4598L52.5052 49.4596L63.6098 38.355" stroke="white" stroke-width="3"/>
        </svg>
      </div>
      <div class="w-full flex flex-col gap-4">
        <div class="flex items-center justify-center tds-color-text-primary tds-heading-5 font-semibold">
          Gửi lời mời thành công
        </div>
        <div class="w-full tds-body-2">
          <ul class="list-disc pl-6">
            <li>
              Lời mời tham gia cửa hàng đã được gửi đến tài khoản có số điện thoại <span class="font-semibold tds-color-text-primary">{{phoneNumber}}</span> qua SMS
            </li>
            <li>
              Bạn có thể gửi đường dẫn <a class="font-semibold tds-color-text-info underline">{{invitationUrl}}</a> 
              <span class="tdsi-copy-fill text-xl leading-none tds-color-icon-info cursor-pointer ml-1"
                (click)="onCopyToClipboard()"
              ></span>
              đến nhân viên <span class="font-semibold tds-color-text-primary">{{employeeName}}</span> 
              để thông báo về việc đăng ký và xác nhận lời mời tham gia cửa hàng
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="w-full flex mt-6 items-center justify-center">
      <button tds-button-outline
        color="secondary"
        size="md"
        class="min-w-[100px]"
        (tdsBtnClick)="onClose()"
      >
        Đóng
      </button>
    </div>
  </div>
</ng-container>
