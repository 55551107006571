<ng-container>
  <tds-spin class="w-full h-full"
    [spinning]="isLoading"
  >
    <form [formGroup]="editEmployeeForm"
      class="h-full w-full flex flex-col gap-3"
    >
      <div class="flex items-center gap-2 w-full">
        <tds-label [tdsRequired]="true"
          class="w-1/3"
        >Tên nhân viên</tds-label>
        <tds-form-field class="w-2/3">
          <input 
            tdsInput
            autocomplete="off"
            placeholder="Tối đa 100 ký tự"
            [maxlength]="100"
            formControlName="name"
          />
          <tds-error>Vui lòng nhập tên nhân viên</tds-error>
        </tds-form-field>
      </div>

      <div class="flex items-center gap-2 w-full">
        <tds-label class="w-1/3">Số điện thoại</tds-label>
        <tds-form-field class="w-2/3">
          <input tdsInput
            [disabled]='true'
            [value]="employee.phoneNumber"
          />
        </tds-form-field>
      </div>

      <div class="flex items-center gap-2 w-full">
        <tds-label class="w-1/3">Email</tds-label>
        <tds-form-field class="w-2/3">
          <input tdsInput
            autocomplete="off"
            placeholder="Nhập email"
            formControlName="email"
          />
          <tds-error>Email không hợp lệ</tds-error>
        </tds-form-field>
      </div>

      <div class="flex items-center gap-2 w-full">
        <tds-label class="w-1/3">
          Ngày sinh
        </tds-label>
        <tds-form-field class="w-2/3">
          <tds-date-picker
            [disabledDate]="disabledDate"
            [inputReadOnly]="true"
            placeholder="Chọn ngày sinh"
            formControlName="birthday"
          >
          </tds-date-picker>
        </tds-form-field>
      </div>

      <div class="flex items-start gap-2">
        <tds-label
          class="w-1/3 mt-1"
        >
          Địa chỉ
        </tds-label>
        <div class="flex flex-col gap-2 w-2/3">
          <tds-form-field class="w-full">
              <input tdsInput 
                formControlName="street"
                placeholder="Nhập số nhà, tên đường"
                [maxlength]="1000"
              />
            </tds-form-field>
            <tds-form-field class="w-full">
              <tds-select
                [data]="provinces"
                [valuePrimitive]="false"
                valueField="Code"
                textField="Name"
                formControlName="city"
                placeholder="Tỉnh/thành phố"
                [allowClear]="true"
                (selectChange)="onChangeProvince($event)"
              >
              </tds-select>
            </tds-form-field>
            <tds-form-field class="w-full">
              <tds-select
                [data]="districts"
                [valuePrimitive]="false"
                valueField="Code"
                textField="Name"
                formControlName="district"
                placeholder="Quận/huyện"
                [allowClear]="true"
                (selectChange)="onChangeDistrict($event)"
              >
              </tds-select>
            </tds-form-field>
            <tds-form-field class="w-full">
              <tds-select
                [data]="wards"
                [valuePrimitive]="false"
                valueField="Code"
                textField="Name"
                formControlName="ward"
                placeholder="Phường/xã"
                [allowClear]="true"
                (selectChange)="onChangeWard($event)"
              >
              </tds-select>
            </tds-form-field>
        </div>
      </div>

      <div class="flex items-center gap-2 w-full">
        <tds-label [tdsRequired]="true" class="w-1/3">Vai trò</tds-label>
        <tds-form-field class="w-2/3">
          <tds-select
            placeholder="Chọn vai trò"
            [valuePrimitive]="false"
            [allowClear]="true"
            [data]="roles"
            [loading]="isLoadingMore"
            formControlName="roleId"
            valueField="id"
            textField="name"
            (selectChange)="onChangeRole($event)"
            (scrollToBottom)="loadMoreRoles()"
          ></tds-select>
          <tds-error>Vui lòng chọn vai trò</tds-error>
        </tds-form-field>
      </div>

      <div *tdsModalFooter
        class="flex gap-2 p-4 justify-end">
        <button tds-button-outline
          color="secondary"
          class="min-w-[100px]"
          type="button"
          (click)="onClose()"
        >
          Đóng
        </button>

        <button tds-button
          class="min-w-[100px]"
          color="primary"
          type="submit"
          (click)="onUpdate()"
        >
          Lưu
        </button>
      </div>
    </form>
  </tds-spin>
</ng-container>