<tds-spin class="w-full h-full"
  [spinning]="isLoading"
>
  <div class="w-full">
    <form
      class="w-full p-4 flex flex-col gap-3 h-[calc(100vh-94px)] overflow-y-auto tds-custom-scroll"
      [formGroup]="form"
    >
      <tds-form-field class="w-full">
        <tds-label [tdsRequired]="true">Tên vai trò</tds-label>
        <input
          tdsInput
          autocomplete="off"
          placeholder="Tối đa 64 ký tự"
          formControlName="name"
          [maxlength]="64"
        />
        <tds-error>Vui lòng nhập tên vai trò</tds-error>
      </tds-form-field>
      
      <tds-form-field class="w-full">
        <tds-label>Mô tả</tds-label>
        <textarea 
          tdsInput
          placeholder="Nhập mô tả"
          [tdsAutosize]="{ minRows: 3 }"
          formControlName="description"
          [maxlength]="500"
        ></textarea>
        @if (form.get('description')?.invalid) {
          <tds-error>Mô tả không được vượt quá 500 ký tự</tds-error>
        }
      </tds-form-field>

      <div class="tds-color-background-neutral-minimalist flex gap-2 items-center p-2.5 rounded-md">
        <tds-switch formControlName="isAllPermission"
          [ngModel]="form.get('isAllPermission')?.value"
          (ngModelChange)="onChangeAllPermission($event)"
        ></tds-switch>
        <span class="tds-body-2 font-semibold tds-color-text-primary">Tất cả các quyền</span>
      </div>

      <section class="w-full grid grid-cols-2 gap-3">
        @if (permissions && permissions.length) {
          @for (permission of permissions; let i = $index; track i) {
            <div [ngClass]="{
              'col-span-2': permission.permissionName === 'Setting', 
              'col-span-1': permission.permissionName !== 'Setting'
            }">
              <app-setting-permission 
                [permission]="permission"
                (onChangeActivePermission)="handleCheckFullPermission()"
              ></app-setting-permission>
            </div>
          }
        }
      </section>
    </form>

    <div class="flex gap-2 h-[50px] px-4 py-2 border-t tds-color-border-primary">
      <button tds-button
        class="min-w-[103px]"
        color="primary"
        (tdsBtnClick)="onSave()"
      >Lưu</button>
      <button tds-button-outline
        class="min-w-[103px]"
        color="secondary"
        (tdsBtnClick)="onCancel()"
      >Hủy bỏ</button>
    </div>
  </div>
</tds-spin>