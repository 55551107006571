import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeActionService {
  private actionCompleted = new BehaviorSubject<boolean>(false);
  reloadEmployeeList$ = this.actionCompleted.asObservable();

  onActionCompleted(): void {
    this.actionCompleted.next(true);
  }
}