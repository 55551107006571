import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as signalR from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { RECEIVE_GROUP_MESSAGE } from '../constants/signalR.const';
import { ISignalrReceiveMessage } from '../models/signalR.model';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection!: signalR.HubConnection;
  private signalRHub = environment.signalRHub;

  private messageSubject = new Subject<ISignalrReceiveMessage<any>>();
  public messages$ = this.messageSubject.asObservable();

  constructor() {}

  startConnection(tenantId: string, accessToken: string) {
    const hub = `${this.signalRHub}?tenant=${tenantId}`;
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(hub, 
        { 
          accessTokenFactory: () => accessToken
        }
      )
      .build();

    this.hubConnection
      .start()
      .then(() => {
        console.log('SignalR Connected!');
        this.setupEventHandlers();
      })
      .catch(err => console.error('Error while starting connection: ', err));
  }

  stopConnection() {
    if (this.hubConnection) {
      this.hubConnection
        .stop()
        .then(() => console.log('SignalR Disconnected!'))
        .catch(err => console.error('Error while stopping connection: ', err));
    }
  }

  private setupEventHandlers() {
    if (!this.hubConnection) return;
  
    this.hubConnection.on(RECEIVE_GROUP_MESSAGE, (data: ISignalrReceiveMessage<any>) => {
      console.log('%c [SignalR] ReceiveGroupMessage:', 'background: #222; color: #bada55', data);
      this.messageSubject.next(data);
    });
  }
}
