@if(!isModalAction) {
  <ng-container>
    <div class="flex items-center gap-2">
      @if (status !== employeeStatus.Active && status !== employeeStatus.Inactive) {
        <button 
          tds-button-flat-icon
          color="info"
          size="sm"
          tds-tooltip
          tooltipTitle="Gửi lời mời"
          (tdsBtnClick)="sendInvitation(employee)"
        >
          <i class="tdsi-send-line"></i>
        </button>
      }

      @if (status !== employeeStatus.Pending && status !== employeeStatus.Undefined) {
        @if (!isDetail) {
          <button 
            tds-button-flat-icon
            color="info"
            size="sm"
            tds-tooltip
            tooltipTitle="Chỉnh sửa"
            (tdsBtnClick)="editEmployee(employee)"
          >
            <i class="tdsi-edit-line"></i>
          </button>
        }
        
        <button 
          tds-button-flat-icon
          color="error"
          size="sm"
          tds-tooltip
          [tooltipTitle]="checkLockStatus() ? 'Mở khóa nhân viên' : 'Khóa nhân viên'"
          (tdsBtnClick)="lockOrUnlockEmployee()"
        >
          <i [ngClass]="checkLockStatus() ? 'tdsi-unlock-line' : 'tdsi-lock-line'"></i>
        </button>
      }

      <button 
        tds-button-flat-icon
        color="error"
        size="sm"
        tds-tooltip
        tooltipTitle="Xóa nhân viên"
        (tdsBtnClick)="deleteEmployee()"
      >
        <i class="tdsi-trash-line"></i>
      </button>
    </div>

  </ng-container>
} @else if (!isEditOnly){
  <ng-container>
    <div class="flex items-center gap-2 w-full">
      <button 
        tds-button
        color="error"
        size="md"
        (tdsBtnClick)="deleteEmployee()"
      >
        Xóa tài khoản
      </button>

      @if (status !== employeeStatus.Pending && status !== employeeStatus.Undefined) {
        <button 
          tds-button
          [color]="'info'"
          size="md"
          (tdsBtnClick)="lockOrUnlockEmployee()"
        >
          {{employee.userStatus === employeeStatus.Active ? 'Khóa tài khoản' : 'Mở khóa tài khoản' }}
        </button>
      }

      @if (status !== employeeStatus.Active && status !== employeeStatus.Inactive) {
        <button 
          tds-button
          color="primary"
          size="md"
          (tdsBtnClick)="sendInvitation(employee)"
        >
          Gửi lời mời
        </button>
      }
    </div>
  </ng-container>
} @else {
  <ng-container>
    <button 
      tds-button-outline
      color="secondary"
      [tdsPrefixIcon]="'tdsi-edit-fill'"
      size="sm"
      class="min-w-[100px]"
      (tdsBtnClick)="editEmployee(employee)"
    >
      Chỉnh sửa
    </button>
  </ng-container>
}

<ng-template #sendInvitationSvg>
  <svg width="72" height="66" viewBox="0 0 72 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M59.5 11.86V41.29C59.5 41.7769 59.3421 42.2506 59.05 42.64C58.7579 43.0295 58.3474 43.3137 57.88 43.45L50.6297 50.3809C50.4207 50.41 33.459 50.4392 33.25 50.41H9.07C7.75425 50.4342 6.48243 49.9366 5.53243 49.0259C4.58243 48.1153 4.03149 46.8656 4 45.55V11.86C3.99927 11.2027 4.13793 10.5526 4.40683 9.95276C4.67573 9.35291 5.06877 8.81688 5.56 8.38005C5.8342 8.13513 6.16356 7.96016 6.52 7.87005C6.86885 7.78108 7.2341 7.77829 7.58426 7.86191C7.93442 7.94553 8.259 8.11306 8.53 8.35005L31.27 27.94C31.402 28.0581 31.5729 28.1234 31.75 28.1234C31.9271 28.1234 32.098 28.0581 32.23 27.94L54.97 8.35005C55.3825 7.98108 55.9166 7.7771 56.47 7.7771C57.0235 7.7771 57.5575 7.98108 57.97 8.35005C58.4596 8.79296 58.8492 9.33506 59.1131 9.94026C59.3769 10.5455 59.5088 11.1999 59.5 11.86Z" fill="#E5EAEB"/>
    <path d="M58.6898 10.15C58.6781 10.4591 58.6029 10.7626 58.4687 11.0413C58.3344 11.32 58.1442 11.5681 57.9098 11.77L35.1698 31.3C34.2328 32.1487 33.014 32.6191 31.7498 32.62C30.4773 32.6152 29.2505 32.1458 28.2998 31.3L5.5898 11.77C5.3554 11.5681 5.16515 11.32 5.03095 11.0413C4.89674 10.7626 4.82145 10.4591 4.8098 10.15C4.79602 9.83937 4.84765 9.52931 4.96135 9.2399C5.07504 8.95049 5.24827 8.6882 5.4698 8.47C6.43039 7.52656 7.72339 6.99859 9.0698 7H54.4298C55.7762 6.99859 57.0692 7.52656 58.0298 8.47C58.2513 8.6882 58.4245 8.95049 58.5382 9.2399C58.6519 9.52931 58.7036 9.83937 58.6898 10.15Z" fill="#CCD2D4"/>
    <path d="M52.8077 59.2151C61.1239 59.2151 67.8655 52.4735 67.8655 44.1573C67.8655 35.8412 61.1239 29.0996 52.8077 29.0996C44.4916 29.0996 37.75 35.8412 37.75 44.1573C37.75 52.4735 44.4916 59.2151 52.8077 59.2151Z" fill="#2A92FF"/>
    <path d="M53 43C51.9 43 50.9583 42.6083 50.175 41.825C49.3917 41.0417 49 40.1 49 39C49 37.9 49.3917 36.9583 50.175 36.175C50.9583 35.3917 51.9 35 53 35C54.1 35 55.0417 35.3917 55.825 36.175C56.6083 36.9583 57 37.9 57 39C57 40.1 56.6083 41.0417 55.825 41.825C55.0417 42.6083 54.1 43 53 43ZM45 51V48.2C45 47.6333 45.146 47.1123 45.438 46.637C45.7293 46.1623 46.1167 45.8 46.6 45.55C47.6333 45.0333 48.6833 44.6457 49.75 44.387C50.8167 44.129 51.9 44 53 44C54.1 44 55.1833 44.129 56.25 44.387C57.3167 44.6457 58.3667 45.0333 59.4 45.55C59.8833 45.8 60.2707 46.1623 60.562 46.637C60.854 47.1123 61 47.6333 61 48.2V51H45Z" fill="white"/>
  </svg>
</ng-template>
